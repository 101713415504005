<template>
  <div>
    <v-row justify="center" align="center" v-if="loading">
      <v-col class="text-center ma-12">
        <v-progress-circular indeterminate color="primary" ></v-progress-circular>
      </v-col>
    </v-row>
    <div class="services-section scrollable-content" v-else>
      <v-container class="pa-0 ma-0">
        <v-row no-gutters>
          <v-col class="pa-0 ma-0">
            <div class="hb-font-header-2-medium my-5">
              <span v-if="merchandise">Your purchase was successful!</span>
              <span v-else-if="payment.id">Success! The lease has been completed.</span>
              <span v-else>Your lease has been finalized!</span>
            </div>
          </v-col>
        </v-row>
      </v-container>


      <hb-notification 
        v-model="notification" 
        v-if="showInvoiceGenerationNote" 
        type="caution" > 
              The move-in date for this rental is outside the invoice threshold period ({{invoice_send_day}} Days). The invoice for the next billing period will be generated after clicking continue. This invoice will be visible in the tenant’s transaction history.
      </hb-notification>



      <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
      <view-payment-content v-if="payment.id" :is-guest-user="isGuestUser" :invoices="invoices" :payment="payment"></view-payment-content>

    <div class="new-tenant-walkthrough-footer">

      <hb-bottom-action-bar
        @close="$emit('close')"
        :top-border="false"
      >
      <template v-slot:left-actions>
          <!-- <help-juice /> -->
        </template>
        <template v-slot:right-actions>

          <hb-btn
            v-if="payment.id"
            color="secondary"
            :to="'/reports.html?type=receipt&request_id=' + payment.id"
            target="_blank"
          >
            Print / Download Receipt
          </hb-btn>

          <hb-btn
            v-if="payment.id"
            color="secondary"
            @click="sendReceiptModal = true,$refs?.sendReceipt?.fetchDataOnEvent()"
          >
            Send Receipt
          </hb-btn>

          <!--
          <hb-btn v-if="payment.id" color="secondary" :to="'/reports/web/payment?id=' + payment.id" target="_blank">View Receipt</hb-btn>
          -->

          <hb-btn
            v-if="lease.id && contact.id"
            color="secondary"
            @click="goToContact"
          >
            View Tenant Profile
          </hb-btn>

          <hb-btn
            color="primary"
            @click="closeWindowRefresh"
          >
            Finish and Close
          </hb-btn>

        </template>

      </hb-bottom-action-bar>
    </div>
  </div>

    <hb-modal
      confirmation
      footerOff
      v-model="sendReceiptModal"
      size="medium"
      title="Send Receipt"
      title-icon="mdi-user-actions-custom-1"
      show-help-link
       @close="$refs.sendReceipt.closeModal()"
    >
      <template v-slot:content>
        <send-receipt :payment_id="payment.id"  ref="sendReceipt" :contact="contact" @close="sendReceiptModal = false"></send-receipt>
      </template>
    </hb-modal>

  </div>

</template>

<script type="text/babel">

    import api from '../../../assets/api.js';
    import Status from '../../includes/Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import moment from 'moment';
    import ViewPaymentContent from '../../payments/ViewPaymentContent.vue';
    import SendReceipt from "../../assets/SendReceipt.vue";
    import { mapGetters } from 'vuex';
    import { EventBus } from '../../../EventBus.js';
    export default {
        name: "OnboardingReceipt",
        data() {
            return {
                payment:{},
                invoices: [],
                sendReceiptModal: false,
                loading: false,
                showInvoiceGenerationNote: false,
                currentDate: '',
                invoiceGenerationDifference: '',
            }
        },
        components:{
            Status,
            Loader,
            ViewPaymentContent,
            SendReceipt
        },
        props: ['payment_id','merchandise','isGuestUser'],
        computed:{
            ...mapGetters({
                properties: 'propertiesStore/filtered',
                property: 'onBoardingStore/property',
                lease: 'onBoardingStore/lease',
                template: 'onBoardingStore/template',
                payments: 'onBoardingStore/payments',
                paymentMethod: 'onBoardingStore/paymentMethod',
                contact: 'onBoardingStore/contact'
            }),
            invoice_send_day() {
              let [key = {}] = Object.keys(this.template);
              let {invoiceSendDay = 0 } = this.template[key];
              return invoiceSendDay;
            }
        },

        async created (){
          this.loading = true;
          await this.fetchData();
          this.currentDate = moment().format('YYYY-MM-DD');
          if(!this.merchandise) this.checkToshowNote();
          this.loading = false;
        },
        methods: {
            async fetchData(){
                let payment_id = this.payments.length ? this.payments[0].id: this.payment_id || null;
                if(!payment_id) return;
                let r = await api.get(this, api.PAYMENTS + payment_id + '/receipt');
                this.payment = r.payment;
                this.invoices = r.invoices;
            },
            goToContact(){
                let path = `/contacts/${this.contact.id}`;
                if(this.property?.id) path += `?property_id=${this.property?.id}`;
                this.$router.push(path);
                EventBus.$emit('HB-Navigation:Hide-Settings-Modal');
                this.$emit('close', true, this.contact.id);
            },
            closeWindowRefresh(){
                this.$emit('close', true);
            },
            checkToshowNote(){
              let [key = {}] = Object.keys(this.template);
              let {invoiceSendDay = 0, add_next_month = 0, add_next_month_days } = this.template[key];
              let { bill_day = 0, start_date } = this.lease;
              let date = moment(start_date).format('DD');
              let bill_date = moment().set("date", bill_day);
              let difference = bill_date.diff(this.currentDate, 'days');

              if(bill_day === 1 && add_next_month && add_next_month_days < date){
                this.showInvoiceGenerationNote = false;
              } else if((difference <= invoiceSendDay) && difference > 0){
                this.invoiceGenerationDifference = difference;
                this.showInvoiceGenerationNote = true;
              } else {
                bill_date.add(1, 'M');
                let differenceNextMonth = bill_date.diff(this.currentDate, 'days');
                if(differenceNextMonth <= invoiceSendDay){
                  this.invoiceGenerationDifference = differenceNextMonth;
                  this.showInvoiceGenerationNote = true;
                }
              }
            }
            
        }
    }
</script>

<style scoped>

  .insurance-container{
    border: 1px solid #C4CDD5;
    background: white;
    padding: 30px;
    border-radius: 4px;
    margin-bottom: 30px;
  }
  .policy-container{
    padding-top: 0;
    padding-bottom: 0;
  }
  .form-label{
    background: #F4F6F8;
    border-radius: 4px;
  }
  .insurance-option-row{
    border-bottom: 1px solid #C4CDD5;
    padding: 10px 20px;
  }
  .insurance-section-label{
    padding: 30px;
    font-weight: bold;
  }
  .insurance-container{
    padding: 30px 50px;
  }

  .insurance-container .v-input--radio-group--column{
    margin: 0;
  }
  .insurance-container .insurance-row {
    margin-bottom: 10px;

  }
  .insurance-option-row.no-border{
    border-bottom: none;
  }

  .new-tenant-walkthrough-footer{
    /* padding: 3px 0 3px 0; */
    background: #FFFFFF;
    border-top: 1px solid #e2e2e2;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    left: 0;
    right: 0;
  }
  .services-section{
    padding: 20px 50px 100px 50px;
  }

  .policy-container  .v-input.v-input--selection-controls.v-input--checkbox {
    margin-top: 0;
  }

  .signatures .v-input--selection-controls .v-input__control{
    flex-grow: 1;
  }

  .send-block{
    margin-top: 10px;
  }

  .note{
    background-color: burlywood;
  }


</style>
