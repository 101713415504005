<template>
  <div class="auction-manager-blade-wrapper auction-manager-light-background">
    <hb-blade-header
      title="Auction Manager"
      back-button-off
      more-title-space
      @close="$emit('close')"
    >
      <template v-slot:right>
          <help-juice blade-header />
      </template>
    </hb-blade-header>

      <div class="pb-4 px-4 pauction-manager-light-backgroundb-3 auction-manager-white-background" :class="{ 'pt-4' : !auctionUpdating }">
        <hb-notification
          v-if="!auctionUpdating"
          :left-cols="scheduled_auction.id && !isAuctionDay && !isMoveOut ? 10 : 12"
          :right-cols="scheduled_auction.id && !isAuctionDay && !isMoveOut ? 2 : 0"
          type="error"
          mdi-code="mdi-gavel"
          :title="auctionLease.auction_status == 'auction_day' ? 'Auction Closed:' : auctionLease.auction_status == 'auction_payment' ? 'Auction Information:' : 'Auction:'"
          notDismissable
          class="auction-dialog-notification"
        >{{ getAuctionMessage(auctionLease) }}
          <template v-slot:condensedActions v-if="scheduled_auction.id && !isAuctionDay && !isMoveOut">
            <hb-menu
              options
              options-hover-type="error"
            >
              <v-list>
                <v-list-item @click="showRemoveAuctionConfirmation = true" :ripple="false">
                  <v-list-item-title>Cancel Auction</v-list-item-title>
                </v-list-item>
              </v-list>
            </hb-menu>
        </template>
        <template v-slot:actions v-if="scheduled_auction.id && !isAuctionDay && !isMoveOut">
          <hb-btn small color="secondary" @click="showRemoveAuctionConfirmation = true">Cancel Auction</hb-btn>
        </template>
        </hb-notification>
        <mini-profile-view
          v-if="contact_id"
          :contact-id="contact_id"
          :use-contact="false"
          class="mt-4"
        />
      </div>
      <VDivider />

      <hb-stepper
        v-model="stepper"
        @change="forceScrollToTopSyncer = true">
        <hb-stepper-header>
            <hb-stepper-step
              @click="goToStep(0)"
              step="0"
              :complete="completed0"
              :rules="[() => hasNoErrors0]"
            >
              Images and Inventory
            </hb-stepper-step>

            <v-divider></v-divider>

            <hb-stepper-step
              @click="goToStep(1)"
              step="1"
              :complete="completed1"
              :rules="[() => hasNoErrors1]"
            >
              Audit
            </hb-stepper-step>

            <v-divider></v-divider>

            <hb-stepper-step
              @click="goToStep(2)"
              step="2"
              :complete="completed2"
              :rules="[() => hasNoErrors2]"
            >
              {{ scheduled_auction.auction_type === 'storage-treasures' && auctionLease.auction_status === 'scheduled' ? 'Schedule &amp; Publish Auction' : 'Schedule Auction' }}
            </hb-stepper-step>

            <v-divider></v-divider>

            <hb-stepper-step
              @click="goToStep(3)"
              step="3"
              :complete="completed3"
              :rules="[() => hasNoErrors3]"
            >
              Auction Sale
            </hb-stepper-step>

        </hb-stepper-header>
      </hb-stepper>
      
        <div v-if="stepper === 0" class="pb-12 auction-manager-light-background">
          <v-row
            v-if="fetchingMedia"
            class="fill-height my-12 py-12"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="#00848E"
            ></v-progress-circular>
          </v-row>
          <hb-empty-state v-else-if="!auctionLease.auction_status" class="ma-4">
            Lock Cut and Inventory has not been completed for this Lease.
          </hb-empty-state>
          <div v-else class="pt-1 pb-12 px-4">
            <v-expansion-panels v-model="stepOneImagesAccordion" multiple>
              <hb-expansion-panel>
                <template v-slot:title>
                  Images and Inventory
                </template>
                <template v-slot:content>
                  <hb-media-viewer
                    grid
                    :uploader="!isPublished"
                    v-model="mediaViewer"
                    @upload="handleUploadedFiles"
                    :upload-error="imageError"
                  >
                    <template v-slot:slides>
                      <slide
                        v-for="(upload, i) in uploads"
                        :key="'upload_' + i"
                        :id="'temp_upload_id_' + i"
                        :src="upload.src"
                      />
                      <slide
                        v-for="(slide, i) in media"
                        :key="'slide_' + i"
                        :id="slide.id"
                        :src="slide.media_src"
                        :thumbnail="slide.media_thumbnail_src"
                      />
                    </template>
                  </hb-media-viewer>
                  <div class="mx-4 mb-4">
                    <div class="mb-2 font-weight-medium">General Description</div>
                    <HbTextarea
                      v-model="mediaDescription"
                      v-validate="'required|max:1000'"
                      name="description"
                      data-vv-scope="lockcut"
                      data-vv-as="General Description"
                      :error="errors.has('lockcut.description')"
                      :placeholder="isPublished ? '' : 'Enter General Description'"
                      :readonly="isPublished"
                      @keydown="allowMediaSave = true"
                    />
                    <VDivider />
                  </div>
                  <v-row no-gutters class="mx-4">
                    <div class="my-2 font-weight-medium">Categories / Keywords</div>
                    <div class="auction-dialog-categories-list" v-if="!isPublished">
                      <!-- have to use v-checkbox instead of hb-checkbox due to array passing up and down incompatibility - please do not replace with HbCheckbox -->
                      <v-checkbox
                        v-for="(category, i) in categories"
                        :key="'category_' + i"
                        v-model="categoriesSelected"
                        :label="category.name"
                        :value="category"
                        hide-details
                        class="auction-dialog-category pa-0 mx-0 my-2"
                        @change="allowMediaSave = true"
                      ></v-checkbox>
                    </div>
                    <div class="auction-dialog-categories-list" v-else-if="categoriesSelected.length">
                      <!-- have to use v-checkbox instead of hb-checkbox due to array passing up and down incompatibility - please do not replace with HbCheckbox -->
                      <v-checkbox
                        v-for="(category, i) in categoriesSelected"
                        :key="'category_' + i"
                        v-model="categoriesSelected"
                        :label="category.name"
                        :value="category"
                        hide-details
                        read-only
                        disabled
                        class="auction-dialog-category pa-0 mx-0 my-2"
                      ></v-checkbox>
                    </div>
                    <div class="auction-dialog-categories-list" v-else>
                      <hb-empty-state class="my-2">
                        No Categories Set
                      </hb-empty-state>
                    </div>
                  </v-row>
                  
                  <v-row no-gutters class="ma-4">
                    Tamper Tag:
                    <HbTextField
                      v-model="tamperTag"
                      v-validate="'max:45'"
                      name="tamper"
                      data-vv-scope="lockcut"
                      data-vv-as="Tamper Tag"
                      :error="errors.has('lockcut.tamper')"
                      :placeholder="isPublished ? '' : 'Enter Tamper Tag'"
                      :readonly="isPublished"
                      class="pl-3"
                      @keydown="allowMediaSave = true"
                      style="position:relative;top:2px"
                    />
                  </v-row>
                </template>
                <template v-slot:footer>
                  <hb-bottom-action-bar cancel-off>
                    <template v-slot:right-actions>
                      <hb-btn
                        color="primary"
                        @click="saveAuctionImagesAndDetails()"
                        :disabled="!allowMediaSave || savingAuctionMediaInProgess"
                        :loading="savingAuctionMediaInProgess"
                      >Save</hb-btn>
                    </template>
                  </hb-bottom-action-bar>
                </template>
              </hb-expansion-panel>
            </v-expansion-panels>
          </div>
        </div>
        <div v-else-if="stepper === 1" class="pb-12">
          <v-row
            v-if="fetchingDelinquency"
            class="fill-height my-12 py-12"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="#00848E"
            ></v-progress-circular>
          </v-row>
          <div v-else-if="delinquency.length" class="pb-12">
            <v-row no-gutters class="py-3 px-4 hb-text-light auction-manager-white-background">
              Please verify each step in the Delinquency Timeline by clicking on the "Mark All as Verified" button.
            </v-row>
            <VDivider />
            <v-row no-gutters class="py-3 px-4 auction-manager-light-background">
              <hb-btn color="secondary" small @click="verifyAudit()">Mark All as Verified</hb-btn>
              <hb-btn class="pl-3" color="secondary" small @click="skipAudit()">Skip Audit</hb-btn>
            </v-row>
            <delinquency :contact="{ id: contact_id }" :active_delinquencies="delinquency"></delinquency>
          </div>
          <div v-else>
            <hb-empty-state class="ma-4">
              No Delinquency Found
            </hb-empty-state>
          </div>
        </div>
        <div class="pa-0 ma-0" v-else>
          <div class="pt-0 px-0 pb-12">
            <v-row
              v-if="auctionUpdating"
              class="fill-height my-12 py-12"
              align="center"
              justify="center"
            >
              <v-progress-circular
                indeterminate
                color="#00848E"
              ></v-progress-circular>
            </v-row>
            <div v-else-if="stepper === 3 && scheduled_auction.id && !isAuctionDay && !isMoveOut" class="pb-6">
              <hb-empty-state class="ma-4">
                Auction Sale details cannot be set until the date of the Auction.
              </hb-empty-state>
            </div>
            <div v-else>
              <v-expansion-panels v-model="auctionFormAccordion" multiple class="pt-1 px-4 pb-12">
                <hb-expansion-panel>
                  <template v-slot:title>
                    Auction Information
                  </template>
                  <template v-slot:content>
                    <v-row class="hb-table-row ma-0 pa-0">
                      <v-col class="hb-table-label py-4 pl-4" cols="3">
                        Auction Type &amp; Platform
                      </v-col>
                      <v-col class="ma-0 pa-4" cols="6">
                        <HbSelect
                          v-if="!scheduled_auction.id || change_auction_info"
                          v-model="scheduled_auction.auction_type"
                          :items="auctionTypes"
                          item-value="value"
                          item-text="text"
                          name="auction_type"
                          data-vv-as="Auction Type &amp; Platform"
                          placeholder="Select Auction Type &amp; Platform"
                          :clearable="false"
                          :error="errors.has('auction_type')"
                          v-validate="'required'"
                        />
          
                        <v-row
                          class="pt-0 pl-4"
                          v-if="scheduled_auction.id && !change_auction_info"
                        >
                          <v-col v-if="scheduled_auction.id" cols="11" class="text-capitalize pa-0 ma-0">
                            {{ getScheduledAuctionType(scheduled_auction.auction_type) }}
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row class="hb-table-row ma-0 pa-0">
                      <v-col class="hb-table-label py-4 pl-4" cols="3">
                        Auction Date &amp; Time
                      </v-col>
                      <v-col class="ma-0 pt-3 pb-0" :class="{'hb-form-editable' : scheduled_auction.id && fieldEditing != 'date_time' && !isAuctionDay && !isMoveOut && !change_auction_info}" cols="9">
                        <v-row class="pa-0">
                          <v-col
                            cols="6"
                            class="pt-0 mt-0"
                            v-if="!scheduled_auction.id || fieldEditing == 'date_time' || change_auction_info"
                          >
                            <hb-date-picker
                              v-model="scheduled_auction.date"
                              v-validate="'required'"
                              id="date"
                              name="due_date"
                              label="Select Starting Date for Auction"
                              :solo="false"
                              :min="minAuctionDate"
                              data-vv-as="Starting Date"
                              :error="errors.has('due_date')"
                            ></hb-date-picker>
                          </v-col>
                          <v-col
                            cols="6"
                            class="pt-0 mt-0"
                            v-if="!scheduled_auction.id || fieldEditing == 'date_time' || change_auction_info"
                          >
                            <label class="v-label v-label--active theme--light auction-dialog-font-11px">Select Starting Time for Auction</label>
                            <v-row no-gutters class="mt-1">
                              <v-col md="3" class="ma-0 pa-0">
                                <hb-text-field
                                  id="auction_hour"
                                  name="auction_hour"
                                  placeholder="HH"
                                  v-model="timeValue.hours"
                                  data-vv-as="Starting Hour"
                                  type="number"
                                  min="1"
                                  max="12"
                                  width="80%"
                                  v-validate="'required|numeric|max_value:12|min_value:1'"
                                  :error="errors.has('auction_hour')"
                                  class="mt-0 pt-0 time-w-80"
                                ></hb-text-field>
                              </v-col>
                              <v-col md="3" class="ma-0 pa-0">
                                <hb-text-field
                                  id="auction_minutes"
                                  name="auction_minutes"
                                  placeholder="MM"
                                  v-model="timeValue.minutes"
                                  data-vv-as="Starting Minute"
                                  type="number"
                                  min="0"
                                  max="59"
                                  width="80%"
                                  v-validate="'required|numeric|max_value:59|min_value:0'"
                                  :error="errors.has('auction_minutes')"
                                  class="mt-0 pt-0 time-w-80"
                                ></hb-text-field>
                              </v-col>
                              <v-col md="6" class="ma-0 pa-0">
                                <hb-select
                                  :items="['AM','PM']"
                                  id="auction_ampm"
                                  name="auction_ampm"
                                  v-model="timeValue.ampm"
                                  :clearable="false"
                                  placeholder="AM/PM"
                                  v-validate="'required'"
                                  data-vv-name="auction_ampm"
                                  data-vv-as="Starting AM/PM"
                                  width="80%"
                                  :error="errors.has('auction_ampm')"
                                  class="mt-0 pt-0 time-w-80"
                                ></hb-select>
                              </v-col>
                            </v-row>
                          </v-col>
                          <v-col
                            cols="12"
                            v-if="scheduled_auction.id && fieldEditing == 'date_time' && !change_auction_info"
                            class="ma-0 pt-1 pb-3 d-flex align-center"
                          >
                            <hb-btn
                              color="primary"
                              small
                              @click="showEditAuctionConfirmation = true"
                              >Save</hb-btn
                            >
                            <hb-link class="ml-2" small @click="clearEditField()"
                              >Cancel</hb-link
                            >
                          </v-col>
                        </v-row>
          
                        <v-row
                          class="py-1 pl-4"
                          v-if="fieldEditing != 'date_time' && !isAuctionDay && !isMoveOut && !change_auction_info && ((scheduled_auction.auction_type === 'storage-treasures' && !isPublished) || scheduled_auction.auction_type !== 'storage-treasures')"
                          @click="editField('date_time')"
                        >
                          <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                            <div class="hb-text-light mb-3">Auction has been scheduled for</div>
                            <div v-if="scheduled_auction.auction_type === 'storage-treasures'" class="mb-1 font-weight-medium">Auction Starting Date &amp; Time:</div>
                            <div class="mb-1">{{ scheduled_auction.date | formatLocalShortDate }}</div>
                            <div class="mb-3">{{ scheduled_auction.scheduled_date | formatTime12Hour }}</div>

                            <div v-if="scheduled_auction.auction_type === 'storage-treasures'" class="hb-text-error">
                              <div class="mb-1 font-weight-medium">Auction Ending Date &amp; Time:</div>
                              <div class="mb-1">{{ tenDaysFromScheduledAuction | formatLocalShortDate }}</div>
                              <div class="mb-3">{{ tenDaysFromScheduledAuction | formatTime12Hour }}</div>
                            </div>
                            <div
                              v-if="scheduled_auction.auction_type === 'storage-treasures' && isPublished && scheduled_auction.vendor_auction_url"
                              class="mt-n1 mb-4"
                            >
                              <hb-link
                                :href="scheduled_auction.vendor_auction_url"
                                target="_blank"
                              >View Auction on Storage Treasures</hb-link>
                            </div>
                          </v-col>
                          <v-col v-if="scheduled_auction.id" cols="1" class="pa-0 ma-0">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-hover v-slot:default="{ hover }">
                                  <span v-on="on">
                                    <hb-icon small :color="hover ? '#101318' : ''"
                                      >mdi-pencil</hb-icon
                                    >
                                  </span>
                                </v-hover>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row
                          class="py-1 pl-5" 
                          v-else-if="!change_auction_info && fieldEditing != 'date_time'"
                        >
                          <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                            <div class="hb-text-light mb-3">Auction has been scheduled for</div>
                            <div v-if="scheduled_auction.auction_type === 'storage-treasures'" class="mb-1 font-weight-medium">Auction Starting Date &amp; Time:</div>
                            <div class="mb-1">{{ scheduled_auction.date | formatLocalShortDate }}</div>
                            <div class="mb-3">{{ scheduled_auction.scheduled_date | formatTime12Hour }}</div>
                            
                            <div v-if="scheduled_auction.auction_type === 'storage-treasures'" class="hb-text-error">
                              <div class="mb-1 font-weight-medium">Auction Ending Date &amp; Time:</div>
                              <div class="mb-1">{{ tenDaysFromScheduledAuction | formatLocalShortDate }}</div>
                              <div class="mb-3">{{ tenDaysFromScheduledAuction | formatTime12Hour }}</div>
                            </div>
                            <div
                              v-if="scheduled_auction.auction_type === 'storage-treasures' && isPublished && scheduled_auction.vendor_auction_url"
                              class="mt-n1 mb-4"
                            >
                              <hb-link
                                :href="scheduled_auction.vendor_auction_url"
                                target="_blank"
                              >View Auction on Storage Treasures</hb-link>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <span v-if="stepper === 3">
                      <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && (isAuctionDay || isMoveOut)">
                        <v-col class="hb-table-label py-4 pl-4" cols="3"> Winning Bid
                          <span style="position:relative;top:-2px;">
                            <hb-tooltip>
                                  <template v-slot:item>
                                    <hb-icon small mdi-code="mdi-information"></hb-icon>
                                  </template>
                                  <template v-slot:body>
                                    Amount of the bid placed by the Winner. Auction Tax will be calculated on this amount.                      
                                  </template>
                            </hb-tooltip>
                          </span>
                        </v-col>
                        <v-col class="ma-0 pl-4" cols="9"  v-if="!isMoveOut">
                          <v-row>
                            <v-col cols="4">
                              <v-text-field
                                v-if="scheduled_auction.id"
                                label="Enter Amount"
                                class="pa-0 mt-0"
                                flat
                                id="amount"
                                name="winning_bid_amount"
                                data-vv-as="winning bid amount"
                                v-model="scheduled_auction.amount"
                                v-validate="schedule_new_auction ? '': 'required|decimal:2|min_value:0'"
                                :error="errors.collect('winning_bid_amount').length"
                                @blur="validateField('winning_bid_amount')"
                                hide-details
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                v-if="scheduled_auction.id"
                                class="pa-0 mt-0"
                                flat                 
                                v-model="scheduled_auction.auction_cleaning_deposit"
                                v-validate="'required|decimal:2|min_value:0'"
                                data-vv-as="cleaning deposit"
                                label="Cleaning Deposit"
                                id="cleaning_deposit"
                                name="cleaning_deposit"
                                :error="errors.collect('cleaning_deposit').length"
                                @blur="validateField('cleaning_deposit')"
                                hide-details
                              >
                              </v-text-field>
                            </v-col>
                            <v-col cols="4">
                              <v-text-field
                                class="pa-0 mt-0"
                                flat                  
                                v-if="scheduled_auction.id"
                                v-validate="'required|min_value:0'"
                                data-vv-as="clean out period"
                                label="Clean Out Period"
                                id="cleaning_period"
                                name="cleaning_period"
                                :error="errors.collect('cleaning_period').length"
                                v-model="scheduled_auction.auction_cleaning_period"
                                hide-details
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col class="mx-0 mt-1 pl-4" cols="9" v-if="isMoveOut">
                          <v-row
                            class="pt-0 pl-4"
                            v-if="scheduled_auction.id"
                          >
                            <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                              {{ scheduled_auction.amount | formatMoney }}
                              <hb-status class="pl-2" style="position:relative;top:-.5px;" color="#02AD0F">Paid</hb-status>
                            </v-col>
                          </v-row>
                          <v-row
                            class="pt-3 pl-4"
                            v-if="scheduled_auction.id"
                          >
                            <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                              Cleaning Deposit: {{ auctionCleaningDeposit | formatMoney }}
                              <hb-btn class="pl-2" style="position:relative;top:-1.5px;" small color="secondary" @click="$emit('refundAuction', scheduled_auction)" v-if="!scheduled_auction.refund_id && !auction_refund_id" :disabled="disableRefundButton"
                                >Refund</hb-btn>
                              <hb-status color="#919EAB" v-else>Refunded</hb-status>
                            </v-col>
                          </v-row>
                          <v-row
                            class="pt-3 pl-4"
                            v-if="scheduled_auction.id"
                          >
                            <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                              Cleaning Up Period: {{ scheduled_auction.cleaning_period }} Days
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && isAuctionDay && !isMoveOut">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          <div class="buyer-premium-label">
                            <span class="block">Buyer Premium</span>
                            <span class="ml-1">
                              <hb-tooltip>
                                <template v-slot:item>
                                  <hb-icon small mdi-code="mdi-information"></hb-icon>
                                </template>
                                <template v-slot:body>
                                  Premium paid by the bidder to the auctioneer<br>or online auction company.<br>This is for record purpose only.                      </template>
                              </hb-tooltip>
                            </span>
                          </div>
                        </v-col>
                        <v-col class="ma-0 pl-4" cols="9">
                          <v-row no-gutters style="margin-top:6px;">
                            <v-col cols="6" class="ma-0 pa-0 pr-3">
                              <hb-select
                                v-model="scheduled_auction.BuyerPremium.type"
                                v-validate="'required'"
                                :items="feeTypes"
                                item-value="value"
                                item-text="text"
                                name="buyer_premium_type" 
                                data-vv-as="Buyer Premium Type"
                                placeholder="Select Buyer Premium Type"
                                :error="errors.collect('buyer_premium_type').length"
                                @blur="validateField('buyer_premium_type')"
                              >
                              </hb-select>
                            </v-col>
                            <v-col cols="6" class="ma-0 pa-0">
                              <hb-text-field 
                                v-model="scheduled_auction.BuyerPremium.value"
                                v-validate="scheduled_auction.BuyerPremium.type === 'percent' ? 'required|decimal:2|min_value:0.1|max_value:100': 'required|decimal:2|min_value:0'"
                                placeholder="Enter Buyer Premium Value"
                                name="buyer_premium_value"
                                data-vv-as="Buyer Premium Value"
                                :error="errors.collect('buyer_premium_value').length"
                                @blur="validateField('buyer_premium_value')"
                              >
                              </hb-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && !isAuctionDay && isMoveOut && isBuyerPremiumPresent">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          <div class="buyer-premium-label">
                            <span class="block">Buyer Premium</span>
                            <span class="ml-1">
                              <hb-tooltip>
                                <template v-slot:item>
                                  <hb-icon small mdi-code="mdi-information"></hb-icon>
                                </template>
                                <template v-slot:body>
                                  Premium paid by the bidder to the auctioneer<br>or online auction company.<br> This is for record purpose only.
                                </template>
                              </hb-tooltip>
                            </span>
                          </div>
                        </v-col>
                        <v-col class="ma-0 pl-4" cols="9" v-if="isMoveOut">
                          <v-row class="pt-0 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0">
                              Type: {{ scheduled_auction.BuyerPremium.type }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0">
                              Value: {{ scheduled_auction.BuyerPremium.value }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0">
                              Product: {{ getAuctionProductNameById(scheduled_auction.BuyerPremium.product_id) }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && isAuctionDay && !isMoveOut">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          <div class="buyer-premium-label">
                            <span class="block">Seller Premium</span>
                            <span class="ml-1">
                              <hb-tooltip>
                                <template v-slot:item>
                                  <hb-icon small mdi-code="mdi-information"></hb-icon>
                                </template>
                                <template v-slot:body>
                                  The premium paid by the property to the auctioneer or online auction company.<br>Some companies refer to it as Purchase Deposit.<br>Final Bid Amount will be the difference between Winning Bid and Seller’s Premium
                                </template>
                              </hb-tooltip>
                            </span>
                          </div>
                        </v-col>
                        <v-col class="ma-0 pl-4" cols="9">
                          <v-row no-gutters style="margin-top:6px;">
                            <v-col cols="6" class="ma-0 pa-0 pr-3">
                              <hb-select
                                v-model="SellerPremium.type"
                                v-validate="'required'"
                                :items="feeTypes"
                                item-value="value"
                                item-text="text"
                                name="seller_premium_type" 
                                data-vv-as="Seller Premium Type"
                                placeholder="Select Seller Premium Type"
                                :error="!!errors.collect('seller_premium_type').length"
                                @blur="validateField('seller_premium_type')"
                              >
                              </hb-select>
                            </v-col>
                            <v-col cols="6">
                              <hb-text-field 
                                v-model="SellerPremium.value"
                                v-validate="SellerPremium?.type === 'percent' ? 'required|decimal:2|min_value:0.1|max_value:100': 'required|decimal:2|min_value:0'"
                                placeholder="Enter Seller Premium Value"
                                name="seller_premium_value"
                                data-vv-as="Seller Premium Value"
                                :error="!!errors.collect('seller_premium_value').length"
                                @blur="validateField('seller_premium_value')"
                              >
                              </hb-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>

                      <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && !isAuctionDay && isMoveOut && isSellerPremiumPresent">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          <div class="buyer-premium-label">
                            <span class="block">Seller Premium</span>
                            <span class="ml-1">
                              <hb-tooltip>
                                <template v-slot:item>
                                  <hb-icon small mdi-code="mdi-information"></hb-icon>
                                </template>
                                <template v-slot:body>
                                  The premium paid by the property to the auctioneer or online auction company.<br>Some companies refer to it as Purchase Deposit.<br>Final Bid Amount will be the difference between Winning Bid and Seller’s Premium
                                </template>
                              </hb-tooltip>
                            </span>
                          </div>
                        </v-col>
                        <v-col class="ma-0 pl-4" cols="9" v-if="isMoveOut">
                          <v-row class="pt-0 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0">
                              Type: {{ SellerPremium.type }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0">
                              Value: {{ SellerPremium.value }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0">
                              Product: {{ getAuctionProductNameById(SellerPremium.product_id) }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>

                      <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && (isAuctionDay || isMoveOut)">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">Auction Winner</v-col>
                        <v-col class="mx-0 mt-4 pa-0 px-4" cols="9" v-if="!isMoveOut">
                          <v-row no-gutters>
                            <v-col cols="6" class="ma-0 pa-0 pr-3">
                              <HbTextField
                                v-if="scheduled_auction.id"
                                :error="errors.has('first_name')"
                                placeholder="Enter First Name"
                                id="first_name"
                                name="first_name"
                                data-vv-as="First Name"
                                v-model="scheduled_auction.Contact.first"
                                :v-validate="schedule_new_auction ? '': 'required'"
                              />
                            </v-col>
                            <v-col cols="6" class="ma-0 pa-0">
                              <HbTextField
                                v-if="scheduled_auction.id"                  
                                v-model="scheduled_auction.Contact.last"
                                :hide-details="!errors.collect('last_name').length"
                                :v-validate="schedule_new_auction ? '': 'required'"
                                data-vv-as="Last Name"
                                placeholder="Enter Last Name"
                                id="last_name"
                                name="last_name"
                                :error="errors.has('last_name')"
                              />
                            </v-col>
                          </v-row>
            
                          <v-row no-gutters>
                            <v-col cols="12" class="mx-0 mt-3 pa-0">
                              <HbTextField
                                v-if="scheduled_auction.id"
                                :error-="errors.has('email')"
                                :hide-details="!errors.collect('email').length"
                                placeholder="Enter Email"
                                id="email"
                                name="email"
                                data-vv-as="Email"
                                v-model="scheduled_auction.Contact.email"
                                :v-validate="schedule_new_auction ? '': 'required'"
                              />
                            </v-col>
                          </v-row>
            
                          <v-row>
                            <v-col cols="7" class="pt-4 pb-0 auction-dialog-phone-code-fix">
                              <VuePhoneNumberInput class="pa-0 custom-styling"
                                v-model="scheduled_auction.Contact.Phones[0].phone"
                                :default-country-code="scheduled_auction.Contact.country_code ? scheduled_auction.Contact.country_code : 'US'"
                                label="Mobile Number"
                                show-code-on-list
                                no-example
                                id="mobile_no"
                                name="mobile_no"
                                @update="updatePhoneNumber"
                                color="#949494"
                                valid-color="#949494"
                                dense
                                flat
                                :translations="mobileNoWording"
                              />                  
                            </v-col>
                          </v-row>
            
                          <v-row>
                            <v-col cols="6">
                              <v-checkbox
                                class="my-0"
                                flat
                                hide-details
                                id="tax_exempt"
                                name="tax_exempt"
                                single-line
                                solo
                                v-model="scheduled_auction.tax_exempt"
                                @change="generateAuctionInvoices"
                              >
                                <span slot="label">Tax Exempt (Reseller License)</span>
                              </v-checkbox>
                            </v-col>
                          </v-row>
                          <v-row v-if="scheduled_auction.tax_exempt">
                            <v-col cols="6">
                              <v-text-field
                                v-if="scheduled_auction.id"
                                :error-messages="errors.collect('license_number')"
                                :hide-details="!errors.collect('license_number').length"
                                label="Reseller License Number"
                                class="pa-0 mt-0"
                                flat
                                id="license_number"
                                name="license_number"
                                data-vv-as="license number"
                                v-model="scheduled_auction.license_number"
                                :v-validate="schedule_new_auction ? '': 'required'"
                                v-mask="'##-######'"
                              >
                              </v-text-field>
                            </v-col>
                          </v-row>
                        </v-col>
                        <v-col class="mx-0 pl-4" cols="9" v-if="isMoveOut" style="margin-top:2px;">
                          <v-row
                            class="pt-0 pl-4"
                            v-if="scheduled_auction.id"
                          >
                            <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                              {{ scheduled_auction.Contact.first }} {{ scheduled_auction.Contact.last }}
                            </v-col>
                          </v-row>
                          <v-row
                            class="pt-1 pl-4"
                            v-if="scheduled_auction.id"
                          >
                            <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                              {{ scheduled_auction.Contact.email }}
                            </v-col>
                          </v-row>
                          <v-row
                            class="pt-1 pl-4"
                            v-if="scheduled_auction.id"
                          >
                            <v-col v-if="scheduled_auction.Contact.Phones.length && scheduled_auction.Contact.Phones[0].phone" cols="11" class="pa-0 ma-0">
                              {{ 1 + scheduled_auction.Contact.Phones[0].phone | formatPhone }}
                            </v-col>
                          </v-row>
                          <v-row
                            class="pt-1 pl-4"
                            v-if="scheduled_auction.id && scheduled_auction.tax_exempt"
                          >
                            <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                              Tax Exempt (Reseller License)
                            </v-col>
                          </v-row>
                          <v-row
                            class="pt-1 pl-4"
                            v-if="scheduled_auction.id && scheduled_auction.tax_exempt"
                          >
                            <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">
                              {{ scheduled_auction.license_number }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>

                      <VDivider class="auction-dialog-temp-divider" />

                    </span>
          
                    <v-row class="hb-table-row ma-0 pa-0">
                      <v-col class="hb-table-label py-4 pl-4" cols="3"> <span v-if="change_auction_info">Reason for Rescheduling</span><span v-else>Notes</span></v-col>
                      <v-col class="ma-0 pa-4" :class="{'hb-form-editable' : scheduled_auction.id && fieldEditing != 'notes' && !isAuctionDay && !isMoveOut}" cols="9">
                        <v-row class="pa-0" no-gutters>
                          <v-col
                            cols="12"
                            class="ma-0 pa-0"
                            v-if="!scheduled_auction.id || fieldEditing == 'notes' || (isAuctionDay && stepper === 3) || change_auction_info"
                          >
                            <div class="hb-text-light mb-3">Add in Reminder Notes about this Auction</div>
                            <HbTextarea
                              v-validate="'max:1000'"
                              data-vv-as="Notes"
                              placeholder="Enter Notes"
                              id="notes"
                              name="notes"
                              :error="errors.has('notes')"
                              v-model="scheduled_auction.notes"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            v-if="scheduled_auction.id && fieldEditing == 'notes'"
                            class="ma-0 pt-4 pb-0 mb-n1 d-flex align-center"
                          >
                            <hb-btn
                              color="primary"
                              small
                              @click="scheduleAuction"
                              :disabled="isLoading($options.name)"
                              >Save</hb-btn
                            >
                            <hb-link class="ml-2" small @click="clearEditField()"
                              >Cancel</hb-link
                            >
                          </v-col>
                        </v-row>
                        <v-row
                          class="pl-3"
                          v-if="fieldEditing != 'notes' && !isAuctionDay && !isMoveOut && !change_auction_info && ((scheduled_auction.auction_type === 'storage-treasures' && !isPublished) || scheduled_auction.auction_type !== 'storage-treasures')"
                          @click="editField('notes')"
                        >
                          <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">{{ scheduled_auction.notes }}</v-col>
                          <v-col v-if="scheduled_auction.id" cols="1" class="pa-0 ma-0">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <v-hover v-slot:default="{ hover }">
                                  <span v-on="on">
                                    <hb-icon small :color="hover ? '#101318' : ''"
                                      >mdi-pencil</hb-icon
                                    >
                                  </span>
                                </v-hover>
                              </template>
                              <span>Edit</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row
                          class="pl-4"
                          v-else-if="!change_auction_info && fieldEditing != 'notes' && (stepper === 2 || isMoveOut)"
                        >
                          <v-col v-if="scheduled_auction.id" cols="11" class="pa-0 ma-0">{{ scheduled_auction.notes }}</v-col>
                        </v-row>
                      </v-col>
                    </v-row>

                    <VDivider v-if="stepper === 2" class="auction-dialog-temp-divider" />

                    <span v-if="stepper === 3">
                      <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && isAuctionDay && !isMoveOut">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          <span>Summary</span>
                        </v-col>
                        <v-col class="mx-0 mb-0 pl-4" cols="9" style="margin-top:2px;">
                          <v-row class="pt-0 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body">
                              Winning Bid: ${{ summary.winning_bid }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body">
                              Seller's Premium: ${{ summary.seller_premium_cost }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body-medium">
                              Remaining Bid: ${{ summary.remaining_bid }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body-medium">
                              Cleaning Deposit: ${{ summary.cleaning_deposit }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body-medium">
                              Auction Tax: ${{ summary.total_tax }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body-medium">
                              Invoice Total: ${{ summary.invoice_total }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="hb-table-row ma-0 pa-0" v-if="!change_auction_info && !isAuctionDay && isMoveOut && isSummary">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          <span>Summary</span>
                        </v-col>
                        <v-col class="mx-0 mb-0 pl-4" cols="9" style="margin-top:2px;">
                          <v-row class="pt-0 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body">
                              Winning Bid: ${{ summary.winning_bid }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body">
                              Seller's Premium: ${{ summary.seller_premium_cost }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body-medium">
                              Remaining Bid: ${{ summary.remaining_bid }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body-medium">
                              Cleaning Deposit: ${{ summary.cleaning_deposit }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body-medium">
                              Auction Tax: ${{ summary.total_tax }}
                            </v-col>
                          </v-row>
                          <v-row class="pt-3 pl-4" v-if="scheduled_auction.id">
                            <v-col class="pa-0 ma-0 hb-font-body-medium">
                              Invoice Total: ${{ summary.invoice_total }}
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
          
                      <div class="pt-6 px-6" v-if="!change_auction_info && isAuctionDay" style="background: #f9fafb;">

                        <hb-notification
                          v-model="showWarning"
                          v-if="showWarning"
                          type="caution"
                          notDismissable
                        >
                          Please add all the required fees and charges before processing the payment. Fees and Charges cannot be added after the ‘Auction Payment' is processed.
                        </hb-notification>
            
                        <payment-process 
                            :propertyID="property_id"  
                            :invoice="auction_invoices"
                            paymentSource="AUCTION">
                        </payment-process>
            
                      </div>

                    </span>

                  </template>

                  <template v-slot:footer>
                    <hb-bottom-action-bar :top-border="stepper === 3 && !change_auction_info && isAuctionDay ? true : false" cancel-off>
                        <template v-slot:right-actions>
                          <v-progress-circular
                            v-if="isLoading($options.name) || disable_payment"
                            indeterminate
                            color="primary"
                            class="mr-0"
                          ></v-progress-circular>
                          <span>
                            <span v-if="change_auction_info && !schedule_new_auction" style="position:relative;top:2px;">
                              <hb-link @click="backToAddInfo">Back</hb-link>
                            </span>
                            <hb-btn
                              v-if="stepper === 2 && ((scheduled_auction.id && !isAuctionDay && !isMoveOut) || change_auction_info)"
                              color="secondary"
                              @click="showRemoveAuctionConfirmation = true"
                            >
                              Cancel Auction
                            </hb-btn>
                            <hb-btn
                              v-if="!scheduled_auction.id || change_auction_info"
                              color="primary"
                              @click="scheduleAuction(false, true)"
                              :disabled="!enableNewSchedule || isLoading($options.name)"
                              >Schedule Auction</hb-btn
                            >
                            <!--
                            <hb-btn v-if="scheduled_auction.id && !isAuctionDay && !isMoveOut && ((scheduled_auction.auction_type === 'storage-treasures' && isPublished) || scheduled_auction.auction_type !== 'storage-treasures')" color="primary" @click="close"
                              >Close</hb-btn
                            >
                            -->
                            <hb-btn v-if="stepper === 2 && scheduled_auction.id && isAuctionDay && !change_auction_info" color="secondary" @click="showChangeAuctionConfirmation = true" :disabled="disable_payment"
                              >Change Auction</hb-btn
                            >
                            <hb-tooltip
                              v-if="stepper === 2 && scheduled_auction.id && scheduled_auction.auction_type === 'storage-treasures' && !change_auction_info && !isPublished"
                              :disabled="imagesAndInventoryCompleted"
                              max-width="400"
                            >
                              <template v-slot:item>
                                  <hb-btn
                                    color="primary"
                                    @click="publishStorageTreasuresAuction"
                                    :disabled="isLoading($options.name) || !canPublishToStorageTreasures || isPublishing"
                                    :loading="isPublishing"
                                    >Publish Auction to Storage Treasures</hb-btn
                                  >
                              </template>
                              <template v-slot:body>
                                Auction Images &amp; Space Inventory details are not completed. You must set these in order to publish to Storage Treasures. Please upload images and set space inventory details in the "Auction Images &amp; Space Inventory" tab or through our Mobile Manager App.
                              </template>
                            </hb-tooltip>
                            <span v-if="stepper === 3">
                              <hb-btn v-if="scheduled_auction.id && isAuctionDay  && !change_auction_info" color="secondary" @click="scheduleAuction" :disabled="enableSaveBtn || disable_payment || isLoading($options.name)"
                                >Save</hb-btn
                              >
                              <hb-btn v-if="scheduled_auction.id && isAuctionDay && !change_auction_info" color="primary" @click="checkPaymentsErrors" :disabled="enableSaveBtn || disable_payment" :loading="disable_payment"
                                >Process Payment</hb-btn
                              >
                              <hb-btn v-if="scheduled_auction.id && isMoveOut" color="primary" @click="auctionMoveOut" :disabled="auctionLease.auction_status != 'move_out'"
                                >Move-Out</hb-btn
                              >
                            </span>
                          </span>
                        </template>
                    </hb-bottom-action-bar>
                  </template>

                </hb-expansion-panel>

              </v-expansion-panels>

            </div>
  
            <hb-modal v-model="showEditAuctionConfirmation" show-help-link v-if="showEditAuctionConfirmation" size="medium" confirmation title="Confirm Auction Update" @close="closeEditConfirmation">
              <template v-slot:content>
                  <div class="pa-6">
                    <span class="fw-500">You are about to reschedule the auction date for Space {{ auctionLease.Unit.number }}.</span>
                    <br /><br />
  
                    Changing an auction date may not comply with your states legal requirements. Please verify that this aligns with your lien process.
  
                    <br /><br />
                    <span class="fw-500">Are you sure you want to reschedule?</span>
                  </div>
              </template>
              <template v-slot:actions>
                  <hb-btn @click="scheduleAuction(false, true)" color="primary" :disabled="isLoading($options.name)" >Confirm</hb-btn>
              </template>
            </hb-modal>

            <!-- Change Auction Confirmation Modal -->
            <hb-modal v-model="showChangeAuctionConfirmation" show-help-link v-if="showChangeAuctionConfirmation" size="medium" confirmation title="Confirm Auction Change" @close="showChangeAuctionConfirmation = false">
              <template v-slot:content>
                  <div class="pa-6">
                    <span class="fw-500">You are about to change the auction date for Space {{ auctionLease.Unit.number }}.</span>
                    <br /><br />
                    Changing an auction date may not comply with your states legal requirements, please verify that this aligns with your lien process.
                    <br /><br />
                    <span>If you schedule a new date, any information you've previously saved will also be deleted.</span>
  
                    <span v-if="scheduled_auction.auction_type === 'storage-treasures' && isPublished">
                      <br /><br />
                      <span>*The auction details for Storage Treasures auctions cannot be changed after they have been published to Storage Treasures. You can only delete the published auction and schedule a new auction.</span>
                    </span>
                  </div>
              </template>
              <template v-slot:right-actions>
                  <hb-btn @click="scheduleNewAuction" color="secondary">Schedule New Auction</hb-btn>
                  <hb-btn v-if="(scheduled_auction.auction_type === 'storage-treasures' && !isPublished) || scheduled_auction.auction_type !== 'storage-treasures'" @click="changeAuctionInfo" color="primary">Change Info</hb-btn>
              </template>
            </hb-modal>
            <!-- Change Auction Confirmation Modal -->
            <!-- Schedule New Auction Modal -->
            <hb-modal v-model="schedule_new_auction" show-help-link v-if="schedule_new_auction" :title="'Schedule New Auction: Space ' + auctionLease.Unit.number" @close="schedule_new_auction = false">
              <template v-slot:subheader>
                <span>Choose whether this is going to be an online or live auction, and then schedule the date and time for the auction.</span>
              </template>
              <template v-slot:content>
                  <!--
                  <status
                    @resetStatus="errorClear($options.name)"
                    v-if="errorHas($options.name)"
                    :message="errorGet($options.name)"
                    status="error"
                    class="pa-3"
                  ></status>
                  <status
                    @resetStatus="successClear($options.name)"
                    v-if="successHas($options.name)"
                    :message="successGet($options.name)"
                    status="success"
                    class="pa-3"
                  ></status>
                  -->
                  <div class="pa-0 ma-0">
                    <div class="pa-0 ma-0">
                      <v-row class="hb-table-row ma-0 pa-0">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          Auction Type &amp; Platform
                        </v-col>
                        <v-col class="ma-0 pa-4" cols="6">
                          <HbSelect
                            v-model="scheduled_auction.auction_type"
                            :items="auctionTypes"
                            item-value="value"
                            item-text="text"
                            name="auction_type"
                            data-vv-as="Auction Type &amp; Platform"
                            label="Select Auction Type &amp; Platform"
                            :clearable="false"
                            :error="errors.has('auction_type')"
                            v-validate="'required'"
                          />
                        </v-col>
                      </v-row>
                      <v-row class="hb-table-row ma-0 pa-0">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">
                          Auction Date &amp; Time
                        </v-col>
                        <v-col class="ma-0 py-4 pt-0" cols="9">
                          <v-row class="pa-0">
                            <v-col
                              cols="6"
                            >
                              <hb-date-picker
                                v-model="scheduled_auction.date"
                                id="date"
                                name="due_date"
                                label="Select Starting Date for Auction"
                                :solo="false"
                                :min="minAuctionDate"
                              ></hb-date-picker>
                            </v-col>
                            <v-col
                              cols="6"
                            >
                              <label class="v-label v-label--active theme--light auction-dialog-font-11px">Select Starting Time for Auction</label>
                              <v-row no-gutters class="mt-1">
                                <v-col md="3" class="ma-0 pa-0">
                                  <hb-text-field
                                    id="auction_hour"
                                    name="auction_hour"
                                    placeholder="HH"
                                    v-model="timeValue.hours"
                                    data-vv-as="Starting Hour"
                                    type="number"
                                    min="1"
                                    max="12"
                                    width="80%"
                                    v-validate="'required|numeric|max_value:12|min_value:1'"
                                    :error="errors.has('auction_hour')"
                                    class="mt-0 pt-0 time-w-80"
                                  ></hb-text-field>
                                </v-col>
                                <v-col md="3" class="ma-0 pa-0">
                                  <hb-text-field
                                    id="auction_minutes"
                                    name="auction_minutes"
                                    placeholder="MM"
                                    v-model="timeValue.minutes"
                                    data-vv-as="Starting Minute"
                                    type="number"
                                    min="0"
                                    max="59"
                                    width="80%"
                                    v-validate="'required|numeric|max_value:59|min_value:0'"
                                    :error="errors.has('auction_minutes')"
                                    class="mt-0 pt-0 time-w-80"
                                  ></hb-text-field>
                                </v-col>
                                <v-col md="6" class="ma-0 pa-0">
                                  <hb-select
                                    :items="['AM','PM']"
                                    id="auction_ampm"
                                    name="auction_ampm"
                                    v-model="timeValue.ampm"
                                    :clearable="false"
                                    placeholder="AM/PM"
                                    v-validate="'required'"
                                    data-vv-name="auction_ampm"
                                    data-vv-as="Starting AM/PM"
                                    width="80%"
                                    :error="errors.has('auction_ampm')"
                                    class="mt-0 pt-0 time-w-80"
                                  ></hb-select>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                      <v-row class="hb-table-row ma-0 pa-0">
                        <v-col class="hb-table-label py-4 pl-4" cols="3">Notes</v-col>
                        <v-col class="ma-0 pa-4" cols="9">
                          <v-row class="pa-0" no-gutters>
                            <v-col
                              cols="12"
                              class="ma-0 pa-0"
                            >
                              <div class="hb-text-light mb-3">Add in Reminder Notes about this Auction</div>
                              <HbTextarea
                                class="mt-0 pt-0"
                                v-validate="'max:1000'"
                                data-vv-as="Notes"
                                placeholder="Enter Notes"
                                id="notes"
                                name="notes"
                                :error="errors.has('notes')"
                                v-model="scheduled_auction.notes"
                              />
                            </v-col>
                          </v-row>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
              </template>
              <template v-slot:right-actions>
                <hb-btn @click="scheduleAuction(false, true)" color="primary" :disabled="isLoading($options.name)">Schedule New Auction</hb-btn>
              </template>
            </hb-modal>
            <!-- Schedule New Auction Modal -->
          </div>
        </div>
        
        <v-row no-gutters class="auction-manager-bab-wrapper" style="z-index:999;">
          <hb-bottom-action-bar cancel-off class="auction-manager-bab">
            <template v-slot:right-actions>
              <hb-menu>
                  <template v-slot:menu-activator>
                    <hb-btn color="secondary" append-icon="mdi-menu-down">Go to</hb-btn>
                  </template>
                  <v-list>
                    <v-list-item
                      v-if="stepper !== 0"
                      @click="stepper = 0"
                      :ripple="false"
                    >
                      <v-list-item-title>Images and Inventory</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="stepper !== 1"
                      @click="stepper = 1"
                      :ripple="false"
                    >
                      <v-list-item-title>Audit</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="stepper !== 2"
                      @click="stepper = 2"
                      :ripple="false"
                    >
                      <v-list-item-title>{{ scheduled_auction.auction_type === 'storage-treasures' && auctionLease.auction_status === 'scheduled' ? 'Schedule &amp; Publish Auction' : 'Schedule Auction' }}</v-list-item-title>
                    </v-list-item>
                    <v-list-item
                      v-if="stepper !== 3"
                      @click="stepper = 3"
                      :ripple="false"
                    >
                      <v-list-item-title>Auction Sale</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </hb-menu>
            </template>
          </hb-bottom-action-bar>
        </v-row>
    <hb-modal
      size="medium"
      title="Confirm Payment"
      v-model="confirm_process_payment"
      confirmation
      show-help-link
    >
      <template v-slot:content>
        <div class="py-4 px-6">
          Please add all the required fees and charges before processing the payment. Fees and Charges cannot be added after the 'Auction Payment' is processed.
        </div>
      </template>
      <template v-slot:actions>
        <hb-btn color="primary" @click="confirmProcessPayment" :disabled="disable_payment">Would you like to proceed?</hb-btn>
      </template>
    </hb-modal>

    <hb-modal v-model="showRemoveAuctionConfirmation" show-help-link v-if="showRemoveAuctionConfirmation" size="medium" confirmation title="Confirm Auction Removal" @close="closeRemoveConfirmation">
      <template v-slot:content>
          <div class="pa-6">
            <span class="fw-500">You are about to remove the auction date for Space {{ auctionLease.Unit.number }}.</span>
            <br /><br />

            Changing an auction date may not comply with your states legal requirements. Please verify that this aligns with your lien process.

            <br /><br />
            <span class="fw-500">Are you sure you want to remove?</span>
          </div>
      </template>
      <template v-slot:actions>
          <hb-btn @click="removeAuctionSchedule" color="destructive">Confirm</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>
<script type="text/babel">
import MiniProfileView from "../includes/LeadIntake/MiniProfileView.vue";
import Delinquency from "../contacts/Delinquency.vue";
import Status from "../includes/Messages.vue";
import { EventBus } from "../../EventBus.js";
import api from "../../assets/api.js";
import Loader from "../assets/CircleSpinner.vue";
import HbDatePicker from "../assets/HummingbirdDatepicker";
import HbTimePicker from "../assets/HummingbirdTimepicker";
import VuePhoneNumberInput from 'vue-phone-number-input';
import moment from "moment";
import PaymentProcess from '../includes/PaymentProcess/Index.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { notificationMixin } from  '../../mixins/notificationMixin.js';
import axios from 'axios';
import https from 'https';

export default {
  name: "AuctionManager",
  mixins: [ notificationMixin ],
  data() {
    return {
      auctionTypes: [
        { text: "Online Auction (Any Platform)", value: "online" },
        { text: "Online Auction (Storage Treasures)", value: "storage-treasures" },
        { text: "Live Auction", value: "holler" },
      ],
      feeTypes: [
        { text: "%", value: "percent" },
        { text: "Fixed Rate", value: "fixed" },
      ],
      auctionProducts: [],
      scheduled_auction: {
        Contact: {
          Phones: [{phone: "", type: ""}],
          country_code: "",
          email: "",
          first: "",
          last: ""
        },
        id: "",
        auction_type: "",
        scheduled_date: "",
        date: "",
        time: "",
        notes: "",
        cleaning_deposit: null,
        cleaning_period: null,
        amount: null,
        tax_exempt: false,
        license_number: '',
        country_code: '',
        mobile_no: '',
        refund_id: '',
        BuyerPremium: {
          type: "",
          value: "",
          is_inclusive: false,
          amount: 0
        },
      },
      SellerPremium: null,
      summary: {
        remaining_bid: 0,
        winning_bid: 0,
        buyer_premium_cost: 0,
        seller_premium_cost: 0,
        total_tax: 0,
        cleaning_deposit: 0,
        invoice_total: 0,
      },
      mobileNoWording: {
        countrySelectorLabel: '',
        countrySelectorError: 'Select country code',
        phoneNumberLabel: 'Mobile Number'
      },
      fieldEditing: "",
      showRemoveAuctionConfirmation: false,
      showEditAuctionConfirmation: false,
      showChangeAuctionConfirmation: false,
      change_auction_info: false,
      schedule_new_auction: false,
      auction_invoices: [],
      disable_payment: false,
      process_payment: false,
      confirm_process_payment: false,
      showWarning: true,
      timeValue: {
        hours: '',
        minutes: '',
        ampm: ''
      },
      stepper: [],
      categories: [],
      media: [],
      mediaDescription: '',
      tamperTag: '',
      mediaViewer: false,
      categoriesSelected: [],
      fetchedSelectedCategories: [],
      forceScrollToTopSyncer: false,
      fetchingMedia: true,
      allowMediaSave: false,
      uploads: [],
      savingAuctionMediaInProgess: false,
      auctionImageSet: {},
      imageError: false,
      delinquency: [],
      fetchingDelinquency: false,
      stepOneImagesAccordion: [ 0 ],
      auctionFormAccordion: [ 0 ],
      auctionUpdating: false,
      isPublishing: false,
    };
  },
  components: {
    MiniProfileView,
    Delinquency,
    Status,
    Loader,
    HbDatePicker,
    HbTimePicker,
    VuePhoneNumberInput,
    PaymentProcess
  },
  computed: {
    ...mapGetters({
      getErrors: 'paymentsStore/getErrors',
      hasPermission: "authenticationStore/rolePermission"
    }),
    minAuctionDate() {
      return (this.change_auction_info) ? null : moment().add(1, 'day').format('YYYY-MM-DD');
    },
    dialog: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    isAuctionDay() {
      return (this.auctionLease.auction_status == 'auction_day' || this.auctionLease.auction_status == 'auction_payment') ? true : false;
    },
    isMoveOut() {
      return (this.auctionLease.auction_status == 'move_out' || this.auctionLease.auction_status == 'complete') ? true : false;
    },
    enableSaveBtn() {
      return (
        !this.scheduled_auction.amount ||
        !this.scheduled_auction.Contact.first ||
        !this.scheduled_auction.Contact.last ||
        !this.scheduled_auction.Contact.email ||
        !this.scheduled_auction.BuyerPremium.type ||
        (isNaN(parseFloat(this.scheduled_auction.BuyerPremium.value)) || this.scheduled_auction.BuyerPremium.value < 0) ||
        !this.SellerPremium.type ||
        (isNaN(parseFloat(this.SellerPremium.value)) || this.SellerPremium.value < 0)
      );
    },
    showSubHeader() {
      return ((this.auctionLease.auction_status !== 'complete' && this.auctionLease.auction_status !== 'move_out') || this.isAuctionDay || this.stepper === 0) ? true : false;
    },
    auction_bidding_amount() {
      return this.auction_invoices && this.auction_invoices.balance;
    },
    isBuyerPremiumPresent() {
      return this.scheduled_auction.BuyerPremium.type && this.scheduled_auction.BuyerPremium.value && this.scheduled_auction.BuyerPremium.product_id
    },
    isSellerPremiumPresent() {
      return this.SellerPremium.type && this.SellerPremium.value && this.SellerPremium.product_id
    },
    isSummary() {
      return this.summary.remaining_bid !== 0 || this.summary.buyer_premium_cost !== 0 || this.summary.seller_premium_cost !== 0 || this.summary.cleaning_deposit !== 0 || this.summary.total_tax !== 0 || this.summary.invoice_total !== 0 || this.summary.winning_bid !== 0
    },
    enableNewSchedule() {
      return !!this.scheduled_auction.auction_type;
    },
    auctionCleaningDeposit() {
      return (this.auctionLease.LeaseAuction && this.auctionLease.LeaseAuction.cleaning_deposit && !this.auctionLease.LeaseAuction.refund_id) ? this.auctionLease.LeaseAuction.cleaning_deposit : 0;
    },
    disableRefundButton(){
      if (this.auctionLease.LeaseAuction.refund_id || this.auctionCleaningDeposit <= 0){
        return true;
      }
      else if (this.auctionLease.LeaseAuction.has_clean_out_period_passed){
        return !this.hasPermission('refund_after_clean_out_period');
      }
      return false;
    },
    isPublished(){
      return (this.scheduled_auction.vendor_auction_id) ? true : false;
    },
    tenDaysFromScheduledAuction(){
      if(this.scheduled_auction.scheduled_date){
        return moment(this.scheduled_auction.scheduled_date).add(10, 'days');
      } else {
        return '';
      }
    },
    imagesAndInventoryCompleted(){
      if(this.auctionImageSet && this.auctionImageSet.uploads && this.auctionImageSet.uploads.length && this.auctionImageSet.description && this.auctionImageSet.categories && this.auctionImageSet.categories.length){
        return true;
      } else {
        return false;
      }
    },
    auditCompleted(){
      return false;
    },
    completed0() {
      if(this.imagesAndInventoryCompleted){
        return true;
      } else {
        return false;
      }
    },
    completed1() {
      if(this.auditCompleted){
        return true;
      } else {
        return false;
      }
    },
    completed2() {
      if(this.scheduled_auction.id){
        return true;
      } else {
        return false;
      }
    },
    completed3() {
      if(this.isMoveOut){
        return true;
      } else {
        return false;
      }
    },
    hasNoErrors0() {
      return true;
    },
    hasNoErrors1() {
      return true;
    },
    hasNoErrors2() {
      return true;
    },
    hasNoErrors3() {
      return true;
    },
  },
  async created() {
    this.getAuctionCategories();
    this.getAuctionMedia();
    this.getAuctionProducts();
    this.fetchDelinquencies();
    const { LeaseAuction } = this.auctionLease;
    
    let momentData = moment().add(1, 'day');
    this.scheduled_auction.date = momentData.format("YYYY-MM-DD");
    let hoursOriginal = momentData.hours();
    let hours = hoursOriginal < 12 ? hoursOriginal : hoursOriginal == 12 ? 12 : (hoursOriginal - 12);
    hours = hours < 10 ? "0" + hours : hours;
    let minutes = momentData.minutes();
    minutes = minutes < 10 ? "0" + minutes : minutes;
    this.timeValue = {
      hours,
      minutes,
      ampm: hoursOriginal < 12 ? 'AM' : 'PM'
    }

    if (LeaseAuction && Object.keys(LeaseAuction).length!== 0) {
      this.scheduled_auction = JSON.parse(JSON.stringify(LeaseAuction));
      this.SellerPremium = LeaseAuction.SellerPremium? JSON.parse(JSON.stringify(LeaseAuction.SellerPremium)): null;
      if(!this.scheduled_auction.contact_id){
        this.scheduled_auction.Contact = {
          Phones: [{phone: "", type: ""}],
          country_code: "",
          email: "",
          first: "",
          last: ""
        };
      } else {
        if(!this.scheduled_auction.Contact.Phones.length){
          this.scheduled_auction.Contact.Phones = [{phone: "", type: ""}];
        } else {
          this.scheduled_auction.Contact.Phones[0].phone = this.scheduled_auction.Contact.Phones[0].phone.slice(1);
        }
      }
      let localScheduleDateTime = moment(
        this.scheduled_auction.scheduled_date
      ).format("YYYY-MM-DD HH:mm:ss");

      this.scheduled_auction.scheduled_date = localScheduleDateTime;
      this.scheduled_auction.date = moment(localScheduleDateTime).format(
        "YYYY-MM-DD"
      );
      let mommentTime = moment(localScheduleDateTime);
      let hoursOriginal = mommentTime.hours();
      let hours = hoursOriginal < 12 ? hoursOriginal : hoursOriginal == 12 ? 12 : (hoursOriginal - 12);
      hours = hours === 0 ? 12 : hours < 10 ? "0" + hours : hours;
      let minutes = mommentTime.minutes();
      minutes = minutes < 10 ? "0" + minutes : minutes;
      this.timeValue = {
        hours,
        minutes,
        ampm: hoursOriginal < 12 ? 'AM' : 'PM'
      }
      if (!this.scheduled_auction.BuyerPremium) {
        this.scheduled_auction.BuyerPremium = {
          type: "fixed",
          value: 0,
          is_inclusive: false,
          amount: 0
        }
      }
      if (!this.SellerPremium) {
        this.SellerPremium = {
          type: "fixed",
          value: 0,
          is_inclusive: false,
          amount: 0
        }
      }
      
      this.scheduled_auction.auction_cleaning_deposit = this.scheduled_auction.cleaning_deposit === null ? this.scheduled_auction.default_cleaning_deposit : this.scheduled_auction.cleaning_deposit;
      this.scheduled_auction.auction_cleaning_period = this.scheduled_auction.cleaning_period === null ? this.scheduled_auction.default_cleaning_period : this.scheduled_auction.cleaning_period;
    }

    if(this.isAuctionDay) {
      this.generateAuctionInvoices();
    } else if(LeaseAuction.BidInvoice && Object.keys(LeaseAuction.BidInvoice).length !== 0){
      this.generateAuctionInvoices();
    }

    EventBus.$on('paymentReader', this.enablePayment);

    if(this.firstTab === true){
      this.stepper = 0;
    } else if(this.scheduled_auction.auction_type === 'storage-treasures' && !this.isPublished){
      this.stepper = 2;
    } else if(!this.isAuctionDay && !this.isMoveOut){
      this.stepper = 2;
    } else if(this.isAuctionDay || this.isMoveOut){
      this.stepper = 3;
    } else {
      this.stepper = 0;
    }
  },
  mounted() {
  },
  destroyed(){
    EventBus.$off('paymentReader', this.enablePayment);
    this.closeMessageNotification();
  },
  filters: {
    formatDays(value) {
      return `${value} Days`;
    }
  },
  props: [
    "auctionLease",
    "contact_id",
    "property_id",
    "value",
    "payments",
    "unallocated",
    "auction_refund_id",
    // "message",
    "firstTab",
    "delinquencyId"
  ],
  methods: {
    ...mapActions({
      getTransformedPaymentObject: 'paymentsStore/getTransformedPaymentObject',
      getTasks: 'taskCenterStore/getTasks',
    }),
    ...mapMutations({
      setCheckErrors: 'paymentsStore/setCheckErrors',
      checkOnlyCredit:'paymentsStore/checkOnlyCredit'
    }),
    getAuctionProducts() {
      api.get(this, api.PRODUCTS + 'list?type=auction&category_type=fee').then(response => {
        const products = response.products.map(p => {
          return {
            value: p.id,
            text: p.name
          }
        });
        this.auctionProducts = products;
      }).catch((err) => {
        this.products = []
      });
    },
    getAuctionProductNameById(id) {
      const product = this.auctionProducts.find(item => item.value === id);
      return product ? product.text : "";
    },
    async enablePayment(response) {
      this.disable_payment = false;
      if(response && response.error) {
        this.showMessageNotification({ list: [response.error] })
      } else if(response && response.data && response.data.msg) {
        this.showMessageNotification({ type: 'success', description: response.data.msg.text });
        EventBus.$emit('contact_updated',  this.contact_id);
        this.fetchAuctionContact();
        EventBus.$emit('reservation_made');
        EventBus.$emit('payment_saved');
        if(this.process_payment && response.data.payment && response.data.payment.id) {
          this.$emit("processPayment", response.data.payment.id);
        }
        this.getTasks({ reset: true, role_ids: this.rolesIDs });
        // this.$emit("close");
        this.schedule_new_auction = false;
        this.change_auction_info = false;
      }
    },
    backToAddInfo() {
      this.change_auction_info = false;
      this.schedule_new_auction = false;
    },
    scheduleNewAuction() {
      this.showChangeAuctionConfirmation = false;
      this.schedule_new_auction = true;
    },
    changeAuctionInfo() {
      this.showChangeAuctionConfirmation = false;
      this.dialog = true;
      this.change_auction_info = true;
      this.stepper = 2;
    },
    auctionMoveOut() {
      this.$emit("close");
      this.$emit('moveOut', this.auctionLease);
    },
    /**
     * Winning bid amount and cleaning deposit will come from settings so their value will
     * be present. If not then we have either remove it or entered an invalid value.
     * That is why they can be put dependent on each other and other fields while validating.
     * param {The field to be validated} field 
     */
    async validateField(field) {
      let status = true;
      switch (field) {
        case 'winning_bid_amount':
          status = await this.$validator.validate('winning_bid_amount');
          status = status && await this.$validator.validate('cleaning_deposit');
          break;
        case 'cleaning_deposit':
          status = await this.$validator.validate('cleaning_deposit');
          status = status && await this.$validator.validate('winning_bid_amount');
          break;
        case 'buyer_premium_type':
          status = await this.$validator.validate('buyer_premium_type');
          status = status && await this.$validator.validate('winning_bid_amount');
          status = status && await this.$validator.validate('cleaning_deposit');
          if (status && this.scheduled_auction.BuyerPremium.value) {
            status = await this.$validator.validate('buyer_premium_value');
          } else {
            status = false;
          }
          break;
        case 'buyer_premium_value':
          status = await this.$validator.validate('buyer_premium_value');
          status = status && await this.$validator.validate('winning_bid_amount');
          status = status && await this.$validator.validate('cleaning_deposit');
          if (status && this.scheduled_auction.BuyerPremium.type) {
            status = await this.$validator.validate('buyer_premium_type');
          } else {
            status = false;
          }
          break;
          case 'seller_premium_type':
          status = await this.$validator.validate('seller_premium_type');
          status = status && await this.$validator.validate('winning_bid_amount');
          status = status && await this.$validator.validate('cleaning_deposit');
          if (status && this.SellerPremium.value) {
            status = await this.$validator.validate('seller_premium_value');
          } else {
            status = false;
          }
          break;
        case 'seller_premium_value':
          status = await this.$validator.validate('seller_premium_value');
          status = status && await this.$validator.validate('winning_bid_amount');
          status = status && await this.$validator.validate('cleaning_deposit');
          if (status && this.SellerPremium.type) {
            status = await this.$validator.validate('seller_premium_type');
          } else {
            status = false;
          }
          break;
        default:
          break;
      }
      if (status) {
        this.generateAuctionInvoices();
      }
    },
    async generateAuctionInvoices() {
      try {        
        let auctionInvoiceData = {
          winning_bid_amount: this.scheduled_auction.amount,
          lease_auction_id: this.scheduled_auction.id,
          cleaning_deposit: this.scheduled_auction.auction_cleaning_deposit,
          tax_exempt: this.scheduled_auction.tax_exempt,
        }
        if (this.scheduled_auction.BuyerPremium.type && this.scheduled_auction.BuyerPremium.value) {
          auctionInvoiceData.BuyerPremium = {
            type: this.scheduled_auction.BuyerPremium.type,
            value: this.scheduled_auction.BuyerPremium.value,
            is_inclusive: this.scheduled_auction.BuyerPremium.is_inclusive,
            amount: this.SellerPremium.amount
          }
          if (this.scheduled_auction.BuyerPremium.id) {
            auctionInvoiceData.BuyerPremium.id = this.scheduled_auction.BuyerPremium.id
          }
        }
        if (this.SellerPremium.type && this.SellerPremium.value) {
          auctionInvoiceData.SellerPremium = {
            type: this.SellerPremium.type,
            value: this.SellerPremium.value,
            is_inclusive: this.SellerPremium.is_inclusive,
            amount: this.SellerPremium.amount
          }
          if (this.SellerPremium.id) {
            auctionInvoiceData.SellerPremium.id = this.SellerPremium.id
          }
        }
        let response = await api.post(this, `${api.AUCTIONS}compute-auction-data`, auctionInvoiceData);
        this.auction_invoices = response.lease_auction.invoices;
        this.SellerPremium = response.lease_auction.SellerPremium || this.SellerPremium;
        this.auction_invoices.balance = response.lease_auction.invoice_total;
        this.calculateSummary()
      } catch (error) {
        this.showMessageNotification({ description: error.toString() });
      }
    },
    resetSummary() {
      this.summary.winning_bid = 0;
      this.summary.remaining_bid = 0;
      this.summary.buyer_premium_cost = 0;
      this.summary.seller_premium_cost = 0;
      this.summary.cleaning_deposit = 0;
      this.summary.total_tax = 0;
      this.summary.invoice_total = 0;
    },
    calculateSummary() {
      this.resetSummary();
      this.auction_invoices.forEach(invoice =>{
        invoice.InvoiceLines.forEach(element => {
          if (element.Product?.slug === 'remaining_bid'){
            this.summary.remaining_bid += element.cost
          }else if (element.Product?.slug === 'cleaning_deposit'){
            this.summary.cleaning_deposit += element.cost
          } 
        });
        this.summary.total_tax += invoice.total_tax;
      });
      this.summary.invoice_total = this.auction_invoices.balance;
      this.summary.seller_premium_cost = this.SellerPremium.amount;
      this.summary.winning_bid = this.scheduled_auction.amount || 0;
    },
    updatePhoneNumber(data){
      this.scheduled_auction.Contact.phone = data.formattedNumber;
      this.scheduled_auction.Contact.country_code = data.countryCode ;
    },
    closeRemoveConfirmation() {
      this.showRemoveAuctionConfirmation = false;
    },
    closeEditConfirmation() {
      this.showEditAuctionConfirmation = false;
    },
    editField(fieldName) {
      this.fieldEditing = fieldName;
    },
    clearEditField() {
      this.fieldEditing = "";
    },
    getSubHeader() {
      if(this.stepper === 0){
        return 'View and edit Auction Images and Space Inventory prior to auction.'
      }
      else if(this.stepper === 2 && this.scheduled_auction.id && this.change_auction_info){
        return 'Change Auction Information.'
      }
      else if(this.stepper === 2 && this.scheduled_auction.id && this.scheduled_auction.auction_type === 'storage-treasures' && this.isPublished){
        return 'Auction has been scheduled and published to Storage Treasures.'
      }
      else if(this.stepper === 2 && this.scheduled_auction.id && this.scheduled_auction.auction_type === 'storage-treasures' && !this.isPublished){
        return 'Auction has been created. Please publish to Storage Treasures to schedule this Auction.'
      }
      else if(this.stepper === 2 && this.scheduled_auction.id && !this.change_auction_info){
        return 'Auction has been scheduled.'
      }
      else if(this.stepper === 2 && (!this.auctionLease.auction_status || (this.auctionLease.auction_status && this.auctionLease.auction_status !== 'complete'))){
        return 'Schedule Auction according to your Delinquency Process.';
      }
      else if(this.stepper === 3 && this.scheduled_auction.id && this.scheduled_auction.auction_type === 'storage-treasures') {
        switch(this.auctionLease.auction_status) {
          case 'scheduled':
            return 'Auction has been tentatively created. Please publish to Storage Treasures to schedule this Auction.';
            break;
          case 'auction_day':
          case 'auction_payment':
            return 'Enter payment information after Auction Sale details have been received from Storage Treasures.';
            break;
          default:
            break;
        }
      }
      else if(this.stepper === 3 && this.scheduled_auction.id && this.scheduled_auction.auction_type !== 'storage-treasures'){
        switch(this.auctionLease.auction_status) {
          case 'scheduled':
            return 'Auction has been scheduled.';
            break;
          case 'auction_day':
          case 'auction_payment':
            return 'Please enter the results from the Auction.';
            break;
          default:
            break;
        }
      }
    },
    async removeAuctionSchedule() {
      try {
        const response = await api.delete(
          this,
          api.LEASE_AUCTION + this.scheduled_auction.id
        ).then(r => {
          this.showMessageNotification({ type: 'success', description: 'Successfully Removed from Auction List' });
          EventBus.$emit('contact_updated',  this.contact_id);
          EventBus.$emit('reservation_made');
          EventBus.$emit('reload_tenant_profile_leases');

        });

        this.$emit("close");
      } catch (err) {
        this.showMessageNotification({
          type: "error",
          message: err,
        });
      }
    },
    checkPaymentsErrors() {
      //this.setCheckErrors({ value: true });
      //this function will check if the payment only with credit it will skip the checkErrors. otherwise, if not credit payment it wich check the errors normal.
      this.checkOnlyCredit();
    },
    async fetchPropertyConnections(device_id) {
       let r = await api.get(this.$app, api.PROPERTIES + this.property_id + "/connections/address?device_id=" + device_id);
       this.IPaddress = r.IPaddress;
       this.lan = r.lan;
    },
    async scheduleAuction(processPayment = false, validateAuctionDatetime = false) {
      this.process_payment = processPayment;
      this.closeEditConfirmation();
      let auctionTime = this.getAuctionTime();
      let scheduleDateTime = moment(
        `${this.scheduled_auction.date} ${auctionTime}:00`,
        "YYYY-MM-DD HH:mm:ss"
      ).format("YYYY-MM-DD HH:mm");
      if(validateAuctionDatetime) {
        let status = await this.$validator.validateAll();
        if(!status) return;
        // let currentTime = moment().format("YYYY-MM-DD HH:mm");
        if(!moment(scheduleDateTime).isValid()) {
        // if(!moment(scheduleDateTime).isValid() || !moment(scheduleDateTime).isSameOrAfter(currentTime)) {
          this.showMessageNotification({ description: 'Invalid Date Time provided' });
          return;
        }
      }
      let auction_data = {
        id: this.schedule_new_auction ? null : this.scheduled_auction.id,
        lease_id: this.auctionLease.id,
        unit_id: this.auctionLease.Unit.id,
        contact_id: this.contact_id,
        auction_type: this.scheduled_auction.auction_type,
        amount: this.schedule_new_auction ? null : this.scheduled_auction.amount,
        cleaning_deposit: this.schedule_new_auction ? null : this.scheduled_auction.auction_cleaning_deposit,
        cleaning_period: this.schedule_new_auction ? null : this.scheduled_auction.auction_cleaning_period,
        license_number: this.schedule_new_auction ? null : this.scheduled_auction.license_number,
        tax_exempt: this.schedule_new_auction ? false : this.scheduled_auction.tax_exempt,
        notes: this.scheduled_auction.notes,
        scheduled_date: scheduleDateTime,
        lease_auction_id: this.schedule_new_auction ? this.scheduled_auction.id : null,
        deleted: this.schedule_new_auction ? true : false,
      };
      if (!this.schedule_new_auction && this.scheduled_auction.BuyerPremium && this.scheduled_auction.BuyerPremium.value) {
        auction_data.BuyerPremium = {
          type: this.scheduled_auction.BuyerPremium.type,
          value: this.scheduled_auction.BuyerPremium.value,
          is_inclusive: this.scheduled_auction.BuyerPremium.is_inclusive,
        }
        if (this.scheduled_auction.BuyerPremium.id) {
          auction_data.BuyerPremium.id = this.scheduled_auction.BuyerPremium.id
        }
      }
      if (!this.schedule_new_auction && this.SellerPremium && this.SellerPremium.value) {
        auction_data.SellerPremium = {
          type: this.SellerPremium.type,
          value: this.SellerPremium.value,
          is_inclusive: this.SellerPremium.is_inclusive,
        }
        if (this.SellerPremium.id) {
          auction_data.SellerPremium.id = this.SellerPremium.id
        }
      }

      if(this.scheduled_auction.Contact && this.isAuctionDay && !this.schedule_new_auction){
        auction_data.Contact = {
          id: this.scheduled_auction.Contact.id,
          first: this.scheduled_auction.Contact.first,
          last: this.scheduled_auction.Contact.last,
          email: this.scheduled_auction.Contact.email,
          country_code: this.scheduled_auction.Contact.country_code,
          Phones: [{
            type: '',
            phone: this.scheduled_auction.Contact.phone ? this.scheduled_auction.Contact.phone : ''
          }]
        };
      }
      if(processPayment) {
        this.disable_payment = true;
        this.confirm_process_payment = false;

        let paymentInfo = await this.getTransformedPaymentObject({
            id: this.$options.name,
        });

        if(paymentInfo.errors && paymentInfo.errors.length) {
          this.showMessageNotification({ list: paymentInfo.errors });
          this.disable_payment = false;
          return;
        }
        
        let payment_info = {
          payment: (this.auction_bidding_amount > 0) ? paymentInfo.payment : null,
          paymentMethod: paymentInfo.paymentMethod,
          property_id: this.property_id,
          lease: this.auctionLease
        }
        auction_data = Object.assign(payment_info, auction_data)
        //Fetch Device IP and Port for Lan Connection
        if(paymentInfo?.paymentMethod?.device_id)
            await this.fetchPropertyConnections(paymentInfo.paymentMethod.device_id);
      }
      let response = {};
      let payloadResponse;
      let lanResponseData;
      let payment_id;

      try {
            // Create an instance of the HTTPS agent with certificate verification enabled.
            const certData = ``;
            const httpsAgent = new https.Agent({
            ca: [certData],
            });

            // Configure Axios to use the custom HTTPS agent.
            const instance = axios.create({
            httpsAgent
            });
            const endpoint = 'https://' + this.IPaddress + '?';
        
        if (!auction_data.id || this.schedule_new_auction) {
          if(auction_data.paymentMethod && auction_data.paymentMethod.device_id && this.lan == 1){
            //Get PAX Request Payload from HB Backend server
            payloadResponse = await api.post(this, api.PAYMENTS + "getLanSwipePayload",auction_data);
            payment_id = payloadResponse.payment.id;
            auction_data.payment.id = payment_id;
            // Make the GET request using the custom Axios instance.
            lanResponseData = await instance.get(endpoint + payloadResponse.payment.status_desc , {headers: {'Accept': 'text/plain',},});
            auction_data.payment.PAXResponse = lanResponseData;
            await api.post(this, api.LEASE_AUCTION, auction_data).then(r => {
                  response = {
                    data: {
                      msg: {
                        id: this.$options.name,
                        text: this.process_payment ? 'Payment Saved Successfully' : 'Auction Saved Successfully',
                      },
                      payment: {id: r.payment_id}
                    }
                  };
              });
          } else {
          await api.post(this, api.LEASE_AUCTION, auction_data).then(r => {
              response = {
                data: {
                  msg: {
                    id: this.$options.name,
                    text: this.process_payment ? 'Payment Saved Successfully' : 'Auction Saved Successfully',
                  },
                  payment: {id: r.payment_id}
                }
              };
          });
          }
        } else {
          if(auction_data.paymentMethod && auction_data.paymentMethod.device_id && this.lan == 1){
            //Get PAX Request Payload from HB Backend server
            payloadResponse = await api.post(this, api.PAYMENTS + "getLanSwipePayload",auction_data);
            payment_id = payloadResponse.payment.id;
            auction_data.payment.id = payment_id;
            // Make the GET request using the custom Axios instance.
            lanResponseData = await instance.get(endpoint + payloadResponse.payment.status_desc , {headers: {'Accept': 'text/plain',},});
            auction_data.payment.PAXResponse = lanResponseData;
            await api.put(
              this,
              api.LEASE_AUCTION + this.scheduled_auction.id,
              auction_data
            ).then(r => {
                response = {
                  data: {
                    msg: {
                      id: this.$options.name,
                      text: this.process_payment ? 'Payment Saved Successfully' : 'Auction Saved Successfully',
                    },
                    payment: {id: r.payment_id}
                  }
                };
            });
          } else {
            await api.put(
              this,
              api.LEASE_AUCTION + this.scheduled_auction.id,
              auction_data
            ).then(r => {
                response = {
                  data: {
                    msg: {
                      id: this.$options.name,
                      text: this.process_payment ? 'Payment Saved Successfully' : 'Auction Saved Successfully',
                    },
                    payment: {id: r.payment_id}
                  }
                };
            });
          }
        }
        if(auction_data.id) {
          this.clearEditField();
        }

      } catch (err) {
         response = {
           error: err,
           contact_id: auction_data.contact_id
         };
         if(auction_data.paymentMethod && auction_data.paymentMethod.device_id && this.lan == 1)
         {
            response = await api.post(this, api.PAYMENTS + payment_id + "/lanSwipeResponse", response);
         }
       } finally {
       this.enablePayment(response);
     }
    },
    confirmProcessPayment() {
      this.scheduleAuction(true);
    },
    resetForm() {
      this.selection = "";
      this.amount = "";
    },
    close() {
      this.change_auction_info = false;
      this.schedule_new_auction = false;
      this.errorClear(this.$options.name);
      this.successClear(this.$options.name);
      this.$emit("close");
    },
    getAuctionTime() {
      var hours =  this.timeValue.ampm == 'AM' ? this.timeValue.hours == 12 ? 0 : parseInt(this.timeValue.hours) : this.timeValue.hours == 12 ? 12 : parseInt(this.timeValue.hours) + 12;
      var minutes = this.timeValue.minutes;
      return `${hours}:${minutes}`;
    },
    async getAuctionCategories(){
      try {
        let r = await api.get(this, api.AUCTIONS + '/images/categories');

        if(r && r.categories && r.categories.length){

          r.categories.map(c => {
            this.categories.push({
              id: c.id,
              name: c.name
            });
          })
        }
      } catch (err){
        this.showMessageNotification({
          type: "error",
          description: err,
        });
      }
    },
    async getAuctionMedia(){
      try {
        let r = await api.get(this, api.AUCTIONS + '/leases/' + this.auctionLease.id + '/uploads');

        if(r){
          if(r.imageSet && r.imageSet[0]){
            this.auctionImageSet = r.imageSet[0];
          }
          if(r.imageSet && r.imageSet[0] && r.imageSet[0].uploads ){
            let mediaSort = r.imageSet[0].uploads;
            mediaSort.sort(function(a, b) {
              return moment(b.upload_date) > moment(a.upload_date);
            });
            this.media = mediaSort;
          }
          if(r.imageSet && r.imageSet[0] && r.imageSet[0].description ){
            this.mediaDescription = r.imageSet[0].description
          }
          if(r.imageSet && r.imageSet[0] && r.imageSet[0].categories ){

            let fetchedSelectedCategories = r.imageSet && r.imageSet[0] && r.imageSet[0].categories;
            
            fetchedSelectedCategories.map(c => {
              this.categoriesSelected.push({
                id: c.id,
                name: c.name
              });
            })

          }
          if(r.imageSet && r.imageSet[0] && r.imageSet[0].tamper_tag ){
            this.tamperTag = r.imageSet[0].tamper_tag;
          }
        }
      } catch (err){
        this.showMessageNotification({
          type: "error",
          description: err,
        });
      } finally {
        this.fetchingMedia = false;
      }
    },
    async publishStorageTreasuresAuction() {
      this.isPublishing = true;
      try {
        let auctionId = this.scheduled_auction.id ? this.scheduled_auction.id : null;
        let r = await api.post(this, api.AUCTIONS + 'lease-auction/' + auctionId + '/publish');

        if(r && r.auction_id && r.auction_url){
          EventBus.$emit('contact_updated');
          this.getTasks({ reset: true, role_ids: this.rolesIDs });
          this.scheduled_auction.vendor_auction_url = r.auction_url;
          this.scheduled_auction.vendor_auction_id = r.auction_id;
          this.stepper = 0;
          this.stepper = 2;
          this.fetchAuctionContact();
          setTimeout(() => {
            this.showMessageNotification({ type: 'success', description: 'Auction has been successfully published to Storage Treasures.' });
          }, 2000);
        }
      } catch (err){
        this.showMessageNotification({
          type: "error",
          description: err,
        });
      } finally {
        setTimeout(() => {
          this.isPublishing = false;
        }, 2000);
      }
    },
    getScheduledAuctionType(type){
      let auctionTypeMatch = this.auctionTypes.find( i => type.toLowerCase() === i.value.toLowerCase() );
      if(auctionTypeMatch) {
        return auctionTypeMatch.text;
      } else {
        return type;
      }
    },
    handleUploadedFiles(files){
      Array.from(files).forEach(file => {
        if(file.size > 10000000){
          this.showMessageNotification({ type: 'error', description: 'Failed: "' + file.name + '" is larger than the allowed maximum file size of 10 MB.' });
        } else {
            const reader = new FileReader();
            reader.onload = (e) => {
            this.uploads.unshift({
              src: e.target.result,
              file: file,
              document_type: 'image',
              caption: '',
              is_thumbnail: 0
            });
            };
            reader.readAsDataURL(file);
            this.allowMediaSave = true;
        }
      });
    },
    async saveAuctionImagesAndDetails(){
      this.savingAuctionMediaInProgess = true;
      this.allowMediaSave = false;
      let status = await this.$validator.validateAll('lockcut');
      if (!status) {
        this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
        this.savingAuctionMediaInProgess = false;
        this.allowMediaSave = true;
        return;
      }
      if(!this.categoriesSelected.length){
        this.showMessageNotification({ type: 'error', description: 'At least one Category / Keyword selection is required.' });
        this.savingAuctionMediaInProgess = false;
        this.allowMediaSave = true;
        return;
      }
      if(!this.uploads.length && !this.media.length){
        this.showMessageNotification({ type: 'error', description: 'At least one image is required.' });
        this.imageError = true;
        this.savingAuctionMediaInProgess = false;
        this.allowMediaSave = true;
        return;
      } else {
        this.imageError = false;
      }

      let reduceCategoriesToIds = [... new Set(this.categoriesSelected.map(a=>a.id))];

      if(this.uploads.length){

        let imageCount = this.uploads.length + this.media.length;

        const formData = new FormData();

        this.uploads.forEach((image, index) => {
          formData.append(`file`, image.file);
          formData.append(`document_type[${index}]`, 'image');
          formData.set(`caption[${index}]`, image.file.name);
          formData.append(`is_thumbnail[${index}]`, image.is_thumbnail);
        });

        formData.append('description', this.mediaDescription);
        formData.append('tamper_tag', this.tamperTag);
        reduceCategoriesToIds.forEach((id, index) => {
          formData.append(`category_ids[${index}]`, id);
        });

        try {
          let r = await api.post(this, api.AUCTIONS + 'leases/' + this.auctionLease.id + '/uploads', formData);

          if(r){
            EventBus.$emit('auction-image-upload', this.auctionLease.id, imageCount);
            this.showMessageNotification({ type: 'success', description: 'Auction Images & Space Inventory Details successfully saved.' });
            this.uploads = [];
            this.allowMediaSave = false;
            this.getAuctionMedia();
          }
        } catch (err){
          this.showMessageNotification({
            type: "error",
            description: err,
          });
          this.allowMediaSave = true;
        } finally {
          this.savingAuctionMediaInProgess = false;
        }
      } else {

        let data = {
          description: this.mediaDescription,
          tamper_tag: this.tamperTag,
          category_ids: reduceCategoriesToIds
        }

        try {
          let r = await api.put(this, api.AUCTIONS + 'leases/' + this.auctionLease.id + '/uploads', data);

          if(r){
            this.showMessageNotification({ type: 'success', description: 'Auction Images & Space Inventory Details successfully saved.' });
            this.allowMediaSave = false;
            this.getAuctionMedia();
          }
        } catch (err){
          this.showMessageNotification({
            type: "error",
            description: err,
          });
          this.allowMediaSave = true;
        } finally {
          this.savingAuctionMediaInProgess = false;
        }
      }
    },
    goToStep(number){
      this.stepper = number;
    },
    getAuctionMessage(lease) {
      if(lease && lease.LeaseAuction && lease.LeaseAuction.auction_type === 'storage-treasures' && !lease.LeaseAuction.vendor_auction_id) {
          return 'Space '+ lease.Unit.number + ` is scheduled for ${this.$options.filters.formatLocalShortDate(lease.LeaseAuction.scheduled_date)} and ending ${this.$options.filters.formatLocalShortDate(moment(lease.LeaseAuction.scheduled_date).add(10, 'days'))} @ ${this.$options.filters.formatTime12Hour(moment(lease.LeaseAuction.scheduled_date).add(10, 'days'))}.`;
      } else if(lease && lease.LeaseAuction && lease.LeaseAuction.auction_type === 'storage-treasures' && lease.LeaseAuction.vendor_auction_id && lease.auction_status && lease.auction_status === 'scheduled') {
          return 'Space '+ lease.Unit.number + ` is scheduled and published for ${this.$options.filters.formatLocalShortDate(lease.LeaseAuction.scheduled_date)} and ending ${this.$options.filters.formatLocalShortDate(moment(lease.LeaseAuction.scheduled_date).add(10, 'days'))} @ ${this.$options.filters.formatTime12Hour(moment(lease.LeaseAuction.scheduled_date).add(10, 'days'))}.`;
      } else {
          switch (lease.auction_status) {
              case 'schedule':
                  return 'Schedule Space '+ lease.Unit.number +' for Auction.';
                  break;
              case 'scheduled':
                  return 'Space '+ lease.Unit.number +
                      ` is scheduled for online auction ending on ${this.$options.filters.formatLocalShortDate(lease.LeaseAuction.scheduled_date)} @ ${this.$options.filters.formatTime12Hour(lease.LeaseAuction.scheduled_date)}.`;
                  break;
              case 'auction_day':
                  return `Verify if auction for Space ${lease.Unit.number} has been closed and enter the bid amount.`;
              case 'auction_payment':
                  return `Auction Winner info has been saved. Please take the auction payment.`;
              case 'move_out':
                  return `Space ${lease.Unit.number} has been sold on ${this.$options.filters.formatLocalShortDate(lease.LeaseAuction.scheduled_date)}.`;
                  break;
              default:
                  break;
          }
      }
    },
    async fetchDelinquencies(){
      this.fetchingDelinquency = true;

      try {
        let r = await api.get(this, api.CONTACTS + this.contact_id + '/delinquencies' + '?property_id=' + this.property_id);

        if(r){
      
          let allDelinquencies = r.delinquencies;

          let match = allDelinquencies.find( i => this.auctionLease.id === i.lease_id );

          if(match && match.id){

            console.log('match1');
            console.log(match);
            console.log('match2');

            try {
              let d = await api.get(this, api.AUCTIONS + 'delinquencies/' + match.id + '/audit');
              console.log('delinquency1');
              if(d){
                console.log(d);
                console.log('delinquency2');
              }
            } catch (err){
              this.showMessageNotification({
                type: "error",
                description: err,
              });
            }

            this.delinquency = [ match ];

            await this.reverseDelinquencyActions();

          }

        }

      } catch (err){
        this.showMessageNotification({
          type: "error",
          description: err,
        });
      }

    },
    reverseDelinquencyActions() {
      this.delinquency.map(d => {
        d.Actions.reverse();
      });
      this.fetchingDelinquency = false;
    },
    async fetchAuctionContact() {
      this.auctionUpdating = true;

      try {
        let r = await api.get(this, api.CONTACTS + this.contact_id);

        if(r && r.contact && r.contact.Leases){
          let match = r.contact.Leases.find( i => this.auctionLease.id === i.id );

          if(match){
            this.$emit('update:auctionLease', match);

            const { LeaseAuction } = match;

            if (LeaseAuction && Object.keys(LeaseAuction).length!== 0) {
              this.scheduled_auction = JSON.parse(JSON.stringify(LeaseAuction));
              this.SellerPremium = LeaseAuction.SellerPremium? JSON.parse(JSON.stringify(LeaseAuction.SellerPremium)): null;
              if(!this.scheduled_auction.contact_id){
                this.scheduled_auction.Contact = {
                  Phones: [{phone: "", type: ""}],
                  country_code: "",
                  email: "",
                  first: "",
                  last: ""
                };
              } else {
                if(!this.scheduled_auction.Contact.Phones.length){
                  this.scheduled_auction.Contact.Phones = [{phone: "", type: ""}];
                } else {
                  this.scheduled_auction.Contact.Phones[0].phone = this.scheduled_auction.Contact.Phones[0].phone.slice(1);
                }
              }
              let localScheduleDateTime = moment(
                this.scheduled_auction.scheduled_date
              ).format("YYYY-MM-DD HH:mm:ss");

              this.scheduled_auction.scheduled_date = localScheduleDateTime;
              this.scheduled_auction.date = moment(localScheduleDateTime).format(
                "YYYY-MM-DD"
              );
              let mommentTime = moment(localScheduleDateTime);
              let hoursOriginal = mommentTime.hours();
              let hours = hoursOriginal < 12 ? hoursOriginal : hoursOriginal == 12 ? 12 : (hoursOriginal - 12);
              hours = hours === 0 ? 12 : hours < 10 ? "0" + hours : hours;
              let minutes = mommentTime.minutes();
              minutes = minutes < 10 ? "0" + minutes : minutes;
              this.timeValue = {
                hours,
                minutes,
                ampm: hoursOriginal < 12 ? 'AM' : 'PM'
              }
              if (!this.scheduled_auction.BuyerPremium) {
                this.scheduled_auction.BuyerPremium = {
                  type: "fixed",
                  value: 0,
                  is_inclusive: false,
                  amount: 0
                }
              }
              if (!this.SellerPremium) {
                this.SellerPremium = {
                  type: "fixed",
                  value: 0,
                  is_inclusive: false,
                  amount: 0
                }
              }
              
              this.scheduled_auction.auction_cleaning_deposit = this.scheduled_auction.cleaning_deposit === null ? this.scheduled_auction.default_cleaning_deposit : this.scheduled_auction.cleaning_deposit;
              this.scheduled_auction.auction_cleaning_period = this.scheduled_auction.cleaning_period === null ? this.scheduled_auction.default_cleaning_period : this.scheduled_auction.cleaning_period;
            }
          }
        }
      } catch (err){
        this.showMessageNotification({
          type: "error",
          description: err,
        });
      } finally {
        this.auctionUpdating = false;
      }
    },
    verifyAudit(){
      // tbd
    },
    slipAudit(){
      // tbd
    }
  },
  watch: {
    getErrors(value) {
      if(value === false) {
        this.confirm_process_payment = true;
        this.setCheckErrors({ value: null });
      }
    },
    stepper(){
      this.imageError = false;
    }
  }
};
</script>
<style scoped>
  .fw-500 {
    font-weight: 500;
  }
  .payment-data{
    background: white;
    margin-bottom: 10px;
    border-radius: 1px;
  }
  .buyer-premium-label {
    height: 100%;
    display: flex;
  }
  .buyer-premium-label span:first-child{
    height: max-content;
    margin-top: 2px;
  }
  .auction-dialog-categories-list {
    width: 100%;
  }
  .auction-dialog-categories-list .auction-dialog-category {
    width: 42%;
    display: inline-block;
  }
  .auction-dialog-notification {
    word-break: normal;
  }
  .auction-dialog-temp-divider {
    border-top: none;
  }
  .auction-dialog-font-11px {
    font-size: 11px !important;
  }

.auction-manager-bab-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.auction-manager-bab {
  width: 100%;
}

.auction-manager-light-background {
  background: #F9FAFB;
}

.auction-manager-blade-wrapper {
  height: 100%;
}

.auction-manager-white-background {
  background: #FFFFFF;
}
</style>

<style>
  .auction-dialog-phone-code-fix .country-selector__country-flag {
    top: 14px !important;
  }
  .auction-dialog-phone-code-fix .country-selector__input {
    padding-top: 0 !important;
  }
</style>
