<template>
    <div class="lease-section my-0 py-4 px-6">

        <v-row justify="center" align="center" v-if="loading">
            <v-col class="text-center ma-12">
                <v-progress-circular width="2" size="32" indeterminate color="primary" ></v-progress-circular>
            </v-col>
        </v-row>
        <v-form v-else>
            <div class="hb-font-header-2-medium" v-if="contact_type == 'lead'">Lead, Reservation, Move-In</div>
            <div class="hb-font-header-2-medium" v-if="contact_type == 'lease'">Lease</div>

            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>
            <v-expansion-panels v-model="panel" multiple>
                <hb-expansion-panel right-cols="6" left-cols="6" no-top-margin>
                    <template v-slot:title>
                    Tenant Information
                        <!-- {{ contact_type == 'lead' ? 'Tenant Information' : 'Tenant' }} -->
                    </template>
                    <template v-if="!isReturningContact" v-slot:actions>
                        <HbCheckbox 
                            @click.native.stop="displayRentAsBusinessModal"
                            label="Rent as a Business"
                            v-model="isRentAsBusiness"
                        />
                    </template>
                    <template v-slot:content>

                        <hb-form
                            label="Email"
                            :required="!form.contact.Phones[0].type || !form.contact.Phones[0].code || !form.contact.Phones[0].phone"
                        >
                            <template v-if="isReturningTenant && isRentAsBusiness" v-slot:tooltipBody>
                                <div style="width: 250px">
                                    Business Email can only be edited through the Tenant Profile by authorized users.
                                </div>
                            </template>
                            <HbTextField
                                v-model="form.contact.email"
                                v-validate="{ max:100, email: true }"
                                :placeholder="isRentAsBusiness ? 'Business Email' : 'Enter Email'"
                                name="lease-email"
                                id="lease-email"
                                data-vv-scope="general"
                                data-vv-name="email"
                                :data-vv-as="isRentAsBusiness ? 'Business Email' : 'Email'"
                                autocomplete="cc-lease-email"
                                :error="errors.collect('general.email').length > 0"
                                @blur="checkEmail"
                                :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                            />
                        </hb-form>

                        <hb-form
                            v-for="(phone,i) in form.contact.Phones" :key="'phone_' + i"
                            :label="form.contact.Phones.length > 1 ? 'Phone ' + (i + 1) : 'Phone'"
                            :required="!form.contact.email && i === 0"
                            full
                        >
                            <template v-if="isReturningTenant && isRentAsBusiness" v-slot:tooltipBody>
                                <div style="width: 250px">
                                    Business Phone can only be edited through the Tenant Profile by authorized users.
                                </div>
                            </template>
                            <div class="d-flex align-top">
                            
                                <v-col :cols="form.contact.Phones.length > 1 ? 11 : 12" class="ma-0 pa-0">
                                    <v-row no-gutters>
                                        <v-col cols="12" lg="3" class="ma-0 pa-0">
                                            <HbSelect
                                                v-model="form.contact.Phones[i].type"
                                                v-validate="!form.contact.email && i === 0 ? 'required' : null"
                                                :items="phone_types"
                                                placeholder="Type"
                                                data-vv-scope="general"
                                                data-vv-name="type"
                                                data-vv-as="Phone Type"
                                                :error="errors.collect('general.type').length > 0"
                                                :clearable="false"
                                                :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                            ></HbSelect>
                                        </v-col>
                                        <v-col cols="12" lg="3" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                            <hb-autocomplete
                                                v-model="form.contact.Phones[i].code"
                                                v-validate="!form.contact.email && i === 0 ? 'required' : null"
                                                :items="countryCodesList"
                                                placeholder="Code"
                                                :id="'phone_code_' + i"
                                                :name="'phone_code_' + i"
                                                data-vv-scope="general"
                                                data-vv-name="code"
                                                data-vv-as="Phone Code"
                                                :error="errors.collect('general.phone_code').length > 0"
                                                @change="checkValidPhone(form.contact.Phones[i].code, form.contact.Phones[i].number, i)"
                                                @focusout="checkValidPhone(form.contact.Phones[i].code, form.contact.Phones[i].number, i)"
                                                :clearable="false"
                                                :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                            >
                                            <template v-slot:prepend-inner>
                                                +
                                            </template>
                                            <template v-slot:item="data">
                                                +{{data.item}}
                                            </template>
                                            </hb-autocomplete>
                                        </v-col>
                                        <v-col cols="12" lg="4" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                            <HbTextField
                                                v-model="form.contact.Phones[i].number"
                                                v-validate="'max:45'"
                                                v-mask="phoneMask(form.contact.Phones[i].code)"
                                                placeholder="Phone Number"
                                                :id="'lease-phone-' + i"
                                                :name="'lease-phone-' + i"
                                                data-vv-scope="general"
                                                data-vv-name="phone"
                                                data-vv-as="Phone Number"
                                                :autocomplete="'cc-lease-phone-' + i"
                                                :error="(errors.collect('general.phone').length > 0 || invalidPhones.indexOf(i) >= 0)"
                                                @blur="checkPhone($event.target.value, form.contact.Phones[i].code)"
                                                @change="checkValidPhone(form.contact.Phones[i].code, form.contact.Phones[i].number, i)"
                                                @focusout="checkValidPhone(form.contact.Phones[i].code, form.contact.Phones[i].number, i)"
                                                :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                            />
                                        </v-col>
                                        <v-col cols="12" lg="2" :class="{'pl-2' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                            <HbCheckbox
                                                v-model="form.contact.Phones[i].sms"
                                                label="SMS"
                                                :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>

                                <v-spacer v-if="form.contact.Phones.length > 1"></v-spacer>

                                <v-col
                                    cols="1"
                                    v-if="form.contact.Phones.length > 1"
                                    class="ma-0 pa-0"
                                    align="right"
                                >
                                    <hb-icon
                                        v-if="!isReturningTenant || !isRentAsBusiness"
                                        @click="removePhone('contact', i)"
                                        class="hand"
                                    >
                                        mdi-close
                                    </hb-icon>
                                </v-col>
                            </div>
                            <div
                                v-if="i === form.contact.Phones.length - 1"
                                class="mt-4 mb-1"
                            >
                                <hb-link v-if="!isReturningTenant || !isRentAsBusiness" @click="addPhone()">+ Add Phone Number</hb-link>
                            </div>
                        </hb-form>

                        <hb-form
                            label="Name"
                            required
                            full
                        >
                            <template v-if="isReturningTenant && isRentAsBusiness" v-slot:tooltipBody>
                                <div style="width: 250px">
                                    Business Name can only be edited through the Tenant Profile by authorized users.
                                </div>
                            </template>
                            <v-row no-gutters>
                                <v-col cols="12" sm="6" class="ma-0 pa-0">
                                <HbTextField
                                    v-model.lazy="form.contact.first"
                                    v-validate="'required|max:45'"
                                    :placeholder="isRentAsBusiness ? 'Business Name' : 'First'"
                                    id="first"
                                    name="first"
                                    data-vv-scope="general"
                                    :data-vv-as="isRentAsBusiness ? 'Business Name' : 'First Name'"
                                    :error="errors.collect('general.first').length > 0"
                                    autocomplete="cc-lease-first-name"
                                    @blur="checkName"
                                    :full="$vuetify.breakpoint.smAndUp"
                                    :disabled="isReturningTenant"
                                />
                                </v-col>
                                <v-col v-show="!isRentAsBusiness" cols="12" sm="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.smAndUp, 'mt-3' : $vuetify.breakpoint.xs}">
                                <HbTextField
                                    v-model.lazy="form.contact.last"
                                    v-validate="isRentAsBusiness ? '' : 'required|max:45'"
                                    placeholder="Last"
                                    id="last"
                                    name="last"
                                    data-vv-scope="general"
                                    data-vv-as="Last Name"
                                    :error="errors.collect('general.last').length > 0"
                                    autocomplete="cc-lease-last-name"
                                    @blur="checkName"
                                    :full="$vuetify.breakpoint.smAndUp"
                                    :disabled="isReturningTenant"
                                />
                                </v-col>
                            </v-row>
                        </hb-form>

                        <hb-form
                            v-if="contact_type == 'lead'"
                            label="Move-In Date"
                        >
                            <div style="margin-top:-23px;">
                                <HbDatePicker
                                    v-model="form.lead.move_in_date"
                                    @click:clear="form.lead.move_in_date = null"
                                    label="MM/DD/YYYY"
                                    :clearable="true"
                                    clear-icon="mdi-close-circle"
                                    :solo="false"
                                    :single-line="true"
                                    :default_today="true"
                                    data-vv-as="move_in_date"
                                />
                            </div>
                        </hb-form>

                        <hb-form
                            v-if="contact_type == 'lead'"
                            label="Length of Stay"
                        >
                            <HbSelect
                                v-model="form.lead.length_of_stay"
                                :items="length_of_stays"
                                placeholder="Select Length of Stay"
                            />
                        </hb-form>

                        <span v-if="contact_type == 'lease'">

                            <hb-form
                                v-for="(address,i) in form.contact.Addresses" :key="'contact_address' + i"
                                :label="form.contact.Addresses.length > 1 ? 'Address ' + (i + 1) : 'Address'"
                                required
                                full
                            >
                                <template v-if="isReturningTenant && isRentAsBusiness" v-slot:tooltipBody>
                                    <div style="width: 250px">
                                        Business Address can only be edited through the Tenant Profile by authorized users.
                                    </div>
                                </template>
                                <div class="d-flex align-top">

                                    <v-col :cols="form.contact.Addresses.length > 1 ? 11 : 12" class="ma-0 pa-0">
                                        <HbAutocomplete
                                            :items="countriesListMixin"
                                            v-validate="'required'"
                                            v-model="form.contact.Addresses[i].Address.country"
                                            label="Country"
                                            :id="'address_country_' + i"
                                            data-vv-scope="general"
                                            data-vv-as="country"
                                            :data-vv-name="'country' + i"
                                            :name="'country' + i"
                                            :error="errors.collect('general.country' + i).length > 0"
                                            @change="setCountry(i)"
                                            :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                            >
                                            <template v-slot:selection="data">
                                            {{data.item}}
                                            </template>
                                            <template v-slot:item="data">
                                                {{data.item}}
                                            </template>
                                        </HbAutocomplete>

                                        <HbTextField
                                            v-model="form.contact.Addresses[i].Address.address"
                                            v-validate="'required|max:45'"
                                            placeholder="Street"
                                            :id="'address' + i"
                                            :name="'address' + i"
                                            data-vv-scope="general"
                                            :data-vv-name="'address' + i"
                                            data-vv-as="Street"
                                            :error="errors.collect('general.address' + i).length > 0"
                                            :autocomplete="'cc-lease-address-' + i"
                                            :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                        />

                                        <HbTextField
                                            v-model="form.contact.Addresses[i].Address.address2"
                                            v-validate="'max:45'"
                                            placeholder="Suite / Apt"
                                            :id="'address2' + i"
                                            :name="'address2' + i"
                                            data-vv-scope="general"
                                            :data-vv-name="'address2' + i"
                                            data-vv-as="Suite / Apt"
                                            :error="errors.collect('general.address2' + i).length > 0"
                                            :autocomplete="'cc-lease-address2-' + i"
                                            :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                        />

                                        <v-row no-gutters>
                                    
                                            <v-col cols="12" lg="3" class="ma-0 pa-0">
                                                <HbTextField
                                                    v-model="form.contact.Addresses[i].Address.zip"
                                                    v-validate="'required'"
                                                    placeholder="ZIP Code"
                                                    :id="'zip' + i"
                                                    :name="'zip' + i"
                                                    data-vv-scope="general"
                                                    :data-vv-name="'zip' + i"
                                                    data-vv-as="ZIP Code"
                                                    :error="errors.collect('general.zip' + i).length > 0"
                                                    :autocomplete="'cc-lease-zip-' + i"
                                                    :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                                />
                                            </v-col>
                                            <v-col cols="12" lg="4" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                                <HbAutocomplete
                                                  :items="stateListMixin[i] || []"
                                                  v-validate="stateListMixin[i] && stateListMixin[i].length ? 'required' : ''"
                                                  v-model="form.contact.Addresses[i].Address.state"
                                                  item-text='name'
                                                  item-value='value'
                                                  label="State"
                                                  single-line
                                                  :id="'address_state_' + i"
                                                  data-vv-scope="general"
                                                  data-vv-as="state"
                                                  :data-vv-name="'state' + i"
                                                  :name="'state' + i"
                                                  :error="errors.collect('general.state' + i).length > 0"
                                                  @change="setState(i)"
                                                  :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                                  >
                                                </HbAutocomplete>
                                            </v-col>
                                            <v-col cols="12" lg="4" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                                <HbAutocomplete
                                                   :items="cityListMixin[i] || []"
                                                   v-validate="cityListMixin[i] && cityListMixin[i].length ? 'required' : ''"
                                                   v-model="form.contact.Addresses[i].Address.city"
                                                   label="City"
                                                   single-line
                                                   :id="'address_city_' + i"
                                                   data-vv-scope="general"
                                                   data-vv-as="city"
                                                   :data-vv-name="'city' + i"
                                                   :name="'city' + i"
                                                   :error="errors.collect('general.city' + i).length > 0"
                                                   :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                                   @keydown.enter="setCityList({event: $event, country: form.contact.Addresses[i].Address.country, state:form.contact.Addresses[i].Address.state, cityList: cityListMixin, index: i, toValidate: true})"
                                                   >
                                                    <template v-slot:selection="data">
                                                      {{data.item}}
                                                    </template>
                                                    <template v-slot:item="data">
                                                      {{data.item}}
                                                    </template>
                                                </HbAutocomplete>
                                            </v-col>

                                        </v-row>
                                    
                                    </v-col>

                                    <v-spacer v-if="form.contact.Addresses.length > 1"></v-spacer>

                                    <v-col
                                        cols="1"
                                        v-if="form.contact.Addresses.length > 1"
                                        class="ma-0 pa-0"
                                        align="right"
                                    >
                                        <hb-icon
                                            @click="removeLocation(i)"
                                            class="hand"
                                        >
                                            mdi-close
                                        </hb-icon>
                                    </v-col>

                                </div>

                                <div
                                    v-if="i === form.contact.Addresses.length - 1 && !isRentAsBusiness"
                                    class="mt-4 mb-1"
                                >
                                    <hb-link @click="addLocation">+ Add Address</hb-link>
                                </div>
                                
                            </hb-form>

                            <hb-form
                                v-show="isRentAsBusiness"
                                label="Taxpayer ID"
                            >
                                <template v-if="isReturningTenant &&  isRentAsBusiness" v-slot:tooltipBody>
                                    <div style="width: 250px">
                                        Taxpayer ID can only be edited through the Tenant Profile by authorized users.
                                    </div>
                                </template>
                                <template v-else v-slot:tooltipBody>
                                    <div style="width: 250px">
                                        Taxpayer ID is a 9 digits identifying number used for tax purposes in the United States and in other countries under the Common Reporting Standard.
                                    </div>
                                </template>
                                <HbTextField
                                    v-model.lazy="form.contact.taxpayer_number"
                                    v-validate="'numeric|min:9|max:9'"
                                    v-mask="'#########'"
                                    placeholder="Taxpayer ID"
                                    id="taxpayer_number"
                                    name="taxpayer_number"
                                    data-vv-scope="general"
                                    data-vv-name="taxpayer_number"
                                    data-vv-as="Taxpayer ID"
                                    :error="errors.collect('general.taxpayer_number').length > 0"
                                    autocomplete="cc-lease-taxpayer-id"
                                    :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                />
                            </hb-form>
                            <hb-form
                                v-show="isRentAsBusiness"
                                label="Business Representative Information"
                                required
                                full
                            >
                                <template v-if="isReturningTenant &&  isRentAsBusiness" v-slot:tooltipBody>
                                    <div style="width: 250px">
                                        Business Representative can only be edited through the Tenant Profile by authorized users.
                                    </div>
                                </template>
                                <HbTextField
                                    v-model.lazy="form.contact.Representative.Contact.first"
                                    v-validate="isRentAsBusiness ? 'required|max:45': 'max:45'"
                                    placeholder="First Name"
                                    :id="'representative_first'"
                                    :name="'representative_first'"
                                    data-vv-scope="general"
                                    :data-vv-name="'representative_first'"
                                    :data-vv-as="'Representative First Name'"
                                    :error="errors.collect('general.representative_first').length > 0"
                                    :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                    @change="updateRelationship(form.contact.Representative.Contact.first,'first')"
                                    @focusout="updateRelationship(form.contact.Representative.Contact.first,'first')"
                                          
                                />

                                <HbTextField
                                    v-model.lazy="form.contact.Representative.Contact.last"
                                    v-validate="isRentAsBusiness ? 'required|max:45': 'max:45'"
                                    placeholder="Last Name"
                                    :id="'representative_last'"
                                    :name="'representative_last'"
                                    data-vv-scope="general"
                                    :data-vv-name="'representative_last'"
                                    :data-vv-as="'Representative Last Name'"
                                    :error="errors.collect('general.representative_last').length > 0"
                                    :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                    @change="updateRelationship(form.contact.Representative.Contact.last,'last')"
                                    @focusout="updateRelationship(form.contact.Representative.Contact.last,'last')"
                                />

                                <HbTextField
                                    v-model="form.contact.Representative.Contact.email"
                                    v-validate="isRentAsBusiness ? { required: false, max:45, email: true } : { required: false, max:45, email: true }"
                                    placeholder="Email"
                                    :id="'representative_email'"
                                    :name="'representative_email'"
                                    data-vv-scope="general"
                                    :data-vv-name="'representative_email'"
                                    data-vv-as="Representative Email"
                                    :error="errors.collect('general.representative_email').length > 0"
                                    :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                    @change="updateRelationship(form.contact.Representative.Contact.email,'email')"
                                    @focusout="updateRelationship(form.contact.Representative.Contact.email,'email')"
                                />
                                <v-row  v-for="(phones, j) in form.contact.Representative.Contact.Phones" :key="'representative_phone' + j"  no-gutters>
                                    <v-col cols="12" lg="3" class="ma-0 pa-0">
                                        <HbSelect
                                            v-model="phones.type"
                                            v-validate="isRentAsBusiness && !form.contact.Representative.Contact.email ? 'required' : null"
                                            :items="phone_types"
                                            placeholder="Type"
                                            :name="'representative__phone_type'"
                                            data-vv-scope="general"
                                            :data-vv-name="'representative__phone_type'"
                                            data-vv-as="Representative Phone Type"
                                            :error="errors.collect('general.representative__phone_type').length > 0"
                                            :clearable="false"
                                            :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                            @change="updateRelationship(phones.type,'type')"
                                            @focusout="updateRelationship(phones.type,'type')"
                                        />
                                    </v-col>
                                    <v-col cols="12" lg="3" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                        <hb-autocomplete
                                            v-model="phones.code"
                                            v-validate="isRentAsBusiness && !form.contact.Representative.Contact.email ? 'required' : null"
                                            :items="countryCodesList"
                                            placeholder="Code"
                                            :id="'representative_phone_code'"
                                            :name="'representative_phone_code'"
                                            data-vv-scope="general"
                                            :data-vv-name="'representative_phone_code'"
                                            data-vv-as="Representative Phone Code"
                                            :error="errors.collect('general.representative_phone_code').length > 0"
                                            @change="checkValidPhoneRepresentative(phones.code, phones.number,j)"
                                            @focusout="checkValidPhoneRepresentative(phones.code, phones.number, j)"
                                            :clearable="false"
                                            :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                        >
                                            <template v-slot:prepend-inner>
                                                +
                                            </template>
                                            <template v-slot:item="data">
                                                +{{data.item}}
                                            </template>
                                        </hb-autocomplete>
                                    </v-col>
                                    <v-col cols="12" lg="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                        <HbTextField
                                            v-model="phones.number"
                                            v-validate="isRentAsBusiness ? 'required|max:45' : 'max:45'"
                                            v-mask="phoneMask(phones.code)"
                                            placeholder="Phone Number"
                                            data-vv-scope="general"
                                            :data-vv-name="'representative_phone_number' + j"
                                            data-vv-as="Representative Phone Number"
                                            :error="(errors.collect('general.representative_phone_number' + j).length > 0 || invalidPhones.indexOf('a' + j) >= 0)"
                                            @change="checkValidPhoneRepresentative(phones.code, phones.number,j)"
                                            @focusout="checkValidPhoneRepresentative(phones.code, phones.number,j)"
                                            :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                        />
                                    </v-col>
                                </v-row>
                                <HbCheckbox 
                                    v-if="!isReturningTenant"  
                                    @change="addRepresentativeInfoAsAlterContact"
                                    label="Use this information as Alternate Contact"
                                    v-model="useBusinessRepresentativeAsAlternate"
                                    :disabled="isReturningTenant && isRentAsBusiness ? true : false"
                                /> 
                            </hb-form>
                            <hb-form
                                v-if="has_access_control"
                                label="Gate Code"
                            >
                                <hb-text-field
                                    v-if="isSpaceAccessCodesVendor"
                                    v-model.lazy="Access.pin"
                                    v-validate="'required|min:4|max:4'"
                                    placeholder="Enter Gate Code"
                                    id="pin"
                                    name="pin"
                                    data-vv-scope="general"
                                    data-vv-name="pin"
                                    data-vv-as="Gate Code"
                                    :error="errors.collect('general.pin').length > 0"
                                    autocomplete="cc-lease-pin"
                                >
                                    <template v-slot:prepend>
                                        {{ pinFormattedUnitNumber }}
                                    </template>
                                </hb-text-field>
                                <HbTextField
                                    v-else-if="!Access.id"
                                    v-model.lazy="Access.pin"
                                    :v-validate="validationRule"
                                    placeholder="Enter Gate Code"
                                    id="pin"
                                    name="pin"
                                    data-vv-scope="specific_pin"
                                    data-vv-name="pin"
                                    data-vv-as="Gate Code"
                                    :error="errors.has('specific_pin.pin')"
                                    autocomplete="cc-lease-pin"
                                />
                                <div v-else>{{ Access.pin }}</div>
                            </hb-form>

                            <hb-form
                                label="Driver's License"
                                required
                                full
                            >

                                <v-row no-gutters>
                                    
                                    <v-col cols="12" lg="6" class="ma-0 pa-0">
                                        <HbTextField
                                            v-model.lazy="form.contact.driver_license"
                                            v-validate="'required|max:45'"
                                            placeholder="License Number"
                                            id="driver_license"
                                            name="driver_license"
                                            data-vv-scope="general"
                                            data-vv-name="driver_license"
                                            data-vv-as="Driver's License Number"
                                            :error="errors.collect('general.driver_license').length > 0"
                                        />
                                    </v-col>
                                    <v-col cols="12" lg="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                        <div style="margin-top: -23px;">
                                            <HbDatePicker
                                                v-model.lazy="form.contact.driver_license_exp"
                                                v-validate="'required'"
                                                dateFormat='MM-DD-YYYY'
                                                mask="##-##-####"
                                                label="Expiration Date (MM-DD-YYYY)"
                                                :clearable="true"
                                                clear-icon="mdi-close-circle"
                                                :solo="false"
                                                :single-line="true"
                                                :canWrite="true"
                                                :min="currentDate"
                                                data-vv-scope="general"
                                                data-vv-name="driver_license_expiry_date"
                                                data-vv-as="Driver's License Expiration Date"
                                                :error="errors.collect('general.driver_license_expiry_date').length > 0"
                                            />
                                        </div>
                                    </v-col>
                                    <v-col cols="12" lg="6" class="ma-0 pa-0 mt-3" :class="{'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                        <HbAutocomplete
                                            :items="countriesListMixin"
                                            v-validate="'required'"
                                            v-model="form.contact.driver_license_country"
                                            label="Country"
                                            id="driver_license_country"
                                            data-vv-scope="general"
                                            data-vv-as="driver_license_country"
                                            data-vv-name="driver_license_country"
                                            name="driver_license_country"
                                            :error="errors.collect('general.driver_license_country').length > 0"
                                            @change="setDLCountry"
                                            >
                                            <template v-slot:selection="data">
                                            {{data.item}}
                                            </template>
                                            <template v-slot:item="data">
                                                {{data.item}}
                                            </template>
                                        </HbAutocomplete>
                                    </v-col>
                                    <v-col cols="12" lg="6" class="ma-0 pa-0 mt-3" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                        <HbAutocomplete
                                          :items="stateDLListMixin"
                                          v-validate="stateDLListMixin.length ? 'required' : ''"
                                          v-model="form.contact.driver_license_state"
                                          label="State"
                                          item-text="name"
                                          item-value="value"
                                          id="driver_license_state"
                                          name="driver_license_state"
                                          data-vv-scope="general"
                                          data-vv-name="driver_license_state"
                                          data-vv-as="Driver's License State"
                                          :error="errors.collect('general.driver_license_state').length > 0"
                                        >
                                        </HbAutocomplete>
                                    </v-col>

                                </v-row>

                            </hb-form>

                            <hb-form
                                v-if="false"
                                label="Business"
                                :required="rent_as_company == 1 ? true : false"
                                description="Are you renting as a Company?"
                                full
                            >

                                <hb-radio-group
                                    v-model="rent_as_company"
                                    row
                                    hide-details
                                    :class="{ 'mb-5' : rent_as_company == 1 }"
                                >
                                    <hb-radio label="No" value="-1"></hb-radio>
                                    <hb-radio label="Yes" value="1"></hb-radio>
                                </hb-radio-group>

                                <span v-if="rent_as_company == 1">

                                    <HbTextField
                                        v-model.lazy="form.contact.Business.name"
                                        v-validate="'required|max:45'"
                                        placeholder="Company Name"
                                        id="company_name"
                                        name="company_name"
                                        data-vv-scope="general"
                                        data-vv-name="company_name"
                                        data-vv-as="Company Name"
                                        :error="errors.collect('general.company_name').length > 0"
                                    />

                                    <v-row no-gutters>
                                        <v-col cols="12" lg="3" class="ma-0 pa-0">
                                            <HbSelect
                                                v-model.lazy="form.contact.Business.phone_type"
                                                v-validate="'required'"
                                                :items="phone_types"
                                                placeholder="Type"
                                                id="business_phone_type"
                                                name="business_phone_type"
                                                data-vv-scope="general"
                                                data-vv-name="business_phone_type"
                                                data-vv-as="Business Phone Type"
                                                :error="errors.collect('general.business_phone_type').length > 0"
                                                :clearable="false"
                                            />
                                        </v-col>
                                        <v-col cols="12" lg="3" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                            <hb-autocomplete
                                                v-model="businessPhoneCode"
                                                v-validate="'required'"
                                                :items="countryCodesList"
                                                placeholder="Code"
                                                id="business_phone_code"
                                                name="business_phone_code"
                                                data-vv-scope="general"
                                                data-vv-name="business_phone_code"
                                                data-vv-as="Business Phone Code"
                                                :error="errors.collect('general.business_phone_code').length > 0"
                                                @change="checkValidPhoneBusiness(businessPhoneCode, businessPhoneNumber)"
                                                @focusout="checkValidPhoneBusiness(businessPhoneCode, businessPhoneNumber)"
                                                :clearable="false"
                                            >
                                            <template v-slot:prepend-inner>
                                                +
                                            </template>
                                            <template v-slot:item="data">
                                                +{{data.item}}
                                            </template>
                                            </hb-autocomplete>
                                        </v-col>
                                        <v-col cols="12" lg="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                            <HbTextField
                                                v-model="businessPhoneNumber"
                                                v-validate="'required|max:45'"
                                                v-mask="phoneMask(businessPhoneCode)"
                                                placeholder="Phone Number"
                                                id="business_phone_number"
                                                name="business_phone_number"
                                                data-vv-scope="general"
                                                data-vv-name="business_phone_number"
                                                data-vv-as="Business Phone Number"
                                                :error="(errors.collect('general.business_phone_number').length > 0 || invalidPhones.indexOf('b') >= 0)"
                                                @change="checkValidPhoneBusiness(businessPhoneCode, businessPhoneNumber)"
                                                @focusout="checkValidPhoneBusiness(businessPhoneCode, businessPhoneNumber)"
                                            />
                                        </v-col>
                                    </v-row>

                                    <HbAutocomplete
                                     :items="countriesListMixin"
                                     v-validate="'required'"
                                     v-model="form.contact.Business.Address.country"
                                     label="Country"
                                     single-line
                                     id="company_country"
                                     name="company_country"
                                     data-vv-scope="general"
                                     data-vv-name="company_country"
                                     data-vv-as="Alternate Contact Country"
                                     :error="errors.collect('general.company_country').length > 0"
                                     @change="setBusinessCountry()"
                                    />

                                    <HbTextField
                                        v-model="form.contact.Business.Address.address"
                                        v-validate="'required|max:45'"
                                        placeholder="Street"
                                        id="company_address"
                                        name="company_address"
                                        data-vv-scope="general"
                                        data-vv-name="company_address"
                                        data-vv-as="Business Street"
                                        :error="errors.collect('general.company_address').length > 0"
                                    />

                                    <HbTextField
                                        v-model="form.contact.Business.Address.address2"
                                        v-validate="'max:45'"
                                        placeholder="Suite / Apt"
                                        id="company_address2"
                                        name="company_address2"
                                        data-vv-scope="general"
                                        data-vv-name="company_address2"
                                        data-vv-as="Business Suite / Apt"
                                        :error="errors.collect('general.company_address2').length > 0"
                                    />

                                    <v-row no-gutters>
                                
                                        <v-col cols="12" lg="3" class="ma-0 pa-0">
                                            <HbTextField
                                                v-model="form.contact.Business.Address.zip"
                                                v-validate="'required|max:45'"
                                                placeholder="ZIP Code"
                                                id="company_zip"
                                                name="company_zip"
                                                data-vv-scope="general"
                                                data-vv-name="company_zip"
                                                data-vv-as="Business ZIP Code"
                                                :error="errors.collect('general.company_zip').length > 0"
                                            />
                                        </v-col>
                                        <v-col cols="12" lg="5" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                            <HbAutocomplete
                                                v-model="form.contact.Business.Address.state"
                                                v-validate="businessStateListMixin.length ? 'required' : ''"
                                                :items="businessStateListMixin"
                                                item-text="name"
                                                item-value="value"
                                                placeholder="State"
                                                id="company_state"
                                                name="company_state"
                                                data-vv-scope="general"
                                                data-vv-name="company_state"
                                                data-vv-as="Business State"
                                                :error="errors.collect('general.company_state').length > 0"
                                                @change="setBusinessState()"
                                            />
                                        </v-col>
                                        <v-col cols="12" lg="4" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                            <HbAutocomplete
                                                v-model="form.contact.Business.Address.city"
                                                v-validate="businessCityListMixin.length ? 'required' : ''"
                                                placeholder="City"
                                                :items="businessCityListMixin"
                                                id="company_city"
                                                name="company_city"
                                                data-vv-scope="general"
                                                data-vv-name="company_city"
                                                data-vv-as="Business City"
                                                :error="errors.collect('general.company_city').length > 0"
                                                @keydown.enter="setCityList({event: $event, country: form.contact.Business.Address.country, state: form.contact.Business.Address.state, cityList: businessCityListMixin,toValidate: true})"
                                            />
                                        </v-col>

                                    </v-row>

                                </span>
                                
                            </hb-form>

                        </span>

                    </template>
            </hb-expansion-panel>
            <!-- End: Old Additional Contact -->
            <!-- Start : New Additional contact -->
            <v-expansion-panel>

            <v-expansion-panel-header class="hb-default-font-size px-4">
                <v-col cols="8" class="pa-0 ma-0">
                    <span class="hb-larger-font-size font-weight-medium">Additional Contact(s)</span>
                </v-col>
            </v-expansion-panel-header>


        <v-expansion-panel-content class="pa-0 ma-0 designate_as_panel" elevation="0">
            <hb-form
                    label="Alternate Contact"
                    :required="secondary_contact === 1 ? true : false"
                    description="Would you like to provide an Alternate Contact?"
                    full
                            >
            <hb-radio-group v-model="secondary_contact"
                row
                :class="{ 'mb-5' : secondary_contact == 1 }"
            >
                <hb-radio label="No" value="-1"></hb-radio>
                <hb-radio label="Yes" value="1"></hb-radio>
            </hb-radio-group>
            <template  v-if="secondary_contact == 1 && setExistingContacts.length > 1">
                <template> 
                    <p class="hb-form-description-text-night-light">Choose contact from previous spaces or create new contact. Alternate contact can only be edited in the Tenant's profile.</p>
                </template>
                <br>
                <template>
                    <HbSelect
                        v-model="selectedAltContact"
                        :items="setExistingContacts"
                        placeholder="Choose contact"
                        item-text="name"
                        item-value="id"
                        @input="handleAltcontact"
                        single-line
                    />
                </template>
            </template>
                
            </hb-form>

            <div  v-if="secondary_contact == 1" class="d-flex align-top">   
                <!-- Testing the Select for exiting alt contacts  -->

            <v-expansion-panels v-if="form.contact.Relationships.length > 0" flat v-model="contactsSubpanel" multiple class="hb-expansion-panel">

                <v-expansion-panel class="ma-0 pa-0" v-for="(r, i) in form.contact.Relationships" :key="i" :readonly="edit === 'contacts' + i">
                    <v-expansion-panel-header class="hb-table-label py-0 my-0 px-4 hb-subpanel-header">
                        <v-col cols="8" class="pa-0 ma-0">
                            <span class="hb-default-font-size font-weight-medium">Contact {{i + 1}}</span>
                        </v-col>
                        <template v-slot:actions>
                            <div class="d-flex align-center">
                                <hb-link @click="removeContact(i)" class="hb-default-font-size">Cancel</hb-link>
                            </div>
                            <hb-icon v-if="edit !== 'contacts' + i">$expand</hb-icon>
                        </template>

                        <hb-modal v-model="confirmRemoveContact" v-if="confirmRemoveContact" size="medium" title="Delete Additional Contact" confirmation>
                            <template v-slot:content>
                                <div class="py-4 px-6">
                                    Are you sure you want to delete the selected additional contact?
                                    <br /><br />
                                    This action cannot be undone.
                                </div>
                            </template>
                            <template v-slot:actions>
                                <hb-btn color="destructive" @click="deleteRelationship(selectedContact)">Delete</hb-btn>
                            </template>
                        </hb-modal>
                    </v-expansion-panel-header>
                     
                    <v-expansion-panel-content class="pa-0 ma-0" elevation="0"> 
                        <v-row class="hb-table-row ma-0 pa-0 designate_as_types">
                                    <v-col cols="12" class="ma-0 pa-0">
                                        <v-row class="mx-0 pb-0 ma-0 hb-forms-content-row">
                                            <v-col cols="12" class="ma-0 pa-0"> 
                                                <div>
                                                    <template>
                                                        <hb-form label="Designated as" required>
                                                            <hb-combobox
                                                                v-model="selectedOptions[i]"
                                                                :items="designatedAsTypes"
                                                                :id="'designations_'+i"
                                                                :name="'designations_'+i"
                                                                select
                                                                item-text="name"
                                                                item-value="id"
                                                                return-object
                                                                label="Select contact type"
                                                                @input="handleOptionChange(i)"
                                                                data-vv-scope="contacts"
                                                                v-validate.disable="'required|max:45'"
                                                                data-vv-as="Designation Type"
                                                                :error="errors.collect('contacts.designations_'+i).length > 0"
                                                            >
                                                            </hb-combobox>
                                                        </hb-form>
                                                    </template>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                        </v-row>

                        <div v-if="r.id !== null" class="additional-contact-col">
                            <v-row class="hb-table-row ma-0 pa-0">
                                <v-col class="hb-forms-label" cols="4">
                                    Name*
                                </v-col>
                                <v-col class="" cols="8">
                                    {{r.Contact.first}} {{r.Contact.last}} 
                                </v-col>
                            </v-row>
                        </div>
                        <div v-else class="additional-contact-col">
                            <v-row class="hb-table-row ma-0 pa-0">

                                <v-col class="hb-forms-label" cols="4">
                                    Name*
                                </v-col>
                                <v-col cols="8" class="">
                                    <v-row class="mx-0 pb-0 ma-0 hb-forms-content-row">
                                        <v-col cols="6" class="ma-0 pa-0">
                                            <HbTextField
                                                v-model.lazy="form.contact.Relationships[i].Contact.first"
                                                v-validate="'required|max:45'"
                                                placeholder="First Name"
                                                :id="'secondarynamefirst' + i"
                                                :name="'secondarynamefirst' + i"
                                                data-vv-scope="general"
                                                :data-vv-name="'secondarynamefirst' + i"
                                                :data-vv-as="'Alternate Contact First Name'"
                                                :error="errors.collect('general.secondarynamefirst' + i).length > 0"
												:disabled="useBusinessRepresentativeAsAlternate == true && !isReturningTenant && i == 0 && isRentAsBusiness"
                                            />
                                        </v-col>

                                        <v-col cols="6" class="ma-0 pa-0">
                                            <HbTextField
                                                v-model.lazy="form.contact.Relationships[i].Contact.last"
                                                v-validate="'required|max:45'"
                                                placeholder="Last Name"
                                                :id="'secondarynamelast' + i"
                                                :name="'secondarynamelast' + i"
                                                data-vv-scope="general"
                                                :data-vv-name="'secondarynamelast' + i"
                                                :data-vv-as="'Alternate Contact Last Name'"
                                                :error="errors.collect('general.secondarynamelast' + i).length > 0"
												:disabled="useBusinessRepresentativeAsAlternate == true && !isReturningTenant && i == 0 && isRentAsBusiness"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>


                        <div v-if="r.id !== null" class="additional-contact-col">
                            <v-row class="hb-table-row ma-0 pa-0">
                                <v-col class="hb-forms-label" cols="4">
                                    Email*
                                </v-col>
                                <v-col class="" cols="8">
                                    {{r.Contact.email}}
                                </v-col>
                            </v-row>
                        </div>
                        <div v-else class="additional-contact-col">
                            <v-row class="hb-table-row ma-0 pa-0">
                                <v-col class="hb-forms-label" cols="4">
                                    Email {{isEmailRequired(i) ? '*' : ''}}
                                </v-col>
                                <v-col cols="8" class="">
                                    <v-row class="mx-0 pb-0 ma-0 hb-forms-content-row additional-contact-col-email">
                                        <v-col cols="12" class="ma-0 pa-0">
                                            <HbTextField
                                                :id="'relationship_email_' + i"
                                                :name="'relationship_email_' + i"
                                                v-model="form.contact.Relationships[i].Contact.email"
                                                v-validate="{ max:100, required: isEmailRequired(i) ? true : false , email: true }"
                                                data-vv-as="relationship email"
                                                data-vv-scope="contacts"
                                                label="Email"
                                                single-line
                                                :autofocus="edit === 'contacts_email' + i"
                                                :hide-details="!errors.collect('contacts.relationship_email_' + i).length"
                                                :error-messages="errors.collect('contacts.relationship_email_' + i)"
												:disabled="useBusinessRepresentativeAsAlternate == true && !isReturningTenant && i == 0 && isRentAsBusiness"
                                            ></HbTextField>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>


                        <div v-if="r.id !== null" class="additional-contact-col">
                            <v-row class="hb-table-row ma-0 pa-0">
                                <v-col class="hb-forms-label" cols="4">
                                    Number
                                </v-col>
                                <v-col class="" cols="8">
                                    Cell +{{r.Contact.Phones[0].code}} {{r.Contact.Phones[0].phone}} {{((r.Contact.Phones[0].sms)?'can sms':'can not sms')}} 
                                </v-col>
                            </v-row>
                        </div>
                        <div v-else class="additional-contact-col">
                            <v-row class="hb-table-row ma-0 pa-0">
                                <v-col class="hb-forms-label" cols="4">
                                    Phone 
                                </v-col>
                                <v-col cols="8" class="">

                                    <v-row class="mx-0 pb-0 ma-0 hb-forms-content-row"
                                        v-for="(address, j) in form.contact.Relationships[i].Contact.Phones" :key="'related_contact_phones' + j"
                                        no-gutters
                                    >
                                        <v-col cols="12" lg="3" class="ma-0 pa-0">
                                            <HbSelect
                                                v-model="form.contact.Relationships[i].Contact.Phones[j].type"
                                                v-validate="!form.contact.Relationships[i].Contact.email && i == 0 ? 'required' : null"
                                                :items="phone_types"
                                                placeholder="Type"
                                                :name="'alternate_phone_type' + i"
                                                data-vv-scope="general"
                                                :data-vv-name="'alternate_phone_type' + i"
                                                data-vv-as="Alternate Contact Phone Type"
                                                :error="errors.collect('general.alternate_phone_type' + i).length > 0"
                                                :clearable="false"
												:disabled="useBusinessRepresentativeAsAlternate == true && !isReturningTenant && i == 0 && isRentAsBusiness"
                                            />
                                        </v-col>
                                        <v-col cols="12" lg="3" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                            <hb-autocomplete
                                                v-model="form.contact.Relationships[i].Contact.Phones[j].code"
                                                v-validate="!form.contact.Relationships[i].Contact.email && i == 0 ? 'required' : null"
                                                :items="countryCodesList"
                                                placeholder="Code"
                                                :id="'alternate_phone_code' + i"
                                                :name="'alternate_phone_code' + i"
                                                data-vv-scope="general"
                                                :data-vv-name="'alternate_phone_code' + i"
                                                data-vv-as="Alternate Contact Phone Code"
                                                :error="errors.collect('general.alternate_phone_code' + i).length > 0"
                                                @change="checkValidPhoneAlternate(form.contact.Relationships[i].Contact.Phones[j].code, form.contact.Relationships[i].Contact.Phones[j].number, i, j)"
                                                @focusout="checkValidPhoneAlternate(form.contact.Relationships[i].Contact.Phones[j].code, form.contact.Relationships[i].Contact.Phones[j].number, i, j)"
                                                :clearable="false"
												:disabled="useBusinessRepresentativeAsAlternate == true && !isReturningTenant && i == 0 && isRentAsBusiness"
                                            >
                                                <template v-slot:prepend-inner>
                                                    +
                                                </template>
                                                <template v-slot:item="data">
                                                    +{{data.item}}
                                                </template>
                                            </hb-autocomplete>
                                        </v-col>
                                        <v-col cols="12" lg="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                            <HbTextField
                                                v-model="form.contact.Relationships[i].Contact.Phones[j].number"
                                                v-validate="'required|max:45'"
                                                v-mask="phoneMask(form.contact.Relationships[i].Contact.Phones[j].code)"
                                                placeholder="Phone Number"
                                                data-vv-scope="general"
                                                :data-vv-name="'alternate_phone_number' + i"
                                                data-vv-as="Alternate Contact Phone Number"
                                                :error="(errors.collect('general.alternate_phone_number' + i).length > 0 || invalidPhones.indexOf('a' + i + j) >= 0)"
                                                @change="checkValidPhoneAlternate(form.contact.Relationships[i].Contact.Phones[j].code, form.contact.Relationships[i].Contact.Phones[j].number, i, j)"
                                                @focusout="checkValidPhoneAlternate(form.contact.Relationships[i].Contact.Phones[j].code, form.contact.Relationships[i].Contact.Phones[j].number, i, j)"
												:disabled="useBusinessRepresentativeAsAlternate == true && !isReturningTenant && i == 0 && isRentAsBusiness"
											/>
                                        </v-col>
                                    </v-row>

                                </v-col>
                            </v-row>
                        </div>

                        <div v-if="r.id !== null" class="additional-contact-col">
                            <v-row class="hb-table-row ma-0 pa-0">
                                <v-col class="hb-forms-label" cols="4">
                                    Address
                                </v-col>
                                <v-col class="" cols="8" v-if="r.Contact.Addresses.length > 0">
                                    {{r.Contact.Addresses[0].Address.address}} {{r.Contact.Addresses[0].Address.address2}}, <br> {{r.Contact.Addresses[0].Address.city}}, {{r.Contact.Addresses[0].Address.state}} {{r.Contact.Addresses[0].Address.zip}} 
                                </v-col>
                            </v-row>
                        </div>
                        <div v-else class="additional-contact-col">
                            <v-row class="hb-table-row ma-0 pa-0">
                                    <v-col class="hb-forms-label" cols="4">
                                    Address {{isAddressRequired(i)?"*":""}}
                                </v-col>
                                <v-col cols="8">
                                    <v-row class="mx-0 pb-0 ma-0 hb-forms-content-row">
                                        <v-col cols="12" class="ma-0 pa-0">
                                            <v-row class="ma-0 pa-0">
                                                <v-col cols="12" class="hb-z-index-99999 hb-forms-max-width-498 ma-0 pa-0">
                                                    <HbAutocomplete
                                                                :items="countriesListMixin"
                                                                v-validate="{required : isAddressRequired(i)}"
                                                                v-model="r.Contact.Addresses[0].Address.country"
                                                                label="Country"
                                                                single-line
                                                                :id="'relationship_address_country' + i"
                                                                data-vv-scope="contacts"
                                                                data-vv-as="Country"
                                                                :name="'relationship_address_country_' + i"
                                                                :error="errors.collect('contacts.relationship_address_country' + i).length > 0"
                                                                @change="setRelationCountry(i)"
                                                            >
                                                                <template v-slot:selection="data">
                                                                    {{data.item}}
                                                                </template>
                                                                <template v-slot:item="data">
                                                                    {{data.item}}
                                                                </template>
                                                            </HbAutocomplete>
                                                </v-col>
                                            </v-row>
                                            <v-row class="ma-0 pa-0 mt-3">
                                                <v-col cols="12" class="ma-0 pa-0">
                                                    <HbTextField
                                                        :id="'relationship_address_address_' + i"
                                                        :name="'relationship_address_address_' + i"
                                                        v-validate="{required : isAddressRequired(i), max: 45}"
                                                        v-model="form.contact.Relationships[i].Contact.Addresses[0].Address.address"
                                                        label="Street"
                                                        single-line
                                                        data-vv-as="Street"
                                                        data-vv-scope="contacts"
                                                        :hide-details="!errors.collect('contacts.relationship_address_address_' + i).length"
                                                        :error-messages="errors.collect('contacts.relationship_address_address_' + i)"
                                                    ></HbTextField>
                                                </v-col>
                                            </v-row>
                                            <v-row class="ma-0 pa-0 mt-3">
                                                <v-col cols="12" class="ma-0 pa-0">
                                                    <HbTextField
                                                        :id="'relationship_address_address2_' + i"
                                                        :name="'relationship_address_address2_' + i"
                                                        v-model="form.contact.Relationships[i].Contact.Addresses[0].Address.address2"
                                                        v-validate="'max:45'"
                                                        label="Suite / Apt"
                                                        single-line
                                                        data-vv-as="Suite / Apt"
                                                        data-vv-scope="contacts"
                                                        :hide-details="!errors.collect('contacts.relationship_address_address2_' + i).length"
                                                        :error-messages="errors.collect('contacts.relationship_address_address2_' + i)"
                                                    ></HbTextField>
                                                </v-col>
                                            </v-row>
                                            <v-row class="ma-0 pa-0 mt-3">
                                                        <v-col cols="3" class="ma-0 pa-0 pr-3">
                                                            <HbTextField
                                                                :id="'relationship_address_zip_' + i"
                                                                :name="'relationship_address_zip_' + i"
                                                                v-validate="{required : isAddressRequired(i), max: 45}"
                                                                v-model="r.Contact.Addresses[0].Address.zip"
                                                                label="Zip"
                                                                single-line
                                                                data-vv-as="zip"
                                                                data-vv-scope="contacts"
                                                                :hide-details="!errors.collect('contacts.relationship_address_zip_' + i).length"
                                                                :error-messages="errors.collect('contacts.relationship_address_zip_' + i)"
                                                            ></HbTextField>
                                                        </v-col>
                                                        <v-col cols="4" class="ma-0 pa-0 hb-z-index-99999">
                                                            <HbAutocomplete
                                                                :items="relationStateListMixin[i]"
                                                                v-validate="{required : isAddressRequired(i)}"
                                                                v-model="r.Contact.Addresses[0].Address.state"
                                                                label="State"
                                                                item-text="name"
                                                                item-value="value"
                                                                :id="'relationship_address_state_' + i"
                                                                :name="'relationship_address_state_' + i"
                                                                single-line
                                                                data-vv-scope="contacts"
                                                                data-vv-name="state"
                                                                data-vv-as="State"
                                                                :error="errors.collect('contacts.relationship_address_state_' + i).length > 0"
                                                                @change="setRelationState(i)"
                                                            >
                                                            </HbAutocomplete>
                                                        </v-col>
                                                        <v-col cols="5" class="ma-0 pa-0 pl-3 hb-z-index-99999"> 
                                                            <HbAutocomplete
                                                                :items="relationCityListMixin[i]"
                                                                v-validate="{required : isAddressRequired(i)}"
                                                                v-model="r.Contact.Addresses[0].Address.city"
                                                                label="City"
                                                                single-line
                                                                :id="'relationship_address_city_' + i"
                                                                :name="'relationship_address_city_' + i"
                                                                data-vv-scope="contacts"
                                                                data-vv-name="city"
                                                                data-vv-as="city"
                                                                :error="errors.collect('contacts.relationship_address_city_' + i).length > 0"
                                                            >
                                                                <template v-slot:selection="data">
                                                                    {{data.item}}
                                                                </template>
                                                                <template v-slot:item="data">
                                                                    {{data.item}}
                                                                </template>
                                                            </HbAutocomplete>
                                                        </v-col>
                                                    </v-row>
                                        </v-col>
                                        <v-col cols="12" class="pa-0 ma-0 mt-5 d-flex align-center" v-if="edit === 'contacts_address' + i">
                                            <hb-btn color="primary" @click="save('contacts')" :disabled="contact.Relationships[i] && contact.Relationships[i].Contact.Addresses && contact.Relationships[i].Contact.Addresses[0] && contact.Relationships[i].Contact.Addresses[0].type === r.Contact.Addresses[0].type && contact.Relationships[i].Contact.Addresses[0].Address.address === form.contact.Relationships[i].Contact.Addresses[0].Address.address && contact.Relationships[i].Contact.Addresses[0].Address.address2 === form.contact.Relationships[i].Contact.Addresses[0].Address.address2 && contact.Relationships[i].Contact.Addresses[0].Address.zip === r.Contact.Addresses[0].Address.zip && contact.Relationships[i].Contact.Addresses[0].Address.state === r.Contact.Addresses[0].Address.state && contact.Relationships[i].Contact.Addresses[0].Address.city === r.Contact.Addresses[0].Address.city">Save</hb-btn>
                                            <hb-link class="ml-2" @click="clearAllEditing()">Cancel</hb-link>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-row class="ma-0 pa-0 hb-default-font-size" v-if="contact.Relationships.length < i + 1">
                                    <v-col cols="12" class="pa-0 ma-0 d-flex align-center justify-end">
                                    </v-col>
                                </v-row>
                            </v-row>
                        </div>

                        <v-divider class="ma-0 pa-0" style="border: 0px solid #101318"></v-divider>
                    </v-expansion-panel-content>
                    <div
                        v-if="i === form.contact.Relationships.length - 1 && secondary_contact == 1"
                        class="mt-4 mb-2 mr-3 align-right"
                    >
                        <hb-link @click="addContact(form.contact.Relationships.length)">+ Add New Contact</hb-link>
                    </div>
                </v-expansion-panel>
        </v-expansion-panels>
    </div>        
</v-expansion-panel-content>
</v-expansion-panel>
            <!-- End : New Additional contact -->

                <hb-expansion-panel v-show="accessAreasEnabled && has_access_control && !hideHoursSelection"> 
                    <template v-slot:title>
                        Access Hours
                    </template>
                    <template v-slot:content>
                        <hb-form
                            label="Select Access Hours"   
                            required
                            description="Select the Access Hours for the tenant in this area"
                        >
                            <AccessHourSelection
                                ref="access-hour"
                                v-model="selectedHours"
                                :property-id="property.id"
                                :area-id="unit.area_id"
                                :contact-id="contact.id"
                                :current-unit-number="unit.number"
                                :hide-selection.sync="hideHoursSelection"
                            />
                        </hb-form>
                    </template>
                </hb-expansion-panel>

            <!-- =================================== -->
                        <!-- Military Start -->
                <!-- =================================== -->
                        
                <hb-expansion-panel>

                    <template v-slot:title>
                        Active Duty Military
                    </template>

                    <template v-slot:content>
                    
                        <active-military
                            v-if="contact"
                            ref="military"
                            v-model="form.contact.Military" 
                            start-open
                            hide-actions
                            :onboarding="true"
                        ></active-military>

                    </template>
                </hb-expansion-panel>

                        <!-- =================================== -->
                                <!-- Military Ends -->
                        <!-- =================================== -->
                
                
            <!-- =================================== -->
                  <!-- Sales Tax Exemption Start -->
            <!-- =================================== -->
                        
            <hb-expansion-panel>

                <template v-slot:title>
                    Rent Tax on Lease
                </template>
                <template v-slot:content>

                    <div>
                        <hb-form
                            label="Rent Tax Exempt"
                            description="Lease tax exemption means you're not required to pay taxes on the lease payments for a property or asset, providing a financial break for leases."
                        >
                            <HbCheckbox 
                                class="mt-2" 
                                @change="setTaxExempt"
                                v-model="tax_exempt" 
                                label="Mark rent as tax exempt" /> 
                        </hb-form> 
                        <hb-form
                            v-if="tax_exempt"
                            label="Sales Tax Reason"
                            description="If different from the default option, describe the reasons for the change of the rent tax status. 
*256 characters max"
                        >
                        <HbTextarea
                            full
                            class="mt-2" 
                            box
                            :rows="3"
                            :maxlength="255"
                            v-model="tax_exempt_description"
                            v-validate="'required|max:255'" 
                            :id="'tax_exempt_description'"
                            :name="'tax_exempt_description'"
                            data-vv-scope="general"
                            :data-vv-name="'tax_exempt_description'"
                            data-vv-as="Rent Tax Exempt Reason" 
                            :error="errors.collect('tax_exempt_description').length > 0"
                            placeholder="Reason for status change"                  
                            @input="handleInputUpdate($event)" />
                        </hb-form>
                    </div>
                </template>
            </hb-expansion-panel>

                <!-- =================================== -->
                        <!-- Sales Tax Exemption Ends -->
                <!-- =================================== -->
                
                    <hb-expansion-panel>
                        <template v-slot:title>
                            Protected Property
                            <hb-tooltip class="ml-1">
                                <template v-slot:body>
                                    <div style="width: 250px">
                                        There are a number of items that are considered protected property, such as alcoholic beverages, certain kinds of documents, firearms, and pharmaceuticals. Tenants in Arizona and Nevada must disclose if they plan to store any of these items in their storage facility.
                                    </div>
                                </template>
                            </hb-tooltip>
                        </template>
                        <template v-slot:content>
                            <protected-property-items
                                show-form-label
                                :lease-id="lease.id"
                                :only-editable="true"
                                :hide-actions="true"
                                :show-description="false"
                                :show-success-message="false"
                                :refetch-on-save="false"    
                                :show-form-label-tooltip="false"
                            />
                        </template>
                    </hb-expansion-panel>
                        
                    <hb-expansion-panel>
                        <template v-slot:title>
                            Stored Contents
                        </template>
                        <template v-slot:content>
                            <stored-contents
                                show-form-label
                                ref="storedContent"
                                :lease-id="lease.id"
                                :only-editable="true"
                                :hide-actions="true"
                                :show-description="true"
                                :show-success-message="false"
                                :refetch-on-save="false"
                                :show-form-label-tooltip="false"
                            />
                        </template>
                    </hb-expansion-panel>

                    <hb-expansion-panel elevation="0" class="mt-5 mb-3">
                        <template v-slot:title>
                            Vehicle Information
                        </template>
                        <template v-slot:content>
                            <hb-form
                                label="Vehicles"
                                description="Are you storing a vehicle?"
                            >
                                <hb-radio-group 
                                    row 
                                    v-model="is_vehicle_ative"
                                    hide-details
                                >
                                    <hb-radio :value="0" label="No" ></hb-radio>
                                    <hb-radio :value="1" label="Yes" ></hb-radio>
                                </hb-radio-group>
                            </hb-form>
                                
                            <v-container class="ma-0 pa-0" v-if="is_vehicle_ative || unit.type == 'parking'">
                                
                                <hb-form
                                    label="Type"
                                    required
                                >
                                    <HbSelect
                                        v-model="form.vehicle.type"
                                        v-validate="'required'"
                                        :items="vehicle_types"
                                        item-text="name"
                                        item-value="id"
                                        placeholder="Select Type"
                                        id="type"
                                        name="type"
                                        data-vv-scope="vehicle"
                                        data-vv-as="Vehicle Type"
                                        :error="errors.collect('vehicle.type').length > 0"
                                    />
                                </hb-form>
                                
                                <hb-form
                                    label="Vehicle Information"
                                    full
                                >
                                    <v-row no-gutters>
                                        <v-col cols="12" lg="3" class="ma-0 pa-0">
                                            <hb-text-field
                                                v-model="form.vehicle.make"
                                                v-validate="'max:45'"
                                                placeholder="Make"
                                                id="make"
                                                name="make"
                                                data-vv-scope="vehicle"
                                                data-vv-as="Vehicle Make"
                                                :error="errors.collect('vehicle.make').length > 0"
                                            ></hb-text-field>
                                        </v-col>
                                        <v-col cols="12" lg="3" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                            <hb-text-field
                                                v-model="form.vehicle.model"
                                                v-validate="'max:45'"
                                                placeholder="Model"
                                                id="model"
                                                name="model"
                                                data-vv-scope="vehicle"
                                                data-vv-as="Vehicle Model"
                                                :error="errors.collect('vehicle.model').length > 0"
                                            ></hb-text-field>
                                        </v-col>
                                        <v-col cols="12" lg="3" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                            <hb-text-field
                                                v-model="form.vehicle.year"
                                                v-validate="'max:4|numeric'"
                                                placeholder="Year"
                                                id="year"
                                                name="year"
                                                data-vv-scope="vehicle"
                                                data-vv-as="Vehicle Year"
                                                :error="errors.collect('vehicle.year').length > 0"
                                            ></hb-text-field>
                                        </v-col>
                                        <v-col cols="12" lg="3" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                            <hb-text-field
                                                v-model="form.vehicle.color"
                                                v-validate="'max:45'"
                                                placeholder="Color"
                                                id="color"
                                                name="color"
                                                data-vv-scope="vehicle"
                                                data-vv-as="Vehicle Color"
                                                :error="errors.collect('vehicle.color').length > 0"
                                            ></hb-text-field>
                                        </v-col>
                                    </v-row>
                                </hb-form>
                                
                                <hb-form
                                    label="License Plate Information"
                                    full
                                >
                                    <v-row no-gutters>
                                        <v-col cols="12" lg="6" class="ma-0 pa-0">
                                            <hb-text-field
                                                v-model="form.vehicle.license_plate_number"
                                                v-validate="'max:45'"
                                                placeholder="License Plate Number"
                                                id="license_plate_number"
                                                name="license_plate_number"
                                                data-vv-scope="vehicle"
                                                data-vv-as="License Place Number"
                                                :error="errors.collect('vehicle.license_plate_number').length > 0"
                                            ></hb-text-field>
                                        </v-col>
                                        <v-col cols="12" lg="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">
                                            <HbAutocomplete
                                             :items="countriesListMixin"
                                             v-model="form.vehicle.license_plate_country"
                                             label="Country"
                                             single-line
                                             id="license_plate_country"
                                             name="license_plate_country"
                                             @change="setVehicleCountry()"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row no-gutters>
                                        <v-col cols="12" lg="6" class="ma-0 pa-0" :class="{'mt-5' : $vuetify.breakpoint.mdAndDown}">
                                            <HbAutocomplete
                                                  :items="vehicleStateListMixin"
                                                  v-model="form.vehicle.license_plate_state"
                                                  item-text='name'
                                                  item-value='value'
                                                  label="State"
                                                  single-line
                                                  id="license_plate_state"
                                                  name="license_plate_state"
                                                >
                                            </HbAutocomplete>
                                        </v-col>
                                    </v-row>
                                </hb-form>

                                <hb-form label="Registration">

                                    <div style="margin-top:-23px;">
                                        <HbDatePicker
                                            v-model="form.vehicle.registration_expiry_date"
                                            @click:clear="form.vehicle.registration_expiry_date = ''"
                                            label="Expiration Date"
                                            :min="currentDate"
                                            :clearable="true"
                                            clear-icon="mdi-close-circle"
                                            :solo="false"
                                            :single-line="true"
                                            id="registration_expiry_date"
                                            name="registration_expiry_date"
                                            data-vv-as="Registration Expiry Date"
                                        />
                                    </div>

                                    <!--
                                        
                                    <HbFileInput
                                        v-model="upload_registartion_file"
                                        :allow-multiple="false"
                                        placeholder="Upload Registration"
                                        id="registration_upload_id"
                                        name="registration_upload_id"
                                    />

                                    -->

                                    <v-file-input
                                        id="registration_upload_id"
                                        name="registration_upload_id"
                                        label="Upload Registration"
                                        single-line
                                        v-model="upload_registartion_file"
                                        flat
                                        hide-details
                                        prepend-inner-icon="mdi-paperclip"
                                        prepend-icon=""
                                    >
                                    </v-file-input>

                                </hb-form>
                                
                                <hb-form label="Insurance">
                                    <hb-text-field
                                        v-model="form.vehicle.insurance_provider_name"
                                        v-validate="'max:45'"
                                        placeholder="Insurance Provider Name"
                                        id="insurance_provider_name"
                                        name="insurance_provider_name"
                                        data-vv-scope="vehicle"
                                        data-vv-as="Provider Name"
                                        :error="errors.collect('vehicle.insurance_provider_name').length > 0"
                                    ></hb-text-field>

                                    <!--

                                    <HbFileInput
                                        v-model="insurance_upload_policy_file"
                                        :allow-multiple="false"
                                        placeholder="Upload Policy"
                                        id="insurance_policy_upload_id"
                                        name="insurance_policy_upload_id"
                                    />

                                    -->

                                    <v-file-input
                                        id="insurance_policy_upload_id"
                                        name="insurance_policy_upload_id"
                                        label="Upload Policy"
                                        single-line
                                        v-model="insurance_upload_policy_file"
                                        hide-details
                                        prepend-inner-icon="mdi-paperclip"
                                        prepend-icon=""
                                    >
                                    </v-file-input>

                                </hb-form>

                                <hb-form label="Vehicle Identification Number (VIN)">
                                    <hb-text-field
                                        v-model="form.vehicle.vechile_identification_number"
                                        v-validate="'max:45'"
                                        placeholder="Enter Vehicle Identification Number (VIN)"
                                        id="vechile_identification_number"
                                        name="vehicle_identification_number"
                                        data-vv-scope="vehicle"
                                        data-vv-as="Vehicle Identification Number"
                                        :error="errors.collect('vehicle.vehicle_identification_number').length > 0"
                                    ></hb-text-field>
                                </hb-form>

                                <hb-form label="Hull Identification Number (HIN)">
                                    <hb-text-field
                                        v-model="form.vehicle.hull_identification_number"
                                        v-validate="'max:45'"
                                        placeholder="Enter Hull Identification Number (HIN)"
                                        id="hull_identification_number"
                                        name="hull_identification_number"
                                        data-vv-scope="vehicle"
                                        data-vv-as="Hull Identification Number"
                                        :error="errors.collect('vehicle.hull_identification_number').length > 0"
                                    ></hb-text-field>
                                </hb-form>

                                <hb-form label="Serial Number">
                                    <hb-text-field
                                        v-model="form.vehicle.serial_number"
                                        v-validate="'max:45'"
                                        placeholder="Enter Serial Number"
                                        id="serial_number"
                                        name="serial_number"
                                        data-vv-scope="vehicle"
                                        data-vv-as="Serial Number"
                                        :error="errors.collect('vehicle.serial_number').length > 0"
                                    ></hb-text-field>
                                </hb-form>

                                <v-row class="hb-table-row ma-0 pa-0">
                                    <v-col class="hb-table-value pl-4" cols="12">
                                        <hb-link @click="show_vehicle_additional_fields = !show_vehicle_additional_fields"><span v-if="show_vehicle_additional_fields">Hide</span><span v-else>Show</span> additional fields <hb-icon class="mdi-18px" :class="show_vehicle_additional_fields ? 'mdi-rotate-90' : 'mdi-rotate-270'"  color="#00848e">mdi-play</hb-icon> </hb-link>
                                    </v-col>
                                </v-row>

                                <v-container v-if="show_vehicle_additional_fields" class="ma-0 pa-0">

                                    <hb-form label="Approximate Value">
                                        <hb-text-field
                                            v-model="form.vehicle.approximation_value"
                                            v-validate="'max:45'"
                                            placeholder="Enter Approximate Value"
                                            id="approximation_value"
                                            name="approximation_value"
                                            data-vv-scope="vehicle"
                                            data-vv-as="Approximate Value"
                                            :error="errors.collect('vehicle.approximation_value').length > 0"
                                            prepend-inner-icon="mdi-currency-usd"
                                        ></hb-text-field>
                                    </hb-form>

                                    <hb-form
                                        label="Registered Owner"
                                        full
                                    >
                                        <HbCheckbox
                                            v-model="form.vehicle.registered_owner.is_tenant"
                                            label="Registered owner is different than tenant."
                                        />

                                        <div v-if="form.vehicle.registered_owner.is_tenant" class="mt-5">

                                            <v-row no-gutters>

                                                <v-col cols="12" lg="6" class="ma-0 pa-0">
                                                    <hb-text-field
                                                        v-model="form.vehicle.registered_owner.first_name"
                                                        v-validate="'max:45'"
                                                        placeholder="First Name"
                                                        id="registered_owner_first_name"
                                                        name="registered_owner_first_name"
                                                        data-vv-scope="vehicle"
                                                        data-vv-as="Registered Owner First Name"
                                                        :error="errors.collect('vehicle.registered_owner_first_name').length > 0"
                                                    ></hb-text-field>

                                                </v-col>

                                                <v-col cols="12" lg="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">

                                                    <hb-text-field
                                                        v-model="form.vehicle.registered_owner.last_name"
                                                        v-validate="'max:45'"
                                                        placeholder="Last Name"
                                                        id="registered_owner_last_name"
                                                        name="registered_owner_last_name"
                                                        data-vv-scope="vehicle"
                                                        data-vv-as="Registered Owner Last Name"
                                                        :error="errors.collect('vehicle.registered_owner_last_name').length > 0"
                                                    ></hb-text-field>

                                                </v-col>

                                            </v-row>

                                            <hb-text-field
                                                v-model="form.vehicle.registered_owner.address"
                                                v-validate="'max:45'"
                                                placeholder="Street"
                                                id="registered_owner_address"
                                                name="registered_owner_address"
                                                data-vv-scope="vehicle"
                                                data-vv-as="Registered Owner Street"
                                                :error="errors.collect('vehicle.registered_owner_address').length > 0"
                                            ></hb-text-field>

                                            <hb-text-field
                                                v-model="form.vehicle.registered_owner.address2"
                                                v-validate="'max:45'"
                                                placeholder="Suite / Apt"
                                                id="registered_owner_address2"
                                                name="registered_owner_address2"
                                                data-vv-scope="vehicle"
                                                data-vv-as="Registered Owner Suite / Apt"
                                                :error="errors.collect('vehicle.registered_owner_address2').length > 0"
                                            ></hb-text-field>

                                            <HbAutocomplete
                                                :items="countriesListMixin"
                                                v-model="form.vehicle.registered_owner.country"
                                                label="Country"
                                                id="license_plate_country"
                                                name="license_plate_country"
                                                data-vv-scope="vehicle"
                                                single-line
                                                @change="setRegisteredVehicleCountry"
                                            >
                                            </HbAutocomplete>

                                            <v-row no-gutters>

                                                <v-col cols="12" lg="3" class="ma-0 pa-0">
                                                    <hb-text-field
                                                        v-model="form.vehicle.registered_owner.zip"
                                                        v-validate="'max:45'"
                                                        placeholder="ZIP Code"
                                                        id="registered_owner_zip"
                                                        name="registered_owner_zip"
                                                        data-vv-scope="vehicle"
                                                        data-vv-as="Registered Owner ZIP Code"
                                                        :error="errors.collect('vehicle.registered_owner_zip').length > 0"
                                                    ></hb-text-field>

                                                </v-col>

                                                <v-col cols="12" lg="3" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">

                                                    <HbAutocomplete
                                                        :items="registeredVehicleStateListMixin"
                                                        item-text='name'
                                                        item-value='value'
                                                        id="registered_owner_state"
                                                        name="registered_owner_state"
                                                        label="State"
                                                        single_line
                                                        v-model="form.vehicle.registered_owner.state"
                                                        data-vv-scope="vehicle"     
                                                        @change="setRegisteredVehicleState"
                                                    />

                                                </v-col>

                                                <v-col cols="12" lg="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">

                                                    <HbAutocomplete
                                                        :items="registeredVehicleCityListMixin"
                                                        v-model="form.vehicle.registered_owner.city"
                                                        label="City"
                                                        id="registered_owner_city"
                                                        name="registered_owner_city"
                                                        data-vv-name="registered_owner_city"
                                                        data-vv-scope="vehicle"
                                                        single-line
                                                        :error="!!errors.collect('vehicle.registered_owner_city').length"
                                                        @keydown.enter="setCityList({event: $event, country: form.vehicle.registered_owner.country, state: form.vehicle.registered_owner.state, cityList: registeredVehicleCityListMixin, toValidate: true})"
                                                        >
                                                    </HbAutocomplete>

                                                </v-col>

                                            </v-row>

                                        </div>

                                    </hb-form>

                                    <hb-form
                                        label="Legal Owner"
                                        full
                                    >
                                        <HbCheckbox
                                            v-model="form.vehicle.legal_owner.is_tenant"
                                            label="Legal owner is different than tenant."
                                        />

                                        <div v-if="form.vehicle.legal_owner.is_tenant" class="mt-5">
                                        
                                            <v-row no-gutters>

                                                <v-col cols="12" lg="6" class="ma-0 pa-0">
                                                    <hb-text-field
                                                        v-model="form.vehicle.legal_owner.first_name"
                                                        v-validate="'max:45'"
                                                        placeholder="First Name"
                                                        id="legal_owner_first_name"
                                                        name="legal_owner_owner_first_name"
                                                        data-vv-scope="vehicle"
                                                        data-vv-as="Legal Owner First Name"
                                                        :error="errors.collect('vehicle.legal_owner_owner_first_name').length > 0"
                                                    ></hb-text-field>

                                                </v-col>

                                                <v-col cols="12" lg="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">

                                                    <hb-text-field
                                                        v-model="form.vehicle.legal_owner.last_name"
                                                        v-validate="'max:45'"
                                                        placeholder="Last Name"
                                                        id="legal_owner_last_name"
                                                        name="legal_owner_last_name"
                                                        data-vv-scope="vehicle"
                                                        data-vv-as="Legal Owner Last Name"
                                                        :error="errors.collect('vehicle.legal_owner_last_name').length > 0"
                                                    ></hb-text-field>

                                                </v-col>

                                            </v-row>

                                            <hb-text-field
                                                v-model="form.vehicle.legal_owner.address"
                                                v-validate="'max:45'"
                                                placeholder="Street"
                                                id="legal_owner_address"
                                                name="legal_owner_address"
                                                data-vv-scope="vehicle"
                                                data-vv-as="Legal Owner Street"
                                                :error="errors.collect('vehicle.legal_owner_address').length > 0"
                                            ></hb-text-field>

                                            <hb-text-field
                                                v-model="form.vehicle.legal_owner.address2"
                                                v-validate="'max:45'"
                                                placeholder="Suite / Apt"
                                                id="legal_owner_address2"
                                                name="legal_owner_address2"
                                                data-vv-scope="vehicle"
                                                data-vv-as="Legal Owner Suite / Apt"
                                                :error="errors.collect('vehicle.legal_owner_address2').length > 0"
                                            ></hb-text-field>

                                            <HbAutocomplete
                                                :items="countriesListMixin"
                                                v-model="form.vehicle.legal_owner.country"
                                                label="Country"
                                                id="legal_owner_country"
                                                name="legal_owner_country"
                                                data-vv-scope="vehicle"
                                                single-line
                                                @change="setLegalVehicleCountry"
                                            >
                                            </HbAutocomplete>

                                            <v-row no-gutters>

                                                <v-col cols="12" lg="3" class="ma-0 pa-0">
                                                    <hb-text-field
                                                        v-model="form.vehicle.legal_owner.zip"
                                                        v-validate="'max:45'"
                                                        placeholder="ZIP Code"
                                                        id="legal_owner_zip"
                                                        name="legal_owner_zip"
                                                        data-vv-scope="vehicle"
                                                        data-vv-as="Legal Owner ZIP Code"
                                                        :error="errors.collect('vehicle.legal_owner_zip').length > 0"
                                                    ></hb-text-field>

                                                </v-col>

                                                <v-col cols="12" lg="3" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">

                                                    <HbAutocomplete
                                                        :items="legalVehicleStateListMixin"
                                                        item-text='name'
                                                        item-value='value'
                                                        id="legal_owner_state"
                                                        name="legal_owner_state"
                                                        label="State"
                                                        single_line
                                                        v-model="form.vehicle.legal_owner.state"
                                                        data-vv-scope="vehicle"     
                                                        @change="setLegalVehicleState"                          
                                                    />

                                                </v-col>

                                                <v-col cols="12" lg="6" class="ma-0 pa-0" :class="{'pl-3' : $vuetify.breakpoint.lgAndUp, 'mt-3' : $vuetify.breakpoint.mdAndDown}">

                                                    <HbAutocomplete
                                                        :items="legalVehicleCityListMixin"
                                                        v-model="form.vehicle.legal_owner.city"
                                                        label="City"
                                                        id="legal_owner_city"
                                                        name="legal_owner_city"
                                                        data-vv-name="legal_owner_city"
                                                        single-line
                                                        data-vv-scope="vehicle"
                                                        :error="!!errors.collect('vehicle.legal_owner_city').length"
                                                        @keydown.enter="setCityList({event: $event, country: form.vehicle.legal_owner.country, state: form.vehicle.legal_owner.state, cityList: legalVehicleCityListMixin, toValidate: true})"
                                                        >
                                                    </HbAutocomplete> 

                                                </v-col>

                                            </v-row>

                                        </div>

                                    </hb-form>

                                </v-container>

                            </v-container>
                        </template>
                    </hb-expansion-panel>
                    <!-- I don't think we use the "Marketing Information" section below anymore -->

                <v-expansion-panel elevation="0" v-if="contact_type == 'lead'">
                    <v-expansion-panel-header :disable-icon-rotate="!!countSectionError('general')" class="hb-default-font-size py-4 px-4">
                        <v-col cols="12" class="pa-0 ma-0">
                            <span class="hb-larger-font-size font-weight-medium">Marketing Information</span>
                        </v-col>
                        <template v-slot:actions v-if="countSectionError('lead')">
                        <v-chip pill small outlined color="error">
                            <hb-icon small color="error">mdi-alert-circle</hb-icon>
                            <strong>{{ countSectionError('lead') }} Error<span v-show="countSectionError('lead') > 1">s</span></strong></v-chip>
                            <hb-icon class="ml-2">$expand</hb-icon>
                        </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="pa-0 ma-0" elevation="0">
                            <v-container class="ma-0 pa-0">
                            <v-row class="hb-table-row ma-0 pa-0" v-if="contact_type =='lead'">
                                    <v-col class="hb-forms-label" cols="3">
                                        Contact Type
                                    </v-col>
                                    <v-col class="hb-forms-content-onboarding hb-forms-max-width-498" cols="9">
                                        <HbSelect
                                            class="ma-0 pa-0"
                                            :items="methods"
                                            v-model="form.lead.method"
                                            name="method"
                                            single-line
                                            label="Select"
                                            background-color="white"
                                            :hide-details="!errors.collect('lead.method').length"
                                            :error-messages="errors.collect('lead.method')"
                                            v-validate="contact_type == 'lead' ? 'required' : ''"
                                            data-vv-scope="lead"
                                            data-vv-as="type"
                                        ></HbSelect>
                                    </v-col>
                            </v-row>

                            <v-row class="hb-table-row ma-0 pa-0">

                                    <v-col class="hb-forms-label" cols="3">
                                        Source
                                    </v-col>
                                    <v-col class="hb-forms-content-onboarding hb-forms-max-width-498" cols="9">
                                        <HbSelect
                                        class="ma-0 pa-0"
                                        :items="sources"
                                        v-model="form.lead.source"
                                        name="source"
                                        single-line
                                        label="Select"
                                        background-color="white"
                                        :hide-details="!errors.collect('lead.source').length"
                                        :error-messages="errors.collect('lead.source')"
                                        data-vv-scope="lead"
                                        v-validate="contact_type == 'lead' ? 'required' : ''"
                                        ></HbSelect>
                                    </v-col>
                            </v-row>
                            <v-row class="hb-table-row ma-0 pa-0" v-if="properties.length > 1">
                                <v-col class="hb-forms-label" cols="3">
                                    Property Interest
                                </v-col>

                                <v-col class="hb-forms-content-onboarding hb-forms-max-width-498" cols="9">
                                        <HbSelect
                                            class="ma-0 pa-0"
                                            :items="properties"
                                            item-text="name"
                                            item-value="id"
                                            name="lead_property"
                                            v-model="form.lead.property_id"
                                            single-line
                                            label="Select"
                                            background-color="white"
                                            v-validate="contact_type == 'lead' ? 'required' : ''"
                                            data-vv-scope="lead"
                                            data-vv-as="facility interest"
                                            :hide-details="!errors.collect('lead.lead_property').length"
                                            :error-messages="errors.collect('lead.lead_property')"
                                    ></HbSelect>
                                </v-col>
                            </v-row>
                            <v-row class="hb-table-row ma-0 pa-0">
                                <v-col class="hb-forms-label" cols="3">
                                    Category Interest
                                </v-col>

                                <v-col class="hb-forms-content-onboarding hb-forms-max-width-498" cols="9">
                                        <HbSelect
                                            class="ma-0 pa-0"
                                            :items="categories"
                                            item-text="name"
                                            item-value="id"
                                            v-model="form.lead.category_id"
                                            hide-details
                                            single-line
                                            label="Select"
                                            background-color="white"
                                            data-vv-scope="lead"
                                    ></HbSelect>
                                </v-col>
                            </v-row>
                            <v-row class="hb-table-row ma-0 pa-0">
                                <v-col class="hb-forms-label" cols="3">
                                    Notes
                                </v-col>

                                <v-col class="hb-forms-content-onboarding" cols="9">
                                    <v-textarea
                                        class="ma-0 pa-0"
                                        name="lead_content"
                                        label="Enter Notes"
                                        data-vv-scope="lead"
                                        rows="1"
                                        v-model="form.lead.content"
                                        v-validate="'max:1000'"
                                        single-line
                                        hide-details="auto"
                                        auto-grow
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel readonly class="new-tenant-form-data elevation-0" :class="{'no-border-radius-bottom': !todo.add}" v-if="contact_type == 'lead'">
                    <v-expansion-panel-header class="mt-0" style="padding:0 0 12px 18px;">
                        <template v-slot:actions v-if="countSectionError('todo')">
                            <div class="pt-2 pr-7">
                                <v-chip pill small outlined color="error">
                                <hb-icon small color="error">mdi-alert-circle</hb-icon>
                                <strong>{{ countSectionError('todo') }} Error<span v-show="countSectionError('todo') > 1">s</span></strong></v-chip>
                            </div>
                        </template>
                        <template v-else v-slot:actions>
                            <hb-icon></hb-icon>
                        </template>
                        <v-checkbox dense class="pl-1 py-0 mt-3 hb-text-night" :class="{'py-2': todo.add}" hide-details v-model="todo.add">
                            <template v-slot:label>
                                <span class="hb-text-night" style="font-size: 0.9375rem;">
                                Schedule a Reminder to Follow Up
                                </span>
                            </template>
                        </v-checkbox>

                    </v-expansion-panel-header>
                    <v-divider v-show="todo.add" class="ma-0 pa-0"></v-divider>
                    <v-expand-transition>
                        <v-card class="mt-5" style="padding: 0 24px 16px;" flat tile v-show="todo.add">
                            <v-container class="hb-table">
                                <v-row class="hb-table-row ma-0 pa-0">
                                    <v-col cols="12" sm="6" class="py-0 my-0" :class="{'border-bottom': !$vuetify.breakpoint.smAndUp}">
                                        <v-row>
                                            <v-col class="hb-table-label pl-4 py-4" cols="3" sm="6">
                                                Remind Me On
                                            </v-col>
                                            <v-col class="hb-table-value py-2 px-0" cols="9" sm="6">
                                                <hb-date-picker
                                                    @click:clear="todo.date = ''"
                                                    label="MM/DD/YYYY"
                                                    :clearable="true"
                                                    :dense="true"
                                                    :min="currentDate"
                                                    name="remind"
                                                    v-model="todo.date"
                                                    v-validate="todo.add ? 'required' : ''"
                                                    data-vv-scope="todo"
                                                    :hide-details="!errors.collect('todo.remind').length"
                                                    :error-messages="errors.collect('todo.remind')"
                                                ></hb-date-picker>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                    <v-col cols="12" sm="6" class="py-0 my-0">
                                        <v-row>
                                            <v-col class="hb-table-label py-4 pl-4" cols="3" sm="6">
                                                Time
                                            </v-col>
                                            <v-col class="hb-table-value py-2 pl-1 pr-0" cols="9" sm="6">
                                                <HbSelect
                                                class="hb-table-lined-input"
                                                :items="times"
                                                v-model="todo.time"
                                                hide-details
                                                dense
                                                flat
                                                solo
                                                label="Time"
                                                background-color="white"
                                                ></HbSelect>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <v-row v-if="todo.add" class="hb-table-row ma-0 pa-0">
                                    <v-col class="hb-table-label py-4 pl-4" cols="3">
                                        Notes
                                    </v-col>

                                    <v-col cols="9" class="pl-4 py-0">
                                        <v-textarea
                                            name="notes"
                                            label="Enter Notes"
                                            rows="1"
                                            v-model="todo.notes"
                                            single-line
                                            solo
                                            flat
                                            auto-grow
                                            v-validate="todo.add ? 'required' : ''"
                                            data-vv-scope="todo"
                                            :hide-details="!errors.collect('todo.notes').length"
                                            :error-messages="errors.collect('todo.notes')"
                                            class="pt-3 pb-6"
                                        ></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-expand-transition>
                </v-expansion-panel>

            </v-expansion-panels>


            <!-- <div class="new-tenant-walkthrough-footer">

                <hb-bottom-action-bar
                    @close="$emit('close')"
                    :top-border="false"
                >
                    <template v-slot:left-actions>
                        <help-juice />
                    </template>
                    <template v-slot:right-actions>
                      <hb-btn
                                color="secondary"
                                :disabled="isLoading($options.name) || savingInProgress"
                                @click="$emit('retire_lead')"
                              >
                                Retire Lead
                      </hb-btn>

                        <hb-btn
                            v-if="contact_type == 'lease'"
                            :disabled="isLoading($options.name) || savingInProgress"
                            :loading="savingInProgress"
                            @click="saveTenant(false)"
                        >
                            Next
                        </hb-btn>
                    </template>
                </hb-bottom-action-bar>

            </div> -->
        </v-form>

        <send-information
            v-model="showRental"
            v-if="showRental"
            slot="body"
            :mobileNumber="contact.phone_to_send_sms.phone"
            :email="contact.email"
            @sendText="sendRentalText"
            @sendEmail="sendRentalEmail"
        >
        </send-information>

        <rent-as-business
            v-model="showRentAsBusinessModal" 
            v-if="showRentAsBusinessModal"
            :currentContactType="current_contact_type"
            :newContactType="new_contact_type"
            @close="closeRentAsBusinessModal()"
            @ok="closeRentAsBusinessModal(true)"
        >
        </rent-as-business>

        <ConfirmAccessHours
            v-model="confirmHoursVisible"
            :area-name="unit.area_name"
            :hours-name="selectedHours?.name"
            :units-in-area="formattedUnitsInArea"
            @confirm-change="confirmHours"
        />

    </div>

</template>
<script type="text/babel">
    import api from '../../../assets/api.js';
    import Status from './../Messages.vue';
    import HbDatePicker from '../../assets/HummingbirdDatepicker';
    import { EventBus } from "../../../EventBus.js";

    import { mapGetters, mapActions, mapMutations } from 'vuex';
    import VuetifyStatepicker from './../VuetifyStatepicker.vue';
    import moment from 'moment';
    import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    import SendInformation from './SendInformationWidget.vue';
    import RentAsBusiness from './Modal/RentAsBusinessModal.vue';
    import ActiveMilitary from '../../contacts/ActiveMilitary.vue';
    import AccessHourSelection from '../../access_hour_selection/AccessHourSelection.vue';
    import ConfirmAccessHours from '../../access_hour_selection/ConfirmAccessHours.vue';
    import { parsePhoneNumber } from 'libphonenumber-js';
    import ProtectedPropertyItems from '../../leases/ProtectedPropertyItems.vue';
    import StoredContents from '../../leases/StoredContents.vue';
    // import SensitiveInformation from '../../leases/SensitiveInformation.vue';
    import VEHICLE from '@/constants/vehicle.js';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';
    import { Validator } from 'vee-validate';
    import { validateEmailPhoneMixin } from '../../../mixins/validateEmailPhoneMixin'
    import { countryStateCityMixin } from '../../../mixins/countryStateCityMixin';
    import ACTIVE_MILITARY from '@/constants/active_military.js'; 
    import PHONE from '@/constants/phone.js';

    Validator.extend('leadindZeros', {
        getMessage: field => "Leading 0's are not allowed for " + field + " field.",
        validate: (value) => {
            let regex = /^[1-9]\d*$/;
            return regex.test(value)
        }
    });

    export default {
        name: 'LeaseData',
        mixins: [notificationMixin, validateEmailPhoneMixin, countryStateCityMixin],
        components: {
            HbDatePicker,
            VuetifyStatepicker,
            Status,
            SendInformation,
            ActiveMilitary,
            AccessHourSelection,
            ConfirmAccessHours,
            ProtectedPropertyItems,
            StoredContents,
            RentAsBusiness
            // SensitiveInformation
        },
        props: ['selected', 'contact_type', 'lease_id', 'context', 'loading','refreshData'],
        data: () => ({
            hideHoursSelection: false,
            confirmHoursVisible: false,
            location_types: ['Home', 'Work', 'Other'],
            selectedHours: {},
            accessAreasEnabled: false,
            edit: '',
            confirmRemoveContact: false,
            contactsSubpanel: [],
            leaseOptions: [],
            selectedLease: [],
            active: '',
            selectedOptions: [],
            designatedAsTypes:[
                {
                    name: "Alternate",
                    id: "is_alternate",
                    disabled: false,
                },
                {
                    name: "Emergency",
                    id: "is_emergency",
                    disabled: false,
                },
                {
                    name: "Authorized Access",
                    id: "is_authorized",
                    disabled: false,
                },
                {
                    name: "Lien Holder",
                    id: "is_lien_holder",
                    disabled: false,
                }
            ],
            prevContacts: [],
            selectedAltContact:0,
            existingAltContacts:[
                {
                    id: "1",
                    name:"New Contact",
                    disabled: false
                },
            ],
            is_existing_contact: false, 
            previousContactId: null,
            branch: ACTIVE_MILITARY.BRANCHES,
            rank: ACTIVE_MILITARY.RANKS,
            formatted_phone: '',
            military_formatted_phone: '',
            length_of_stays: ['1-3 Months', '3-6 Months', '6-12 Months', '1+ Year'],
            currentDate: '',
            times: [],
            panel: [0,1,2,3,4,5,6],
            valid: false,
            Access:{
                id: '',
                pin: '',
                property_id: ''
            },
            default_pin_format: '8',
            default_pin_min: '3',
            default_pin_max: '12',
            tax_exempt: false,
            tax_exempt_description: null,
            form: {
                contact: {},
                lead: {},
                vehicle: {
                    active: 0
                }
            },
            defaultCountry: 'United States',
            is_vehicle_ative: 0,
            move_in_date: null,
            length_of_stay: null,
            sources:[
                "Online",
                "Walk In",
                "Referral"
            ],
            methods:[
                "Phone Call",
                "Email",
                "Website",
                "Walk In",
            ],
            found:[],
            email_types: ['Primary', 'Work', 'Other'],
            phone_types: PHONE.TYPES,
            vehicle_types: VEHICLE.TYPES,
            show_vehicle_additional_fields: false,
            rent_as_company: '-1',
            secondary_contact: '-1',
            military: '-1',
            interaction:{
                activity_object_id:'',
                content: '',
                date: null,
                hh: '',
                mm: '',
                ampm: 'AM',
            },
            todo: {
                add: false,
                date: null,
                time: '',
                hh: '05',
                mm: '00',
                ampm: 'PM',
                details: ''
            },
            remindTime: false,
            tenant:{
                id: null,
                contact_id: '',
                lease_id: '',
                created_at: '',
                type: '',
                sort: ''
            },
            has_access_control: true,
            showRental: false,
            upload_registartion_file: null,
            insurance_upload_policy_file: null,
            states: [
                'AA','AE','AP','AK', 'AL', 'AR', 'AS', 'AZ', 'CA', 'CO', 'CT', 'DC', 'DE', 'FL', 'GA', 'GU', 'HI', 'IA', 'ID', 'IL', 'IN', 'KS', 'KY', 'LA', 'MA', 'MD', 'ME', 'MI', 'MN', 'MO', 'MP', 'MS', 'MT', 'NC', 'ND', 'NE', 'NH', 'NJ', 'NM', 'NV', 'NY', 'OH', 'OK', 'OR', 'PA', 'PR', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VA', 'VI', 'VT', 'WA', 'WI', 'WV', 'WY'
            ],
            countryCodesList: PHONE.COUNTRY_CODES,
            invalidPhones: [],
            militaryPhoneCode: '1',
            militaryPhoneNumber: '',
            businessPhoneCode: '1',
            businessPhoneNumber: '',
            alternatePhoneCode: '1',
            alternatePhoneNumber: '',
            savingInProgress: false,
            isSpaceAccessCodesVendor: false,
            isRentAsBusiness: false,
            showRentAsBusinessModal: false,
            useBusinessRepresentativeAsAlternate: false,
            keysToCheckFormIsFilled: { 
                contact : ['first', 'last', 'email', 'number'],
                driver_license: ['driver_license', 'driver_license_exp', 'driver_license_state'],
                address : ['state', 'zip', 'address', 'country']
            },
            isPtiVendor: false
        }),
        async created(){
            await api.get(this, api.PROPERTIES + this.property.id + "/property-area-management").then(results => {
                this.accessAreasEnabled = results?.property_area_configuration.access_hours_upgrade === 1;
            }).catch(error => {
                this.accessAreasEnabled = false;
            });
           await this.initializeComponentData();
        },
        mounted(){

        },
        destroyed(){
            this.resetMissingCityList()
        },
        computed:{
            contact: {
                get() { return this.$store.getters['onBoardingStore/contact'] } ,
                set(val){  return this.$store.commit('onBoardingStore/setContact', val ) }
            },
            lead: {
                get() { return this.$store.getters['onBoardingStore/lead'] } ,
                set(val){  return this.$store.commit('onBoardingStore/setLead', val ) }
            },
            validationRule(){
                if(this.isPtiVendor){
                    return 'required|numeric|leadindZeros|min:' + this.Access.pin_min + '|max:' + this.Access.pin_max
                }else{
                    return 'required|numeric|min:' + this.Access.pin_min + '|max:' + this.Access.pin_max;
                }
                
            },
            ...mapGetters({
                property: 'onBoardingStore/property',
                properties: 'propertiesStore/filtered',
                categories: 'unit_category_id',
                lease: 'onBoardingStore/lease',
                tenants: 'onBoardingStore/tenants',
                unit: 'onBoardingStore/unit',
                configuration_lease:  'onBoardingStore/configuration_lease',
                latestContactData:"onBoardingStore/latestContactData",
                unitsInArea: "onBoardingStore/units_in_area",
                getTaxExempt: "onBoardingStore/getTaxExempt",
                getTaxExemptDescription: "onBoardingStore/getTaxExemptDescription"
            }),
            pinFormattedUnitNumber(){
                if(this.unit.number.length < 5){
                    var numString = this.unit.number.toString();
                    var leadingZeros = "0000";
                    return leadingZeros.substring(0, leadingZeros.length - numString.length) + numString;
                } else {
                    return '';
                }
            },
            //This function prepare a computed array for the existing alternative contacts
             setExistingContacts(){
                const matchingLeases = this.existingAltContacts
                if(this.contact?.Relationships?.length){
                    this.contact?.Relationships.forEach((relationship) => {
                    if ((relationship.is_alternate === 1 || relationship.is_alternate === true) && relationship.lease_id != this.lease.id) {
                        let matchedLeases = this.contact.Relationships.map((relationships) => {
                            let leaseMatch = '';
                            if(this.contact?.Leases && relationships.related_contact_id == relationship.related_contact_id && relationship.lease_id != this.lease.id){
                                leaseMatch = this.contact?.Leases.find((lease) => lease.id === relationships.lease_id)
                                return leaseMatch ? leaseMatch.Unit.number : null;
                            }
                            if(!leaseMatch && relationships.related_contact_id == relationship.related_contact_id && relationship.lease_id != this.lease.id){
                                leaseMatch = this.Access?.Spaces.find((lease) => lease.id === relationships.lease_id)
                                return leaseMatch ? leaseMatch.name : null;
                            }
                        })
                        .filter((unitNumber) => unitNumber !== null && unitNumber !== undefined);

                        if(!matchedLeases || (matchedLeases &&  matchedLeases?.length == 0) ) return;
                        // let spacesList = (matchedLeases?.length>0?`(Spaces: ${matchedLeases})`:`(Associated with tenant)`)
                        let spacesList = `(Spaces: ${matchedLeases})`;

                        matchingLeases.push({
                        id: relationship.Contact.id,
                        name: `${relationship.Contact.first} ${relationship.Contact.last} ${spacesList}`,
                        unitNumbers: matchedLeases,
                        }); 

                    }
                    });
                }
                return matchingLeases
            },
            new_contact_type(){
              return this.isRentAsBusiness ? 'Business' : 'Individual';
            },
            current_contact_type(){
              return this.new_contact_type === 'Business' ? 'Individual': 'Business';
            },
            isReturningTenant(){
              return this.contact?.Leases?.length > 0;
            },
            isReturningContact(){
              return this.contact?.is_returning_contact;
            },


            isEmailRequired() {
                return (i) => {
                    let designationsData = this.selectedOptions[i];
                    if(designationsData?.length){
                        return designationsData.some(option => option.id === "is_authorized");
                    }
                };
            },

            isAddressRequired() {
                return (i) => {
                    let designationsData = this.selectedOptions[i];
                    // if(designationsData?.length){
                    //     return designationsData.some(option => (option.id === "is_authorized" || option.id === 'is_emergency'));
                    // }
                    return false;
                };
            },
            formattedUnitsInArea(){
                return this.unitsInArea.join(", ");
            }
        },
        methods:{
            ...mapMutations({
            setLeaseProperty: 'onBoardingStore/setLeaseProperty',
            setTaxExemptStore: 'onBoardingStore/setTaxExempt',
            setTaxExemptDescriptionStore: 'onBoardingStore/setTaxExemptDescription',
            }),
            ...mapActions({
            updateLeaseTaxExemptHistory: 'onBoardingStore/updateLeaseTaxExemptHistory'
            }),
            handleInputUpdate : _.debounce(async function(taxExemptDescription) {
                this.setTaxExemptDescriptionStore(taxExemptDescription)
            }, 200),
            confirmHours(){
                this.$emit("hours-confirmed");
            },
            setCountry(index){
                this.form.contact.Addresses[index].Address.state = ''
                this.form.contact.Addresses[index].Address.city = ''
                this.setStatesListEvent(this.form.contact.Addresses[index].Address.country, index)
                this.setCountryCitiesListEvent(this.form.contact.Addresses[index].Address.country, index)
            },
            setState(index){
                this.form.contact.Addresses[index].Address.city = ''
                this.setCitiesListEvent(this.form.contact.Addresses[index].Address.country, this.form.contact.Addresses[index].Address.state, index)
            },
            setCityList(payload){
                const {event, country, state, cityList} = payload
                const toValidate =  payload?.toValidate || false
                const index = payload?.index >= 0 ? payload?.index : -1
                const {value, dataset} = event.target
                if(!value) return
                if(toValidate) {
                    const name= `${dataset.vvScope}.${dataset.vvName}`
                    const isSpecialCharacter = this.validateCityName(value)
                    const cityValidation = this.errors.items.indexOf(name)

                    this.errors.items.splice(cityValidation);
                    if(isSpecialCharacter){
                        return this.errors.add({
                                    field: name,
                                    msg: 'Please add a valid city name.'
                                }) 
                    }
                }

                const cityName = value.toString()
                const cityData = (index >= 0) ? cityList[index] : cityList;
                const isCityExists = cityData.some((city) => city.toLowerCase() === cityName.toLowerCase())
                if(isCityExists) return

                if(index >= 0){
                    this.$set(cityList, index, [...cityData, value])
                }else{
                    cityList.push(value)
                }
                
                const countryData = this.countriesListMixinRaw.find(item => item.name === country);
                this.addMissingCity({
                    country_code: countryData.isoCode,
                    state_code: state,
                    city: value
                })
            },
            setDLCountry(){
                this.form.contact.driver_license_state = ''
                this.setDLStatesListEvent(this.form.contact.driver_license_country)
            },
            setRelationCountry(index){
                this.form.contact.Relationships[index].Contact.Addresses[0].Address.state = ''
                this.form.contact.Relationships[index].Contact.Addresses[0].Address.city = ''
                this.setRelationStatesListEvent(this.form.contact.Relationships[index].Contact.Addresses[0].Address.country, index)
                this.setRelationCountryCitiesListEvent(this.form.contact.Relationships[index].Contact.Addresses[0].Address.country, index)
            },
            setRelationState(index){
                this.form.contact.Relationships[index].Contact.Addresses[0].Address.city = ''
                this.setRelationCitiesListEvent(this.form.contact.Relationships[index].Contact.Addresses[0].Address.country, this.form.contact.Relationships[index].Contact.Addresses[0].Address.state, index)
            },
            setVehicleCountry(){
                this.form.vehicle.license_plate_state = ''
                this.setVehicleStatesListEvent(this.form.vehicle.license_plate_country)
            },
            setRegisteredVehicleCountry(){
                this.form.vehicle.registered_owner.state = ''
                this.form.vehicle.registered_owner.city = ''
                this.setRegisteredVehicleStatesListEvent(this.form.vehicle.registered_owner.country)
                this.setRegisteredVehicleCountryCitiesListEvent(this.form.vehicle.registered_owner.country)
            },
            setRegisteredVehicleState(){
                this.form.vehicle.registered_owner.city = ''
                this.setRegisteredVehicleCitiesListEvent(this.form.vehicle.registered_owner.country, this.form.vehicle.registered_owner.state)
            },
            setLegalVehicleCountry(){
                this.form.vehicle.legal_owner.state = ''
                this.form.vehicle.legal_owner.city = ''
                this.setLegalVehicleStatesListEvent(this.form.vehicle.legal_owner.country)
                this.setLegalVehicleCountryCitiesListEvent(this.form.vehicle.legal_owner.country)
            },
            setLegalVehicleState(){
                this.form.vehicle.legal_owner.city = ''
                this.setLegalVehicleCitiesListEvent(this.form.vehicle.legal_owner.country, this.form.vehicle.legal_owner.state)
            },
            setBusinessCountry(){
                this.form.contact.Business.Address.state = ''
                this.form.contact.Business.Address.city = ''
                this.setBusinessStatesListEvent(this.form.contact.Business.Address.country)
                this.setBusinessCountryCitiesListEvent(this.form.contact.Business.Address.country)
            },
            setBusinessState(){
                this.form.contact.Business.Address.city = ''
                this.setBusinessCitiesListEvent(this.form.contact.Business.Address.country, this.form.contact.Business.Address.state)
            },
            validateEmailPhone(){
              // Purpose of this method is to check if either Email or Phone is added

              // This condition is to check if both are not added and returns true otherwise false
              if((!this.form.contact.email && !this.form.contact.Phones[0].number)){
                return true
              }
              return false
            },
            setRentAsBusiness() {
                if(this.isRentAsBusiness == true) {
                    this.phone_types.unshift("Office Phone");
                    this.form.contact.Phones[0].type = 'Office Phone';
                    this.form.contact.last = '';
                    this.rent_as_company = '-1';
                    if(!this.isReturningTenant){
                        this.secondary_contact = "1";
                        this.useBusinessRepresentativeAsAlternate = true;
                    }
                } else {
                    const index = this.phone_types.indexOf("Office Phone");
                    if (index > -1) {
                        this.phone_types.splice(index, 1);
                        this.form.contact.Phones[0].type = 'Cell';
                    }
                    this.form.contact.taxpayer_number = '';
                    if(!this.isReturningTenant){
                        this.secondary_contact = "-1";
                        this.useBusinessRepresentativeAsAlternate = false;
                    }
                }
            },
            async setTaxExempt(){
                await this.setTaxExemptStore(this.tax_exempt);
                this.updateLeaseTaxExemptHistory({lease_id: this.lease.id, tax_exempt: this.tax_exempt, tax_exempt_description: this.tax_exempt_description, skip_history: true});
                this.$store.dispatch('onBoardingStore/getEstimate', {updateInvoice: true, generateInvoices: true});
                this.tax_exempt && this.$validator.validateAll(); 
                if(!this.tax_exempt){
                    this.tax_exempt_description = null;
                }
            },
            setTaxEcemptDescription(){
                this.setTaxExemptDescriptionStore(this.tax_exempt_description);
            },
            addRepresentativeInfoAsAlterContact() {
                let secondaryContactChange = this.secondary_contact; 
                
                if(this.useBusinessRepresentativeAsAlternate === true) {
                    this.secondary_contact = "1";
                }else{
                    this.secondary_contact = "-1";
                }

                if(secondaryContactChange == this.secondary_contact){
                    if(this.form.contact.Relationships.length == 0){
                        this.addContact(0);                        
                    }
                    if(this.form.contact.Relationships.length > 0 && this.useBusinessRepresentativeAsAlternate === true){
                        this.form.contact.Relationships[0].Contact.first = this.form.contact.Representative.Contact?.first;
                        this.form.contact.Relationships[0].Contact.last = this.form.contact.Representative.Contact?.last;
                        this.form.contact.Relationships[0].Contact.email = this.form.contact.Representative.Contact?.email;
                        this.form.contact.Relationships[0].Contact.Phones = JSON.parse(JSON.stringify(this.form.contact.Representative.Contact?.Phones));
                    }
                }
            },
            closeRentAsBusinessModal(confirm = false) {
                this.showRentAsBusinessModal = false;
                if(!confirm)
                    this.isRentAsBusiness = !this.isRentAsBusiness;
                else
                    this.setRentAsBusiness();
            },
            isFormFilled(){
                return this.hasObjectValue(this.form.contact, 'contact') || this.hasObjectValue(this.form.contact, 'driver_license') || this.hasObjectValue(this.form.contact?.Phones[0], 'contact') || this.hasObjectValue(this.form.contact.Addresses[0].Address, 'address') || this.hasObjectValue(this.form.contact.Representative, 'contact');
            },
            displayRentAsBusinessModal(){
                let is_form_filled = this.isFormFilled();
                if(is_form_filled)
                    this.showRentAsBusinessModal = true;
                else
                    this.setRentAsBusiness();
            },
            hasObjectValue(obj, keys_type) {
                let v_keys = this.keysToCheckFormIsFilled[keys_type];
                for (const key of v_keys){
                    if (obj[key]){
                        return true;
                    } else if (typeof obj[key] === 'object' && obj[key] !== null) {
                        if (this.hasObjectValue(obj[key], keys_type)) {
                            return true;
                        }
                    }
                }
                return false;
            },
            blurredMethod(){},
            countSectionError(section){
                if(!this.errors || !this.errors.items || !this.errors.items.length) return false;
                return this.errors.items.filter(i => i.scope == section).length;
            },
            async getGateConfig(){

                this.Access.property_id = this.property.id;
                await this.checkIsSpaceAccessCodesVendor();
                await this.getTenantCode();
                await this.getPinFormat();
                if((this.isSpaceAccessCodesVendor && (!this.Access.pin || (this.Access.pin && this.Access.pin.length !== 4))) || !this.Access.id){
                    await this.generateCode();
                }

            },
            async checkIsSpaceAccessCodesVendor(){
                if(this.Access.property_id){
                    try {
                        let r = await api.get(this, api.PROPERTIES + this.Access.property_id + '/access');

                        if(r.property_access_name === 'Derrels'){
                            this.isSpaceAccessCodesVendor = true;
                        }

                        if(r.property_access_name === 'PTI' || r.property_access_name === 'PTI Cloud'){
                            this.isPtiVendor = true;
                        }
                    } catch (err){
                        console.log(err);
                    }
                }
            },
            async getVehicleData(){
                let r = await api.get(this, api.LEASES + this.lease.id + '/vehicles');
                if(!r.vehicles.id) {
                   this.resetVehicle();
                   return;
                }
                this.form.vehicle = r.vehicles;
                this.is_vehicle_ative = 1;
            },
            async getTenantCode(){
                if(this.isSpaceAccessCodesVendor){
                    let r = await api.get(this, api.CONTACTS + this.form.contact.id + '/lease/' + this.lease.id + '/access');
                    this.Access = r.creds;
                    let s = await api.get(this, api.CONTACTS + this.form.contact.id + '/lease/' + this.lease.id + '/access', { unit_id: this.unit.id });
                    if(s.creds && s.creds.pin && s.creds.pin.length === 8 && s.creds.pin.substr(0,4) == this.pinFormattedUnitNumber){
                        this.Access.pin = s.creds.pin.substr(4);
                    } else {
                        this.Access.pin = '';
                    }
                } else {
                    let r = await api.get(this, api.CONTACTS + this.form.contact.id + '/lease/' + this.lease.id + '/access');
                    if(!r.creds.id) return;
                    this.Access = r.creds;
                    this.Access.property_id = this.property.id;
                    // this.access_type = r.access_type;
                }
            },
            async getPinFormat(){
                api.get(this, api.PROPERTIES + this.property.id + '/access').then(r => {
                    this.defaultPinFormat(r.property_access_name);
					this.Access.pin_format = r.access.Credentials.pin_format || this.default_pin_format;
                    this.Access.pin_min = r.access.Credentials.pin_min || this.default_pin_min;
                    this.Access.pin_max = r.access.Credentials.pin_max || this.default_pin_max;
                    
				});
            },
            defaultPinFormat(vendor){
                switch (vendor) {
                    default:
                        this.default_pin_format = '8'
                        break;
                }
            },
            async generateCode(){
                if(this.isSpaceAccessCodesVendor && this.unit && this.unit.number && this.unit.number.length <= 4 ){
                    var data = {
                        unit_id: this.unit.id,
                        unit_number: this.unit.number,
                    }

                    try {
                        let r = await api.get(this, api.PROPERTIES + this.property.id + '/generate-space-code', data, 'gate' );
                        this.Access.pin = r.code.substr(4);
                        this.access_type = r.access_type;
                    } catch(err){

                        this.has_access_control = false;
                    }
                } else if(this.isSpaceAccessCodesVendor && this.unit && this.unit.number && this.unit.number.length > 4 ){

                    this.has_access_control = false;

                } else {
                    try {
                        let r = await api.get(this, api.PROPERTIES + this.property.id + '/generate-access-code', null, 'gate' );
                        this.Access.pin = r.code;
                        this.access_type = r.access_type;
                    } catch(err){
                        this.has_access_control = false;
                    }
                }
            },
            addEmail(){
                this.form.contact.Email.push({
                    type: 'Primary',
                    email: ''
                })
            },
            removeEmail(i){
                if(this.form.contact.Email.length > 1){
                    this.form.contact.Email.splice(i,1);
                }
            },
            addPhone(){
                this.form.contact.Phones.push({
                    type: 'Cell',
                    code: '1',
                    number: '',
                    phone: '',
                    sms: true,
                })
            },
            addSecondaryPhone(i){
                this.form.contact.Relationships[i].Contact.Phones.push({
                    type: 'Phone',
                    code: '1',
                    number: '',
                    phone: ''
                })
            },
            removePhone(type, i, j){
                if(type == 'contact' && this.form.contact.Phones.length > 1){
                    this.form.contact.Phones.splice(i,1);
                }
                if(type == 'related' && this.form.contact.Relationships[i].Contact.Phones.length > 1){
                    this.form.contact.Relationships[i].Contact.Phones.splice(j,1);
                }

            },
            addLocation(){
                this.form.contact.Addresses.push({
                    id: null,
                    type: 'Home',
                    address_id: '',
                    Address:{
                        address: '',
                        address2: '',
                        city: '',
                        country: this.defaultCountry,
                        neighborhood: '',
                        lat: '',
                        lng: '',
                        state: '',
                        zip: ''
                    }
                })

                this.setStatesListEvent(this.defaultCountry, this.form.contact.Addresses.length-1)
                this.setCountryCitiesListEvent(this.defaultCountry, this.form.contact.Addresses.length-1)
            },

            removeLocation(i){
                if(this.form.contact.Addresses.length > 1){
                    this.form.contact.Addresses.splice(i,1);
                }
            },
            addRepresentative(){
                this.form.contact.Representative = {
                    id: null,
                    type: '',
                    is_emergency: false,
                    is_cosigner: false,
                    is_military: false,
                    is_authorized: false,
                    is_lien_holder: false,
                    lease_id: '',
                    Contact: {
                        id: null,
                        first: '',
                        last: '',
                        email: '',
                        Phones: [{
                            id: null,
                            type: 'Cell',
                            code: '1',
                            number: '',
                            phone: '',
                            sms: false
                        }],
                        Addresses: [
                            {
                                id: null,
                                type: 'Home',
                                address_id: '',
                                Address:{
                                    address: '',
                                    address2: '',
                                    city: '',
                                    neighborhood: '',
                                    lat: '',
                                    lng: '',
                                    state: '',
                                    zip: ''
                                }
                            }
                        ],
                    }  
                };
            },
            async addContact(i){
                if(i != 0){
                    const checkContacts = await this.$validator.validateAll('contacts');
                    const checkGeneral = await this.$validator.validateAll('general');
                    if (!checkContacts || !checkGeneral) {
                        return;
                    }                    
                } 
                let count = i;
                let relationships = this.form.contact.Relationships[count];
                if(relationships){
                    if((relationships?.is_alternate == false && relationships?.is_authorized == false && relationships?.is_emergency == false && relationships?.is_lien_holder == false)){
                        this.showMessageNotification({ type: 'error', description: 'Please select designated as first.'});
                        return;
                    }
                    if((relationships?.Contact.first == null || relationships?.Contact.last == null || relationships?.Contact.email == null || relationships?.Contact.Phones[0].number == "")){
                        this.showMessageNotification({ type: 'error', description: 'Please fill all fields.'});
                        return;
                    }
                    // if((relationships?.is_alternate == true || relationships?.is_lien_holder == true) && (relationships?.Contact.Addresses[0].Address.address == "" || relationships?.Contact.Addresses[0].Address.zip == "" || relationships?.Contact.Addresses[0].Address.state == "" || relationships?.Contact.Addresses[0].Address.city == null)){
                    //     this.showMessageNotification({ type: 'error', description: 'Please fill address fields.'});
                    //     return;
                    // }
                }
                
                this.form.contact.Relationships.push({
                    id: null,
                        type: '',
                        is_alternate: 0,
                        is_emergency: 0,
                        is_authorized: 0,
                        is_lien_holder: 0,
                        // is_business_representative: 0,
                        // is_cosigner: 0,
                        // is_military: 0,
                        lease_id: [this.lease.id],

                        Contact: {
                            id: null,
                            first: '',
                            last: '',
                            email: '',
                            Phones: [{
                                id: null,
                                type: 'Cell',
                                code: '1',
                                number: '',
                                phone: '',
                                sms: false
                            }],
                            Addresses: [
                                {
                                    id: null,
                                    type: 'Home',
                                    address_id: '',
                                    Address:{
                                        address: '',
                                        address2: '',
                                        city: '',
                                        neighborhood: '',
                                        lat: '',
                                        lng: '',
                                        state: '',
                                        zip: '',
                                        country: this.defaultCountry
                                    }
                                }
                            ],
                        }
                });

                // let formLength = this.form.contact.Relationships.length;
                this.setRelationStatesListEvent(this.defaultCountry, this.form.contact.Relationships.length - 1)
                this.setRelationCountryCitiesListEvent(this.defaultCountry, this.form.contact.Relationships.length-1)
                this.contactsSubpanel = [];
                this.contactsSubpanel.push(i);
                this.selectedOptions[this.form.contact.Relationships.length - 1] = [];
                this.alternateContactMap(i);
            },
            //// all functions starts here related to additional contact rework
            //:TODO Have to complete this
            leaseMapper(selected_contact_id){
                const selectedContact = this.form.contact.Relationships.find((relationship) => relationship.Contact.id === selected_contact_id);

                if (!selectedContact) {
                    return;
                }
                selectedContact.lease_id = [this.lease.id] 
            },
            //:TODO Have to complete this
            alternateContactMap(index){
                if(index === 0 ){
                this.selectedOptions.splice(0, 0, [this.designatedAsTypes[0]]);
                this.saveDesignationTypes(index)
                }else{
                    this.selectedOptions[this.form.contact.Relationships.length - 1] = [];
                }
            },

            handleAltcontact(){ 
                // Remove unit.Lease.id from the previously selected contact
                this.prevContacts = JSON.parse(JSON.stringify(this.contact));
                this.$set(this.form.contact, 'Relationships', []);

                if(this.previousContactId === 1){
                    return 
                }

                if(this.previousContactId === this.selectedAltContact){
                    return
                }

                if(this.selectedAltContact == 1){
                    this.previousContactId = null
                    this.addContact(0)
                    this.leaseMapper(this.selectedAltContact);
                }else{
                    // Update the previousContactId
                    this.previousContactId = this.selectedAltContact;
                    this.prevContacts.Relationships.filter((val, index, self) => self.findIndex(item => item.related_contact_id === val.related_contact_id) === index).forEach(value => {
                        if(value.Contact.id == this.selectedAltContact){
                            value.id = '';
                            value.is_alternate = 1;
                            value.is_emergency = 0;
                            value.is_authorized = 0;
                            value.is_lien_holder = 0;
                            this.form.contact.Relationships.push(value);
                            this.alternateContactMap(this.form.contact.Relationships?.length - 1);
                        }
                    })
                    // Call leaseMapper with the selectedAltContact
                    this.leaseMapper(this.selectedAltContact);
                }
            },
            handleOptionChange(index){ 
                this.saveDesignationTypes(index)
            },
            async saveDesignationTypes(index) {

                const objectToUpdate = this.form.contact.Relationships[index];
                if (!objectToUpdate) return;

                // Make a copy of the object to avoid directly mutating the original data
                const updatedObject = { ...objectToUpdate };

                // Iterate through the designatedAsTypes and update the keys
                this.designatedAsTypes.forEach((option) => {
                    // Check if the current option exists in selectedOptions based on the "name" property
                    const isSelected = this.selectedOptions[index].some(
                        (selectedOption) => selectedOption.id === option.id
                    );
                    // Update the corresponding property in updatedObject
                    updatedObject[option.id] = isSelected ? 1 : 0;
                }); 

                // Update the object back in the Relationships array
                this.$set(this.form.contact.Relationships, index, updatedObject);
                this.active = '';
            },
            async saveAssociatedSpace(newLease) {     
                let objectToUpdate = this.form.contact.Relationships;

                if(!objectToUpdate) return; 

                if(this.contact.Relationships?.length >= 1){
                    // let filteredContacts = this.contact.Relationships.filter(x => (x.id != "" && x.id != null && x.id != undefined));
                    let filteredContacts = [];                    
                    this.contact?.Relationships.forEach((relationship) => {
                        if(relationship.id == "" || relationship.id == null || relationship.id == undefined) return;
                        
                        let matchedLeases = this.contact.Relationships.map((relationships) => {
                            let leaseMatch = '';

                            if(this.contact?.Leases && relationships.related_contact_id == relationship.related_contact_id && relationship.lease_id != this.lease.id){
                                leaseMatch = this.contact?.Leases.find((lease) => lease.id === relationship.lease_id)
                                return leaseMatch ? leaseMatch.Unit.number : null;
                            }
                        })
                        .filter((unitNumber) => unitNumber !== null && unitNumber !== undefined);

                        if(!matchedLeases || (matchedLeases &&  matchedLeases?.length == 0) ) return;

                        filteredContacts.push(relationship);
                    });
                    let prevCo = this.removeVerificationTokenFromPhones(filteredContacts);
                    let updatedExistingContact = this.removeVerificationTokenFromPhones(objectToUpdate)
                    objectToUpdate = [...updatedExistingContact, ...prevCo];
                }          
                // Update the object back in the Relationships array
                this.form.contact.Relationships = objectToUpdate
                this.active = '';
            },
            getSelectedOptionsEvent(value, i) {
                let data = this.designatedAsTypes.filter((option) => (value[option.id] === 1 || value[option.id] === true));

                if(value){
                    this.selectedOptions[i] = data;
                }else{
                    this.selectedOptions[i] = [];
                }
            },
            //// all functions ends here related to additional contact rework
           
            removeContact(i){
                if(this.form.contact.Relationships.length >= 1){
                    this.form.contact.Relationships.splice(i,1);
                }
                if(this.form.contact.Relationships.length === 0){
                    this.secondary_contact = '-1';
                    this.$set(this.form.contact, 'Relationships', []);
                };
            },
            async saveTenant(another){
                const isValid =  await this.$validator.validateAll('specific_pin');
                if (!isValid) {
                    this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                    return "ERROR_SAVE_TENANT";
                }

                if(this.form.contact?.Relationships && this.form.contact.Relationships?.length > 0){
                    for (const relationships of this.form.contact.Relationships) {
                        if (relationships?.is_alternate == false && relationships?.is_authorized == false && relationships?.is_emergency == false && relationships?.is_lien_holder == false ) {
                            this.showMessageNotification({ type: 'error', description: 'Please select designated as first.' });
                            return "ERROR_SAVE_TENANT";
                        }
                        if (relationships?.Contact.first == null || relationships?.Contact.last == null || relationships?.Contact.email == null || relationships?.Contact.Phones[0].number == "" ) {
                            this.showMessageNotification({ type: 'error', description: 'Please fill all fields.' });
                            return "ERROR_SAVE_TENANT";
                        }
                        // if ((relationships?.is_alternate == true || relationships?.is_lien_holder == true) && (relationships?.Contact?.Addresses.length == 0 || relationships?.Contact.Addresses[0].Address.address == "" || relationships?.Contact.Addresses[0].Address.zip == "" || relationships?.Contact.Addresses[0].Address.state == "" || relationships?.Contact.Addresses[0].Address.city == null) ) {
                        //     this.showMessageNotification({ type: 'error', description: 'Please fill address fields.' });
                        //     return "ERROR_SAVE_TENANT";
                        // }              
                    }
                }

                let storedContentValidationStatus = await this.$refs["storedContent"].$validator.validateAll();
                if(!storedContentValidationStatus){
                  this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                  return "ERROR_SAVE_TENANT";
                }

                this.showEmailPhoneValidation(this.validateEmailPhone(), this.errors)


                if (this.invalidPhones.length && this.errors.items.indexOf('Please enter valid phone number(s) before continuing.') === -1) {
                  this.errors.items.push('Please enter valid phone number(s) before continuing.');
                }
                
                if (!this.invalidPhones.length && this.errors.items.indexOf('Please enter valid phone number(s) before continuing.') >= 0){
                  var invalidPhoneIndex = this.errors.items.indexOf('Please enter valid phone number(s) before continuing.');
                  this.errors.items.splice(invalidPhoneIndex);
                }

                if (this.invalidPhones.length) {
                  this.showMessageNotification({ list: ['Please enter valid phone number(s) before continuing.'] });
                  return "ERROR_SAVE_TENANT";
                }
                
                if(this.form.contact.Relationships?.length == 0){
                    this.secondary_contact = '-1';
                }
                let contact = {
                    id: this.form.contact.id,
                    company_id: this.form.contact.company_id,
                    salutation: this.form.contact.salutation,
                    first: this.form.contact.first,
                    middle: this.form.contact.middle,
                    last: this.form.contact.last,
                    suffix: this.form.contact.suffix,
                    company: this.rent_as_company == 1? this.rent_as_company: null,
                    active_military: this.military == 1? true: false,
                    have_secondary_contact: this.secondary_contact == 1? true: false,
                    email: this.form.contact.email,
                    dob: this.form.contact.dob,
                    gender: this.form.contact.gender,
                    driver_license: this.form.contact.driver_license,
                    driver_license_country:  this.form.contact.driver_license_country,
                    driver_license_state:  this.form.contact.driver_license_state,
                    driver_license_city: this.form.contact.driver_license_city,
                    driver_license_exp: moment(this.form.contact.driver_license_exp).format('YYYY-MM-DD') == 'Invalid date' ? '' : moment(this.form.contact.driver_license_exp).format('YYYY-MM-DD'),
                    taxpayer_number: this.form.contact.taxpayer_number,
                    rent_as_business: this.isRentAsBusiness,
                }
                
                contact.Military = JSON.parse(JSON.stringify(this.form.contact.Military));

                if(this.rent_as_company == 1){
                    contact.Business = this.form.contact.Business;
                    contact.Business.Address =  this.form.contact.Business.Address;
                }
                contact.Addresses = this.form.contact.Addresses;

                contact.Phones =  this.form.contact.Phones.filter(i => !!i.number).map(p => {
                  return {
                    id: p.id, 
                    type: p.type,
                    phone: p.phone,
                    sms: !!p.sms
                  }
              }); 
                
                // this.form.contact.Phones.map(p => {

                //     return {
                //         id: p.id,
                //         type: p.type,
                //         phone: p.phone,
                //         sms: !!p.sms
                //     }
                // });
                contact.ssn = contact.ssn  && typeof contact.ssn == 'number' ? contact.ssn.toString(): contact.ssn; 

                if(this.isLoading(this.$options.name)) return;
                this.errorClear(this.$options.name);


                let general_status = await this.$validator.validateAll('general');
                let additional_status = await this.$validator.validateAll('additional');
                let valid_military = await this.$refs['military'].$validator.validateAll();
                let vehicle_status = await this.$validator.validateAll('vehicle');
                let contacts_status = await this.$validator.validateAll('contacts');
                if(this.invalidPhones.length || !general_status || !additional_status || !vehicle_status || !valid_military || this.validateEmailPhone() || !contacts_status){
                    this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                    return "ERROR_SAVE_TENANT";
                }

                this.savingInProgress = true;
                this.updateLeaseTaxExemptHistory({lease_id: this.lease.id, tax_exempt: this.tax_exempt, tax_exempt_description: this.tax_exempt_description})
                this.active = '';

                if (this.accessAreasEnabled && this.has_access_control) {
                    await this.$store.dispatch("onBoardingStore/getUnitsInArea", {area_id: this.unit.area_id});
                    let numUnitsApplying = this.unitsInArea.length;
                    if (!this.confirmHoursVisible) {
                        if (numUnitsApplying > 0) {
                            this.confirmHoursVisible = true;
                            this.savingInProgress = false;
                            return "PAUSE_SAVE_TENANT";
                        }
                    } else {
                        this.confirmHoursVisible = false;
                    }
                }

                if(this.secondary_contact == 1){

                    contact.Relationships = this.form.contact.Relationships.map((r, index) => {
                        r.Contact.Phones = r.Contact.Phones.map(p => {
                            return {
                                id: p.id,
                                type: p.type,
                                phone: p.phone,
                                sms: !!p.sms
                            }
                        });
                       

                        r.is_alternate = !!(r.is_alternate);
                        r.is_emergency = !!(r.is_emergency);
                        r.is_cosigner = !!(r.is_cosigner);
                        r.is_military = !!(r.is_military);
                        r.is_authorized = !!(r.is_authorized);
                        r.is_lien_holder = !!(r.is_lien_holder);
                        r.is_move_in = 1;
                        return r;
                    });

                    this.saveAssociatedSpace(this.lease.id)
                    contact.Relationships = this.form.contact.Relationships  

                }else if(this.secondary_contact == -1){
                    contact.Relationships = this.removeVerificationTokenFromPhones(this.prevContacts.Relationships) 
                }

                contact.Leases = JSON.parse(JSON.stringify(this.contact.Leases));
                if(this.isRentAsBusiness) {
                    contact.Representative = JSON.parse(JSON.stringify(this.form.contact.Representative));
                    contact.Representative.Contact.Phones = contact.Representative.Contact.Phones.map(p => {
                        return {
                                id: p.id,
                                type: p.type,
                                phone: p.phone,
                                sms: !!p.sms
                        }
                    });
                    contact.Representative.is_business_representative = 1;
                    contact.Representative.is_move_in = 1;
                    if(!this.isReturningTenant){
                        if(this.secondary_contact == 1 && this.useBusinessRepresentativeAsAlternate == true){
                            contact.Relationships[0].is_business_representative = 1;
                            contact.Relationships[0].is_same_br_and_alc = 1;
                        }else{
                            contact.Relationships.push(contact.Representative);
                        }
                    }
                    
                }
                if(!contact.id){
                    // new Contact
                    // save new contact
                    let r = await api.post(this, api.LEASES + this.lease.id + '/tenants',  contact)
                    contact.id = r.contact_id;
                    this.form.contact.Relationships.forEach((item, i) => {
                        this.getSelectedOptionsEvent(item, i);
                    })
                    // should add to lease
                } else { 
                    await this.$store.dispatch('onBoardingStore/updateContact', contact)
                    if(this.isSpaceAccessCodesVendor && this.Access.id && this.Access.pin){
                        var data = {
                            pin: this.Access.pin,
                            property_id: this.unit.property_id,
                            unit_id: this.unit.id,
                            unit_number: this.unit.number,
                            area_id: this.unit.area_id,
                            vendor_access_level_id: this.selectedHours?.vendor_access_level_id
                        }

                        try {
                            let r = await api.put(this, api.CONTACTS + this.contact.id + '/access/' + this.Access.id, data);
                        } catch (err){
                            this.showMessageNotification({ description: err });
                            this.savingInProgress = false;
                            return "ERROR_SAVE_TENANT";
                        }


                    } else if(this.isSpaceAccessCodesVendor && !this.Access.id && this.Access.pin){
                        var data = {
                            pin: this.Access.pin,
                            property_id: this.property.id,
                            unit_id: this.unit.id,
                            unit_number: this.unit.number,
                            area_id: this.unit.area_id,
                            vendor_access_level_id: this.selectedHours?.vendor_access_level_id
                        }

                        try {
                            let r = await api.post(this, api.CONTACTS + this.contact.id + '/access', data);
                        } catch (err){
                            this.showMessageNotification({ description: err });
                            this.savingInProgress = false;
                            return "ERROR_SAVE_TENANT";
                        }

                    } else if(this.Access.pin){
                        try {
                            this.Access.area_id = this.unit.area_id;
                            this.Access.vendor_access_level_id = this.selectedHours?.vendor_access_level_id;
                            let r = await api.post(this, api.CONTACTS + this.contact.id + '/access/', this.Access);
                        } catch (err){

                            this.showMessageNotification({ description: err });
                            this.savingInProgress = false;
                            return "ERROR_SAVE_TENANT";
                        }
                    }
                } 
                let accessCodeData = {
                    access_code: this.isSpaceAccessCodesVendor ? this.pinFormattedUnitNumber + this.Access.pin : this.Access.pin,
                    contact_id : contact.id
                }

                if(this.contact_type == 'lease'){
                    EventBus.$emit('saveProtectedPropertyItems');
                    EventBus.$emit('saveStoredContent');
                    // EventBus.$emit('saveSensitiveInformation');

                    this.form.vehicle.active = this.is_vehicle_ative;
                    let vehicleData = this.form.vehicle;

                    if(this.upload_registartion_file) {
                        const upload_registartion_file_content = await this.upload(this.upload_registartion_file);

                        const { id } = upload_registartion_file_content;
                        if(id) {
                            this.form.vehicle.registration_upload_id = id;
                        }
                    }

                    if(this.insurance_upload_policy_file) {
                        const insurance_upload_policy_file_content = await this.upload(this.insurance_upload_policy_file);

                        const { id } = insurance_upload_policy_file_content;
                        if(id) {
                            this.form.vehicle.insurance_policy_upload_id = id;
                        }
                    }

                    if(this.form.vehicle.id) {
                       let r = await api.put(this, api.LEASES + this.lease.id + '/vehicles', vehicleData);
                    } else {
                        if(this.form.vehicle.type) {
                        let r = await api.post(this, api.LEASES + this.lease.id + '/vehicles', vehicleData);
                        }
                    }
                }

                this.$emit('accessData', accessCodeData)
                this.$emit('saved');
                
                if (this.accessAreasEnabled && this.has_access_control && !this.selectedHours?.vendor_access_level_id) {
                    this.showMessageNotification({ type: 'error', description: 'The tenant being moved in needs access. Please configure and assign their Access Hours.'});
                }

                this.savingInProgress = false;
                

            },
            async validateForm(){
                if(this.secondary_contact == -1){ 
                this.removeEmptyAlternateContactData() // remove redundant relationship data from form before validating
                } 
                const isValid =  await this.$validator.validateAll('specific_pin');
                if (!isValid) {
                    this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                    return "ERROR_SAVE_TENANT";
                }

                if(this.form.contact?.Relationships && this.form.contact.Relationships?.length > 0){
                    for (const relationships of this.form.contact.Relationships) {
                        if (relationships?.is_alternate == false && relationships?.is_authorized == false && relationships?.is_emergency == false && relationships?.is_lien_holder == false ) {
                            this.showMessageNotification({ type: 'error', description: 'Please select designated as first.' });
                            return "ERROR_SAVE_TENANT";
                        }
                        if (relationships?.Contact.first == null || relationships?.Contact.last == null || relationships?.Contact.email == null || relationships?.Contact.Phones[0].number == "" ) {
                            this.showMessageNotification({ type: 'error', description: 'Please fill all fields.' });
                            return "ERROR_SAVE_TENANT";
                        }
                        // address no longer mandatory
                        // if ((relationships?.is_alternate == true || relationships?.is_lien_holder == true) && (relationships?.Contact?.Addresses.length == 0 || relationships?.Contact.Addresses[0].Address.address == "" || relationships?.Contact.Addresses[0].Address.zip == "" || relationships?.Contact.Addresses[0].Address.state == "" || relationships?.Contact.Addresses[0].Address.city == null) ) {
                        //     this.showMessageNotification({ type: 'error', description: 'Please fill address fields.' });
                        //     return "ERROR_SAVE_TENANT";
                        // }              
                    }
                }
                

                let storedContentValidationStatus = await this.$refs["storedContent"].$validator.validateAll();
                if(!storedContentValidationStatus){
                  this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                  return "ERROR_SAVE_TENANT";
                }

                this.showEmailPhoneValidation(this.validateEmailPhone(), this.errors)


                if (this.invalidPhones.length && this.errors.items.indexOf('Please enter valid phone number(s) before continuing.') === -1) {
                  this.errors.items.push('Please enter valid phone number(s) before continuing.');
                }
                
                if (!this.invalidPhones.length && this.errors.items.indexOf('Please enter valid phone number(s) before continuing.') >= 0){
                  var invalidPhoneIndex = this.errors.items.indexOf('Please enter valid phone number(s) before continuing.');
                  this.errors.items.splice(invalidPhoneIndex);
                }

                if (this.invalidPhones.length) {
                  this.showMessageNotification({ list: ['Please enter valid phone number(s) before continuing.'] });
                  return "ERROR_SAVE_TENANT";
                }
                

                if(this.form.contact.Relationships?.length == 0){
                    this.secondary_contact = '-1';
                }
                 let contact = {
                    id: this.form.contact.id,
                    company_id: this.form.contact.company_id,
                    salutation: this.form.contact.salutation,
                    first: this.form.contact.first,
                    middle: this.form.contact.middle,
                    last: this.form.contact.last,
                    suffix: this.form.contact.suffix,
                    company: this.rent_as_company == 1? this.rent_as_company: null,
                    active_military: this.military == 1? true: false,
                    have_secondary_contact: this.secondary_contact == 1? true: false,
                    email: this.form.contact.email,
                    dob: this.form.contact.dob,
                    gender: this.form.contact.gender,
                    driver_license: this.form.contact.driver_license,
                    driver_license_country:  this.form.contact.driver_license_country,
                    driver_license_state:  this.form.contact.driver_license_state,
                    driver_license_city: this.form.contact.driver_license_city,
                    driver_license_exp: moment(this.form.contact.driver_license_exp).format('YYYY-MM-DD') == 'Invalid date' ? '' : moment(this.form.contact.driver_license_exp).format('YYYY-MM-DD'),
                    taxpayer_number: this.form.contact.taxpayer_number,
                    rent_as_business: this.isRentAsBusiness,
                }
                
                contact.Military = JSON.parse(JSON.stringify(this.form.contact.Military));

                if(this.rent_as_company == 1){
                    contact.Business = this.form.contact.Business;
                    contact.Business.Address =  this.form.contact.Business.Address;
                }
                contact.Addresses = this.form.contact.Addresses;

                contact.Phones =  this.form.contact.Phones.filter(i => !!i.number).map(p => {
                  return {
                    id: p.id, 
                    type: p.type,
                    phone: p.phone,
                    sms: !!p.sms
                  }
              }); 
                
                contact.ssn = contact.ssn  && typeof contact.ssn == 'number' ? contact.ssn.toString(): contact.ssn; 

                if(this.isLoading(this.$options.name)) return;
                this.errorClear(this.$options.name);

                let general_status = await this.$validator.validateAll('general');
                let additional_status = await this.$validator.validateAll('additional');
                let valid_military = await this.$refs['military'].$validator.validateAll();
                let vehicle_status = await this.$validator.validateAll('vehicle');
                let contacts_status = await this.$validator.validateAll('contacts');

                if(this.invalidPhones.length || !general_status || !additional_status || !vehicle_status || !valid_military || this.validateEmailPhone() || !contacts_status){
                    this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                    return "ERROR_SAVE_TENANT";
                }
            },

            async upload(file){
                let body = {
                    document_type: 'file',
                    document_type_id: null
                }

                let uploadedFile = await api.postFile(this, api.LEASES + this.lease.id + '/upload', body, [file]);
                if(uploadedFile && uploadedFile.uploads && uploadedFile.uploads.length) return uploadedFile.uploads[0];
            },
            async setContactInfo(contact_id){
                await this.$store.dispatch('getContactInfo', {contact_id: contact_id});
            },
            async checkEmail(){
                if(this.form.contact.id  || !this.form.contact.email.length) return;
                let r = await api.get(this, api.CONTACTS + 'check', {
                    id: this.form.contact.id,
                    email: this.form.contact.email
                });

                if(r.contacts && r.contacts.length && !this.form.contact.id) {
                    this.found = [...this.found, ...r.contacts];
                }
                if(this.found.length){
                    this.$emit('found', this.found)
                }

            },
            async checkPhone(value, code){
                if (this.form.contact.id || !this.form.contact.Phones.length || !value) return;
                let r = await api.get(this, api.CONTACTS + 'check', {
                    id: this.form.contact.id,
                    phone: code + value.replace(/\D/g, '')
                });
                if (r.contacts && r.contacts.length && !this.form.contact.id) {
                    this.found = r.contacts || [];
                } else {
                    this.found = [];
                }

                this.$emit('found', {
                    type: 'phone',
                    contacts: this.found
                })

            },

            async checkName(){
                if(this.form.contact.id || !this.form.contact.first || !this.form.contact.last) return;
                let r = await api.get(this, api.CONTACTS + 'check', { contact_id: this.form.contact.id, first: this.form.contact.first, last: this.form.contact.last } );

                if(r.contacts && r.contacts.length && !this.form.contact.id) {
                    this.found = [...this.found, ...r.contacts];
                }

                if(this.found.length){
                    this.$emit('found', this.found)
                }
            },
            resetLead(){
                this.$set(this.form, 'lead', {
                    id: null,
                    property_id: "",
                    unit_id: '',
                    category_id: '',
                    note: '',
                    content: '',
                    extras: {},
                    status: 'unread',
                    opened: 'null',
                    source: '',
                    method: '',
                    length_of_stay: '',
                    move_in_date: '',
                })
            },
            resetVehicle(){
                this.$set(this.form, 'vehicle', {
                    active: 0,
                    id: null,
                    contact_id: this.contact.id ? this.contact.id : null,
                    type: null,
                    make: '',
                    year: '',
                    model: '',
                    color: '',
                    license_plate_number: '',
                    license_plate_state: '',
                    license_plate_country: '',
                    registration_expiry_date: '',
                    registration_upload_id: '',
                    insurance_provider_name: '',
                    insurance_policy_upload_id: '',
                    vechile_identification_number: '',
                    hull_identification_number: '',
                    serial_number: '',
                    approximation_value: '',
                    registered_owner: {},
                    legal_owner: {}
                })
            },
            resetContact(){
                this.$set(this.form, 'contact', {
                        first: '',
                        last: '',
                        email: '',
                        driver_license: '',
                        driver_license_state: '',
                        driver_license_city: '',
                        driver_license_country: '',
                        driver_license_exp: '',
                        company: '',
                        active_military: false,
                        have_secondary_contact: false,
                        Phones: [{
                          type: 'Cell',
                          code: '1',
                          number: '',
                          sms: true
                      }],
                      Military: {
                      },
                      Business: {
                          name: '',
                          phone_type: '',
                          phone: '',
                          Address: {
                                address: '',
                                address2: '',
                                city: '',
                                state: '',
                                country:'',
                                zip: ''
                          },
                      },
                      Addresses: [
                        {
                            id: null,
                            type: 'Home',
                            address_id: '',
                            Address: {
                                address: '',
                                address2: '',
                                city: '',
                                neighborhood: '',
                                lat: '',
                                lng: '',
                                state: '',
                                country:'',
                                zip: ''
                            }
                        }
                    ],
                    Relationships: [
                      {
                          id: null,
                          type: '',
                          is_alternate: true,
                          is_emergency: false,
                          is_cosigner: false,
                          is_military: false,
                          is_authorized: false,
                          is_lien_holder: false,
                          lease_id: '',
                          Contact: {
                              id: null,
                              first: '',
                              last: '',
                              email: '',
                              Phones: [{
                                  id: null,
                                  type: 'Cell',
                                  phone: '',
                                  sms: false
                              }],
                              Addresses: [
                                  {
                                      id: null,
                                      type: 'Home',
                                      address_id: '',
                                      Address: {
                                          address: '',
                                          address2: '',
                                          city: '',
                                          neighborhood: '',
                                          lat: '',
                                          lng: '',
                                          state: '',
                                          country:'',
                                          zip: ''
                                      }
                                  }
                              ],
                          },
                      }
                    ]
                });
            },

            phoneMask(code) {
                switch (parseInt(code)) {
                    case 1:
                        return "(###) ###-####";
                    default:
                        return "(###) ###-####";
                }
            },

            showRentalModal(){
              this.contact.phone_to_send_sms = this.contact.Phones.find(item => item.sms == true);
              this.showRental = true;
            },

            async sendRentalEmail(){
              await api.get(this, api.LEADS + this.contact.id + '/request-dossier/' + this.lease.unit_id);
              this.showRental = false;
            },

            async sendRentalText(){
              await api.get(this, api.LEADS + this.contact.id + '/request-dossier/' + this.lease.unit_id);
              this.showRental = false;
            },

            checkValidPhone(code, number, i){
                if(code || number){
                  try {
                      var parsedPhoneNumber = parsePhoneNumber('+' + code + number);
                  }  catch(err){ 
                  }
                var r = null;

                if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
                    this.form.contact.Phones[i].phone = parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber;
                  if (this.invalidPhones.indexOf(i) != -1){
                    this.invalidPhones.splice(this.invalidPhones.indexOf(i), 1);
                  }
                  //return true;
                  r = true;
                } else if((!parsedPhoneNumber || (parsedPhoneNumber && !parsedPhoneNumber.isValid())) && number.length > 0) {
                  if (this.invalidPhones.indexOf(i) != -1){
                    '';
                  } else {
                    this.invalidPhones.push(i);
                  }
                  //return false;
                  r = false;
                } else {
                  if (this.invalidPhones.indexOf(i) != -1){
                    this.invalidPhones.splice(this.invalidPhones.indexOf(i), 1);
                  }
                  //return false;
                  r = false;
                }
                return r;
              }
            },

            checkValidPhoneBusiness(code, number){
                if(code || number){
                    try {
                      var parsedPhoneNumber = parsePhoneNumber('+' + code + number);
                    }  catch(err){ 
                    }
                    var r = null;

                    if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
                        this.form.contact.Business.phone = parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber;
                    if (this.invalidPhones.indexOf('b') != -1){
                        this.invalidPhones.splice(this.invalidPhones.indexOf('b'), 1);
                    }
                    //return true;
                    r = true;
                    } else if((!parsedPhoneNumber || (parsedPhoneNumber && !parsedPhoneNumber.isValid())) && number.length > 0) {
                    if (this.invalidPhones.indexOf('b') != -1){
                        '';
                    } else {
                        this.invalidPhones.push('b');
                    }
                    //return false;
                    r = false;
                    } else {
                    if (this.invalidPhones.indexOf('b') != -1){
                        this.invalidPhones.splice(this.invalidPhones.indexOf('b'), 1);
                    }
                    //return false;
                    r = true;
                    }
                    return r;
                }
            },

            checkValidPhoneAlternate(code, number, i, j){
                if(code || number){
                    try {
                        var parsedPhoneNumber = parsePhoneNumber('+' + code + number);
                    }  catch(err){ 
                    }
                    var r = null;

                    if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
                        this.form.contact.Relationships[i].Contact.Phones[j].phone = parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber;
                        if (this.invalidPhones.indexOf('a' + i + j) != -1){
                            this.invalidPhones.splice(this.invalidPhones.indexOf('a' + i + j), 1);
                        }
                    //return true;
                    r = true;
                    } else if((!parsedPhoneNumber || (parsedPhoneNumber && !parsedPhoneNumber.isValid())) && number.length > 0) {
                    if (this.invalidPhones.indexOf('a' + i + j) != -1){
                        '';
                    } else {
                        this.invalidPhones.push('a' + i + j);
                    }
                    //return false;
                    r = false;
                    } else {
                    if (this.invalidPhones.indexOf('a' + i + j) != -1){
                        this.invalidPhones.splice(this.invalidPhones.indexOf('a' + i + j), 1);
                    }
                    //return false;
                    r = true;
                    }
                    return r;
                }
            },

            checkValidPhoneRepresentative(code, number, j){
                if(code || number){
                    if(code){
                        this.updateRelationship(code,'code'); 
                    }
                    if(number){
                        this.updateRelationship(number,'number');
                    }
                    try {
                        var parsedPhoneNumber = parsePhoneNumber('+' + code + number);
                    }  catch(err){ 
                    }
                    var r = null;

                    if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
                        this.form.contact.Representative.Contact.Phones[j].phone = parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber;
                        this.updateRelationship(parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber,'phone');
                        if (this.invalidPhones.indexOf('a' + j) != -1){
                            this.invalidPhones.splice(this.invalidPhones.indexOf('a' + j), 1);
                        }
                    //return true;
                    r = true;
                    } else if((!parsedPhoneNumber || (parsedPhoneNumber && !parsedPhoneNumber.isValid())) && number.length > 0) {
                    if (this.invalidPhones.indexOf('a' + j) != -1){
                        '';
                    } else {
                        this.invalidPhones.push('a' + j);
                    }
                    //return false;
                    r = false;
                    } else {
                    if (this.invalidPhones.indexOf('a' + j) != -1){
                        this.invalidPhones.splice(this.invalidPhones.indexOf('a' + j), 1);
                    }
                    //return false;
                    r = true;
                    }
                    return r;
                }
            },

            checkValidPhoneMilitary(code, number){
                if(code || number){
                    try {
                      var parsedPhoneNumber = parsePhoneNumber('+' + code + number);
                    }  catch(err){ 
                    }
                    var r = null;

                    if(parsedPhoneNumber && parsedPhoneNumber.isValid()){
                        this.form.contact.Military.phone = parsedPhoneNumber.countryCallingCode + parsedPhoneNumber.nationalNumber;
                    if (this.invalidPhones.indexOf('m') != -1){
                        this.invalidPhones.splice(this.invalidPhones.indexOf('m'), 1);
                    }
                    //return true;
                    r = true;
                    } else if((!parsedPhoneNumber || (parsedPhoneNumber && !parsedPhoneNumber.isValid())) && number.length > 0) {
                    if (this.invalidPhones.indexOf('m') != -1){
                        '';
                    } else {
                        this.invalidPhones.push('m');
                    }
                    //return false;
                    r = false;
                    } else {
                    if (this.invalidPhones.indexOf('m') != -1){
                        this.invalidPhones.splice(this.invalidPhones.indexOf('m'), 1);
                    }
                    //return false;
                    r = true;
                    }
                    return r;
                }
            },
      
            removeVerificationTokenFromPhones(relationships) {
                if(!relationships?.length) return [];
                const updatedRelationships = relationships.map(relationship => {
                    if (relationship.Contact?.Phones?.length > 0) {
                        const updatedPhones = relationship.Contact.Phones.map(phone => {
                            const { verification_token, ...rest } = phone;
                            return rest;
                        });

                        return {
                            ...relationship,
                            Contact: {
                                ...relationship.Contact,
                                Phones: updatedPhones
                            }
                        };
                    }
                    return relationship;
                });
                return updatedRelationships;
            },
            updateRelationship(value,field){ 
                if(this.form.contact.Relationships.length > 0 && this.useBusinessRepresentativeAsAlternate == true){
                    switch(field){
                        case 'first':
                            this.form.contact.Relationships[0].Contact.first = JSON.parse(JSON.stringify(value));
                        break;
                        case 'last':
                            this.form.contact.Relationships[0].Contact.last = JSON.parse(JSON.stringify(value));
                        break;
                        case 'email':
                            this.form.contact.Relationships[0].Contact.email = JSON.parse(JSON.stringify(value));
                        break;
                        case 'code':
                            this.form.contact.Relationships[0].Contact.Phones[0].code = JSON.parse(JSON.stringify(value));
                        break;
                        case 'type':
                            this.form.contact.Relationships[0].Contact.Phones[0].type = JSON.parse(JSON.stringify(value));
                        break;
                        case 'number':
                            this.form.contact.Relationships[0].Contact.Phones[0].number = JSON.parse(JSON.stringify(value));
                        break;
                        case 'phone':
                            this.form.contact.Relationships[0].Contact.Phones[0].phone = JSON.parse(JSON.stringify(value));
                        break;
                        default:
                    }
                }
            },
            async initializeComponentData(){
                this.tax_exempt = this.lease.tax_exempt || this.getTaxExempt;
                this.tax_exempt_description = this.lease.tax_exempt_description || this.getTaxExemptDescription;
                console.log("================================================================================================ lease initialization");
                this.contact = JSON.parse(JSON.stringify(this.latestContactData))
                this.setCountriesListEvent()
                const generalScope = 'general'
                const vehicleScope = 'vehicle'
                if (this.contact.id) {
                    this.form.contact = JSON.parse(JSON.stringify(this.contact));
                    this.prevContacts = JSON.parse(JSON.stringify(this.contact));
                    this.form.contact.driver_license_country = this.setCountryNameEvent(this.contact.driver_license_country)
                    this.form.contact.driver_license_state = this.contact.driver_license_state || ''

                    this.setDLStatesListEvent(this.form.contact.driver_license_country)

                    this.form.contact.Addresses.map((value, i) => {
                        const fieldName = `city${i}`
                        const targetValue = { target: { value: this.form.contact.Addresses[i].Address.city, dataset: { vvScope: generalScope, vvName: fieldName } } }
                        this.form.contact.Addresses[i].Address.country = this.setCountryNameEvent(value.Address.country)

                        this.setStatesListEvent(this.form.contact.Addresses[i].Address.country, i)
                        if (!value.Address.state) {
                            this.setCountryCitiesListEvent(this.form.contact.Addresses[i].Address.country, i)
                        } else {
                            this.setCitiesListEvent(this.form.contact.Addresses[i].Address.country, value.Address.state, i)
                        }
                        this.setCityList({ event: targetValue, country: this.form.contact.Addresses[i].Address.country, state: value.Address.state, cityList: this.cityListMixin, index: i })

                    })

                    this.form.contact.Phones = this.contact.Phones.map(p => {
                        try {
                            var parseEditedPhone = parsePhoneNumber('+' + p.phone);
                        } catch (err) {
                        }

                        if (!parseEditedPhone) {
                            return {
                                id: p.id,
                                sms: p.sms,
                                phone: p.phone,
                                code: '1',
                                number: p.phone,
                                type: p.type
                            }
                        } else {
                            return {
                                id: p.id,
                                sms: p.sms,
                                phone: p.phone,
                                code: parseEditedPhone.countryCallingCode,
                                number: parseEditedPhone.nationalNumber,
                                type: p.type
                            }
                        }

                    });
                    this.form.contact.Military = this.form.contact.Military || {};
                    this.form.contact.Business = this.form.contact.Business || {};
                    this.form.contact.Business.Address = this.form.contact.Business.Address || {};
                    this.form.contact.Relationships = JSON.parse(JSON.stringify(this.form.contact.Relationships)) || [];
                    this.form.contact.Representative = JSON.parse(JSON.stringify(this.form.contact.Representative)) || {};
                    this.isRentAsBusiness = this.form.contact.rent_as_business;
                    if (this.isRentAsBusiness) {
                        this.phone_types.unshift("Office Phone");
                        if(this.form.contact.Phones.length === 0){
                        this.form.contact.Phones = [{
                          id: '',
                          sms: '',
                          phone: '',
                          code: '1',
                          number: '',
                          type: ''
                      }]}
                        this.form.contact.Phones[0].type = 'Office Phone';
                    }
                    this.form.vehicle = this.contact?.Vehicles?.[0] || {};
                    this.form.vehicle.registered_owner = this.form.vehicle.registered_owner || {};
                    this.form.vehicle.legal_owner = this.form.vehicle.legal_owner || {};
                } else {
                    this.resetContact();
                    this.resetVehicle();
                }
                if (!this.form.contact.Phones.length) {
                    this.addPhone('primary');
                }

                if (this.form.contact.Military.Address) {
                    this.form.contact.Military.Address.country = this.setCountryNameEvent(this.form.contact.Military.Address.country)
                }

                if (this.contact_type == 'lease') {
                    if (!this.form.contact.Addresses.length) {
                        this.addLocation();
                    }
                    if (!this.form.contact.Relationships.length) {
                        this.addContact(0);
                    }
                    if (!Object.keys(this.form.contact.Representative).length) {
                        this.addRepresentative();
                    }
                    await this.getGateConfig();
                    await this.getVehicleData();
                }

                await this.$store.dispatch('filterStore/getCategories');

                this.currentDate = moment().format('YYYY-MM-DD');

                this.form.contact.Relationships.forEach((a, i) => {
                    this.contactsSubpanel.push(i);
                    this.getSelectedOptionsEvent(a, i);
                    if (a.Contact.Addresses.length) {
                        a.Contact.Addresses.map(p => {
                            const fieldName = `secondarycity${i}`
                            const targetValue = { target: { value: p.Address.city, dataset: { vvScope: generalScope, vvName: fieldName } } }
                            const countryValue = this.setCountryNameEvent(p.Address.country)
                            this.setRelationStatesListEvent(countryValue, i)
                            if (!p.Address.state) {
                                this.setRelationCountryCitiesListEvent(countryValue, i)
                            } else {
                                this.setRelationCitiesListEvent(countryValue, p.Address.state, i)
                            }
                            this.setCityList({ event: targetValue, country: countryValue, state: p.Address.state, cityList: this.relationCityListMixin, index: i })


                            return {
                                id: p.id,
                                type: p.type,
                                address_id: p.address_id,
                                Address: {
                                    address: p.Address.address,
                                    address2: p.Address.address2,
                                    country: countryValue,
                                    city: p.Address.city,
                                    state: p.Address.state,
                                    zip: p.Address.zip,
                                    neighborhood: p.Address.neighborhood,
                                    lat: p.Address.lat,
                                    lng: p.Address.lng,
                                }
                            }
                        })
                    } else {
                        a.Contact.Addresses.push(
                            {
                                id: null,
                                type: 'Home',
                                address_id: '',
                                Address: {
                                    address: '',
                                    address2: '',
                                    city: '',
                                    neighborhood: '',
                                    lat: '',
                                    lng: '',
                                    state: '',
                                    country: this.defaultCountry,
                                    zip: ''
                                }
                            })
                    }
                    if (a.Contact.Phones.length < 1) {
                        a.Contact.Phones.push(
                            {
                                id: null,
                                type: 'Cell',
                                code: '1',
                                number: '',
                                phone: '',
                                sms: false
                            }
                        )
                    }
                    a.Contact.Phones = a.Contact.Phones.map(p => {
                        try {
                            var parseEditedPhone = parsePhoneNumber('+' + p.phone);
                        } catch (err) {
                        }

                        if (!parseEditedPhone) {
                            return {
                                id: p.id,
                                sms: p.sms,
                                phone: p.phone,
                                code: '1',
                                number: p.phone,
                                type: p.type
                            }
                        } else {
                            return {
                                id: p.id,
                                sms: p.sms,
                                phone: p.phone,
                                code: parseEditedPhone.countryCallingCode,
                                number: parseEditedPhone.nationalNumber,
                                type: p.type
                            }
                        }
                    });
                });
                if (this.form.contact.Representative.Contact.Phones.length > 0) {
                    this.form.contact.Representative.Contact.Phones = this.form.contact.Representative.Contact.Phones.map(p => {
                        try {
                            var parseEditedRepresentativePhone = parsePhoneNumber('+' + p.phone);
                        } catch (err) {
                        }

                        if (!parseEditedRepresentativePhone) {
                            return {
                                id: p.id,
                                sms: p.sms,
                                phone: p.phone,
                                code: '1',
                                number: p.phone,
                                type: p.type
                            }
                        } else {
                            return {
                                id: p.id,
                                sms: p.sms,
                                phone: p.phone,
                                code: parseEditedRepresentativePhone.countryCallingCode,
                                number: parseEditedRepresentativePhone.nationalNumber,
                                type: p.type
                            }
                        }
                    });
                }

                if (this.form.contact.Representative.Contact.Phones.length == 0) {
                    this.form.contact.Representative.Contact.Phones.push(
                        {
                            id: null,
                            type: 'Cell',
                            code: '1',
                            number: '',
                            phone: '',
                            sms: false
                        }
                    )
                }

                this.rent_as_company = this.form.contact.company == 1 ? "1" : "-1";
                this.military = this.form.contact.active_military == 1 ? "1" : "-1";
                this.secondary_contact = this.form.contact.have_secondary_contact == 1 ? "1" : "-1";
                if (this.isRentAsBusiness && !this.isReturningTenant) {
                    this.secondary_contact = "1";
                }
                this.formatted_phone = this.form.contact.Business.phone;

                this.form.contact.Business.Address.country = this.setCountryNameEvent(this.form.contact.Business.Address.country);

                this.setBusinessStatesListEvent(this.form.contact.Business.Address.country)
                this.form.contact.Business.Address.state = this.form.contact.Business.Address.state || '';

                if (!this.form.contact.Business.Address.state) {
                    this.setBusinessCountryCitiesListEvent(this.form.contact.Business.Address.country)
                } else {
                    this.setBusinessCitiesListEvent(this.form.contact.Business.Address.country, this.form.contact.Business?.Address?.state)
                }
                const businessfieldName = 'company_city'
                const businessTargetValue = { target: { value: this.form.contact.Business.Address.city, dataset: { vvScope: generalScope, vvName: businessfieldName } } }
                this.setCityList({ event: businessTargetValue, country: this.form.contact.Business.Address.country, state: this.form.contact.Business.Address.state, cityList: this.businessCityListMixin })


                if (this.form.contact.Business.phone) {
                    try {
                        var parseEditedPhoneBusiness = parsePhoneNumber('+' + this.form.contact.Business.phone);
                    } catch (err) {
                    }

                    if (!parseEditedPhoneBusiness) {
                        this.businessPhoneCode = '';
                        this.businessPhoneNumber = this.form.contact.Business.phone;
                    } else {
                        this.businessPhoneCode = parseEditedPhoneBusiness.countryCallingCode;
                        this.businessPhoneNumber = parseEditedPhoneBusiness.nationalNumber;
                    }
                } else {
                    this.businessPhoneCode = '1';
                    this.businessPhoneNumber = '';
                }

                this.form.vehicle.license_plate_country = this.setCountryNameEvent(this.form.vehicle.license_plate_country);

                this.form.vehicle.registered_owner.country = this.setCountryNameEvent(this.form.vehicle.registered_owner.country);

                this.form.vehicle.legal_owner.country = this.setCountryNameEvent(this.form.vehicle.legal_owner.country);

                this.form.vehicle.license_plate_state = this.form.vehicle.license_plate_state || '';
                this.form.vehicle.registered_owner.state = this.form.vehicle.registered_owner.state || '';
                this.form.vehicle.legal_owner.state = this.form.vehicle.legal_owner.state || '';

                this.setVehicleStatesListEvent(this.form.vehicle.license_plate_country)

                this.setRegisteredVehicleStatesListEvent(this.form.vehicle.registered_owner.country)

                if (this.form.vehicle.registered_owner.country && !this.form.vehicle.registered_owner.state) {
                    this.setRegisteredVehicleCountryCitiesListEvent(this.form.vehicle.registered_owner.country)
                } else {
                    this.setRegisteredVehicleCitiesListEvent(this.form.vehicle.registered_owner.country, this.form.vehicle.registered_owner.state)
                }
                const registeredfieldName = 'registered_owner_city'
                const registeredTargetValue = { target: { value: this.form.vehicle.registered_owner.city, dataset: { vvScope: vehicleScope, vvName: registeredfieldName } } }
                this.setCityList({ event: registeredTargetValue, country: this.form.vehicle.registered_owner.country, state: this.form.vehicle.registered_owner.state, cityList: this.registeredVehicleCityListMixin })

                this.setLegalVehicleStatesListEvent(this.form.vehicle.legal_owner.country)

                if (this.form.vehicle.legal_owner.country && !this.form.vehicle.legal_owner.state) {
                    this.setLegalVehicleCountryCitiesListEvent(this.form.vehicle.legal_owner.country)
                } else {
                    this.setLegalVehicleCitiesListEvent(this.form.vehicle.legal_owner.country, this.form.vehicle.legal_owner.state)
                }
                const legalfieldName = 'legal_owner_city'
                const legalTargetValue = { target: { value: this.form.vehicle.legal_owner.city, dataset: { vvScope: vehicleScope, vvName: legalfieldName } } }
                this.setCityList({ event: legalTargetValue, country: this.form.vehicle.legal_owner.country, state: this.form.vehicle.legal_owner.state, cityList: this.legalVehicleCityListMixin })

                let timesArray = [];
                new Array(24).fill().forEach((acc, index) => {
                    timesArray.push(moment({ hour: index }).format('h:mm a'));
                    timesArray.push(moment({ hour: index, minute: 15 }).format('h:mm a'));
                    timesArray.push(moment({ hour: index, minute: 30 }).format('h:mm a'));
                    timesArray.push(moment({ hour: index, minute: 45 }).format('h:mm a'));
                });

                this.times = timesArray;
                this.todo.time = '5:00 pm';
                this.useBusinessRepresentativeAsAlternate = (this.isRentAsBusiness && !this.isReturningTenant) ? true : false;
                const isAlternateContactExists = this.form.contact.Relationships.find(contact => contact.is_alternate === 1);
                this.secondary_contact = this.isRentAsBusiness && this.isReturningTenant && !isAlternateContactExists ? "-1" : this.secondary_contact;
                this.$emit('update:loading', false);
            },
            removeEmptyAlternateContactData(){
                this.form.contact.Relationships = this.form.contact.Relationships.filter(relationship => !(relationship.id === null && relationship.Contact.id === null))
            },
        },
        watch:{
            lease(){

            },
            "contact.id": async function(){
                this.form.contact = JSON.parse(JSON.stringify(this.contact));
                this.prevContacts = JSON.parse(JSON.stringify(this.contact));
                this.form.contact.Phones = this.contact.Phones.map(p => {

                    try {
                        var parseEditedPhone = parsePhoneNumber('+' + p.phone);
                    }  catch(err){ 
                    }
                    if(!parseEditedPhone){
                      return {
                          id: p.id,
                          sms: p.sms,
                          phone: p.phone,
                          code: '1',
                          number: p.phone,
                          type: p.type
                      }
                    } else {
                      return {
                          id: p.id,
                          sms: p.sms,
                          phone: p.phone,
                          code: parseEditedPhone.countryCallingCode,
                          number: parseEditedPhone.nationalNumber,
                          type: p.type
                      }
                    }

                });
                this.form.contact.Military = this.form.contact.Military || {};
                this.form.contact.Military.Address = this.form.contact.Military.Address || {};
                this.form.contact.Business = this.form.contact.Business || {};
                this.form.contact.Business.Address = this.form.contact.Business.Address || {};
                this.form.contact.Relationships = this.form.contact.Relationships || [];
                this.form.vehicle = this.form.vehicle || {};
                this.form.vehicle.registered_owner = this.form.vehicle.registered_owner || {};
                this.form.vehicle.legal_owner = this.form.vehicle.legal_owner || {};
            },

            active_lead_id(){
                if(this.contact_type == 'lead'){

                }
            },
            rent_as_company(){
                if(this.rent_as_company == -1){
                    this.form.contact.company = null; 
                }
            },

            military(){
                if(this.military == -1){
                    this.form.contact.active_military = false;
                }
            },
            secondary_contact(){
                console.log("this.secondary_contact --> secondary_contact: ", this.secondary_contact);
                if(this.secondary_contact == -1){
                    this.form.contact.have_secondary_contact = false;
                    this.selectedAltContact = 0;
                    this.previousContactId = null;
                }
                else{
                    let checkExistingContacts = this.setExistingContacts;
                    console.log("checkExistingContacts --> secondary_contact: ", checkExistingContacts);
                    this.$set(this.form.contact, 'Relationships', []);
                    // if(checkExistingContacts?.length > 1){
                    //     this.$set(this.form.contact, 'Relationships', []);
                    // }
                    console.log("this.form.contact.Relationships --> secondary_contact: ", this.form.contact.Relationships);
                    if(this.form.contact.Relationships.length == 0 && (this.setExistingContacts?.length && this.setExistingContacts?.length <= 1) ){
                        this.addContact(0);                        
                    }
                    if(this.form.contact.Relationships.length > 0 && this.useBusinessRepresentativeAsAlternate === true){
                        this.form.contact.Relationships[0].Contact.first = this.form.contact.Representative.Contact?.first;
                        this.form.contact.Relationships[0].Contact.last = this.form.contact.Representative.Contact?.last;
                        this.form.contact.Relationships[0].Contact.email = this.form.contact.Representative.Contact?.email;
                        this.form.contact.Relationships[0].Contact.Phones = JSON.parse(JSON.stringify(this.form.contact.Representative.Contact?.Phones));
                    }
                }

            },
            async refreshData(){
                await this.initializeComponentData()
            }
        }
    }

</script>

<style>
    .designate_as_types>.col>.row>.col>div>div>.row>.hb-aviary-form-label{
        min-width: 21% !important;
        max-width: 21% !important;
    }
    .designate_as_types>.col>.row>.col>div>.row>.col-4{
        flex: 0 0 21% !important;
        min-width: 21% !important;
        max-width: 21% !important;
    }
    .additional-contact-col>.row>.col-4 {
        max-width: 21% !important;
    }
    .additional-contact-col>.row>.col-8 {
        flex: 0 0 79% !important;
        max-width: 79% !important;
    }

    .additional-contact-col>.row>.col-8>.hb-forms-content-row>.col-12>.v-text-field{
        padding-top: 0px !important;
        margin-top: 0px !important;
    }
    .designate_as_panel>.v-expansion-panel-content__wrap{
        padding: 0px 0px 0px 0px !important;
    }
</style>


<style lang="scss" scoped>
    .lease-section hr.v-divider {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .lease-section{
        padding: 20px 50px 20px 50px;
        background: #F9FAFB;
    }


    .lease-section .v-chip {
        padding: 0 12px 0 5px;
    }
    .lease-section .v-chip i.v-icon{
        margin-right: 5px;
    }
/*
    .lease-section .v-expansion-panel{
        border: 1px solid #E1E6EA;
    }
    .lease-section .v-expansion-panel:before { box-shadow: none !important; }
*/
    .lease-section .v-expansion-panel-header.v-expansion-panel-header--active{
        border-bottom: 1px solid #E1E6EA;

    }

    .lease-section .v-expansion-panel.new-tenant-form-data {
        margin-bottom: 20px;
    }

    .lease-section .input-action{
      padding: 5px;
      cursor: default;
    }

    .lease-section .new-tenant-walkthrough-footer{
        // padding: 3px 0 3px 0;
        background: #FFFFFF;
        border-top: 1px solid #e2e2e2;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
        left: 0;
        right: 0;
    }

    .lease-section .border-bottom{
        border-bottom:1px solid #DFE3E8;
    }

    .lease-section .no-border-radius-bottom{
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .lease-section .hb-table {
        border: 1px solid #C4CDD5;
        margin-bottom: 5px;
    }

    .lease-section .hb-table-row {
        border-bottom: 1px solid #C4CDD5;
    }

    .lease-section .v-expansion-panel-header {
        font-weight: 500;
    }

    .lease-section .v-label {
        font-size: 14px;
    }

    .lease-section .v-select__selection {
        font-size: 14px;
    }

    .lease-section .v-text-field.v-input--dense .v-input__prepend-inner .v-input__icon > .v-icon, .v-text-field.v-input--dense .v-input__append-inner .v-input__icon > .v-icon {
        margin-top: 0;
    }
    .padding-top{
        padding-top: 0 !important;
    }
    .custom-padding-top{
        padding-top: 12px !important;
    }
    .custom-styling input{
        border: none !important;
        border-radius: none !important;
        border-bottom: 1px solid #C6CDD4 !important;
    }
    .custom-styling .input-tel.input-phone-number {
        margin-left: 0px !important;

    }
    .custom-styling .input-tel.input-phone-number{
        padding-left: 0px !important;
        border: none !important;
        border-radius: none !important;
        border-bottom: 1px solid #C6CDD4 !important;

     }
     .custom-styling .input-tel.input-phone-number input{
        padding-left: 0px !important;
        box-shadow: none !important;
        outline: none !important;
        border-radius: 0px !important;
    }
    .custom-styling .input-tel.input-phone-number input:focus{
        box-shadow: none !important;
        outline: none !important;
        border-radius: 0px !important;



    }
    .custom-styling .input-tel.input-phone-number label {
        left: 0px !important;
    }
    .custom-styling .select-country-container .country-selector.input-country-selector{
        box-shadow: none !important;
        outline: none !important;
        border-radius: 0px !important;
        width: 100px !important;
    }
    .custom-styling .select-country-container .country-selector.input-country-selector.has-value input{
        box-shadow: none !important;
        outline: none !important;
        border-radius: 0px !important;
        width: 100px !important;
    }
    .custom-styling .select-country-container .country-selector.input-country-selector.has-value input:focus{
        box-shadow: none !important;
        outline: none !important;
        border-radius: 0px !important;
        width: 100px !important;
    }
    .alignment{
        padding-top: 8px;
        padding-bottom: 8px;
        padding-right: 16px;
        padding-left: 16px;
    }
</style>
