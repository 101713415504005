<template>
  <div style="overflow: hidden;" class="navigation-panel-container">
    <div class="panel-content" >
      <hb-blade-header
        title="Move-Out"
        title-icon="mdi-system-navigation-custom-1"
        @back="back"
        :back-button-off="step == 1"
        @close="closeProcess"
      >
        <template v-slot:right>
          
          <hb-btn class="mr-1" icon tooltip="Lease Summary" @click="summary_panel = summary_panel === 'leaseSummary' ? null : 'leaseSummary'" :active="summary_panel === 'leaseSummary'" hover-background-color="#CAEAEA">mdi-users-custom-1</hb-btn>
          
          <hb-btn class="mr-1" v-if="!contact?.national_account_id" icon tooltip="Communication" @click="summary_panel = summary_panel === 'chat' ? null : 'chat'" :active="summary_panel === 'chat'" hover-background-color="#CAEAEA">mdi-forum</hb-btn>
        </template>
      </hb-blade-header>

      <div class="main-panel-body">
        <div class="main-panel-header">
          <hb-stepper :value="step">
            <hb-stepper-header :force-resize="resizeStepper">
              <hb-stepper-step
                step="1"
                :complete="step > 1"
              >
                Intent to Move-Out
              </hb-stepper-step>

              <v-divider></v-divider>

              <hb-stepper-step
                step="2"
                :complete="step > 2"
              >
                Prepare Space
              </hb-stepper-step>

              <v-divider></v-divider>

              <hb-stepper-step
                step="3"
                :complete="step > 3"
              >
                Move-Out Statement
              </hb-stepper-step>
            </hb-stepper-header>
          </hb-stepper>
          <div class="main-panel-content">
            <div class="d-flex justify-center mt-6" v-if="isLoadingData">
              <VProgressCircular
                indeterminate
                color="primary"
              />
            </div>
            <intent-move-out
              v-if="step === 1 && !paymentSection && contactLeases && lease && lease.id && !isLoadingData"
              :property_id="property_id"
              @close="closeProcess"
              @next="next"
              @showTaskCreater="show_reminder = true"
              :moveOutDate="getMoveOutDate"
              :lease="getLease"
              @setMoveOutDate="setMoveOutDate"
              @showLeaseSummary="summary_panel = 'leaseSummary'"
            >
            </intent-move-out>

            <intent-payments
              v-if="step === 1 && paymentSection"
              :lease_id="lease_id"
              :unit_id="unit_id"
              :property_id="property_id"
              :contact_id="contact.id"
              @close="closeProcess"
              @next="next"
              @showChat="contact?.national_account_id ? summary_panel = 'leaseSummary' : summary_panel = 'chat'"
              :auction_status="lease && lease.auction_status"
            >
            </intent-payments>

            <prepare-space
              v-if="step === 2"
              @close="closeProcess"
              @next="next"
              @showChat="contact?.national_account_id ? summary_panel = 'leaseSummary' : summary_panel = 'chat'"
              :lease="lease"
              :property_id="property_id"
              :contact="contact"
            ></prepare-space>

            <move-out-statement
              v-if="step === 3"
              :lease="getLease"
              :lease_id="lease_id"
              :unit_id="unit_id"
              :property_id="property_id"
              :contact_id="contact.id"
              :contact="contact"
              :move_out_date="getMoveOutDate"
              :ready_to_move_in="readyToMoveIn"
              @showLeaseSummary="summary_panel = 'leaseSummary'"
              @close="closeProcess"
              @next="next"
              @fetchLeaseData="getLeaseData"
            ></move-out-statement>
          </div>
        </div>
        <div class="main-side-info-panel" v-if="summary_panel">
          <lease-summary dense v-if="summary_panel === 'leaseSummary' && lease.id" @hide="summary_panel = ''" :lease="lease" :contact="contact" :access="access" :leaseMatrices="leaseMatrices"></lease-summary>
          <chat-window dense chatWindowHeight="x-large"  v-if="!contact?.national_account_id" v-show="summary_panel === 'chat'" :from="interactionContext" @hide="summary_panel = ''" :InteractionObj="contact" style="margin-bottom: 12%;"></chat-window>
        </div>
      </div>

    </div>

    <todo @close="closeReminder" v-model="show_reminder" :lease_id="$props.lease_id" :contact_id="$props.contact.id" event_type="move_out" :due_date="getMoveOutDate" v-if="isMoveOutDate" name="Intent to Move-Out"></todo>

  </div>

</template>

<script  type="text/babel">

import IntentMoveOut from './IntentMoveOut.vue'
import IntentPayments from './IntentPayments.vue'
import PrepareSpace from './PrepareSpace.vue'
import MoveOutStatement from './MoveOutStatement/Index.vue'
import ChatWindow from '../../communication_center/ChatWindow.vue'
import LeaseSummary from './LeaseSummary.vue'
import Loader from '../../assets/CircleSpinner.vue';
import api from '../../../assets/api.js';
import { EventBus } from '../../../EventBus.js';
import Todo from "./../Todo.vue";
import { mapActions, mapGetters, mapMutations } from 'vuex';
import moment from 'moment';

export default {
  name: 'MoveOutIndex',
  data() {
    return {
      step: 1,
      interactionContext: 'moveOut',
      summary_panel: '',
      lease: [],
      access: [],
      leaseMatrices: [],
      paymentSection: false,
      readyToMoveIn: false,
      show_reminder: false,
      contactLeases: {},
      resizeStepper: 0,
      loading: false,
      move_out_date: null
    }
  },
  props: ['close', 'contact', 'property_id', 'lease_id', 'unit_id', 'moveOutDate'],
  components: {
    IntentMoveOut,
    IntentPayments,
    PrepareSpace,
    MoveOutStatement,
    ChatWindow,
    LeaseSummary,
    Todo,
    Loader
  },
  async mounted () {
    setTimeout(() => {
      this.$store.dispatch('charmCallStore/setLoadingAction', {"status": false, "eventStatus": "move-out-button-clicked"});
    }, 2000);
      await this.setPaymentSource({
      paymentSource: this.paymentSource,
    });
    await this.getLeaseData();
    if(this.lease.auction_status == 'move_out' && !this.contact.Leases.length) {
      await this.getContactLeases();
    } else {
      this.contactLeases = this.contact.Leases;
    }

    if(this.activeDrawerComponent.type === 'showMoveOut') {
      this.step = this.activeDrawerComponent.step || this.step;
      this.paymentSection = this.activeDrawerComponent.paymentSection || this.paymentSection;
      this.readyToMoveIn = this.activeDrawerComponent.readyToMoveIn || this.readyToMoveIn;
    }

    await this.getAccessData();
    await this.getLeaseMatrices();
    
  },
  created () {
    this.setContact(this.contact);
    EventBus.$on('invoice_generated', this.getLeaseData);
    EventBus.$on('ready_to_move_in', this.getPrepareSpaceAction);
    EventBus.$on('reset_move_out_hb_model', this.closeProcess);
  },
  computed:{
    ...mapGetters({
      activeDrawerComponent: 'navigationStore/activeDrawerComponent',
      properties: 'propertiesStore/filtered',
    }),
    getLease() {
      return (this.contactLeases.length && this.lease.auction_status == 'move_out') ? this.contactLeases.find(lease => lease.id == this.lease_id) : this.lease;
    },
    isLoadingData() {
      return !!(this.isLoading(this.$options.name) && !(this.step === 1 && this.paymentSection))
    },
    isMoveOutDate() {
      return this.getMoveOutDate ? true : false;
    },
    getMoveOutDate() {
      return this.getPropertyCurrentDate || this.moveOutDate || this.move_out_date;
    },
    getPropertyCurrentDate() {
      let currentProperty = this.properties.filter(p => this.lease?.Unit?.property_id === p.id);
      let offSet = currentProperty.length ? currentProperty[0]?.utc_offset : "+00:00";
      let dateFormat = 'YYYY-MM-DD h:mm:ss';
      let localCurrentDate = moment().utcOffset(parseInt(offSet)).format(dateFormat);
      return localCurrentDate;
    }
  },
  destroyed() {
    EventBus.$off('invoice_generated', this.getLeaseData);
    EventBus.$off('ready_to_move_in', this.getPrepareSpaceAction);
    this.resetContact(); 
    EventBus.$off('reset_move_out_hb_model', this.closeProcess);
  },
  methods: {
    ...mapMutations({
      setPaymentSource: 'paymentsStore/setPaymentSource',
      setLeases: 'paymentsStore/setLeases',
      setContact: 'onBoardingStore/setContact',
      resetContact: 'onBoardingStore/resetContact',
    }),
    ...mapActions({
      setActiveDrawerComponent:'navigationStore/setActiveDrawerComponent'
    }),
    getContactLeases() {
      api.get(this, api.CONTACTS + this.contact.id).then(r => {
          this.contactLeases = r.contact.Leases.filter(obj => {
              return obj.status === 1
          });
      });
    },
    async getPrepareSpaceAction(readyToMove) {
      this.readyToMoveIn = readyToMove;
      this.setActiveDrawerComponent({...this.activeDrawerComponent, readyToMoveIn: readyToMove });
    },
    next (paymentFlag) {
      if (paymentFlag && !this.contact?.national_account_id) {
        this.paymentSection = true;
        this.setActiveDrawerComponent({...this.activeDrawerComponent, paymentSection: this.paymentSection, step: this.step })
        return
      } else {
        this.paymentSection = paymentFlag || false
      }

      if (this.step === 3) {
        this.closeProcess()
      } else {
        this.step += 1
      }

      this.setActiveDrawerComponent({
        ...this.activeDrawerComponent,
        paymentSection: this.paymentSection,
        step: this.step
      })

    },
    async getLeaseData() {
      let r = await api.get(this, api.LEASES + this.$props.lease_id);
      this.lease = r.lease;
    },
    async getAccessData() {
      let r = await api.get(this, api.CONTACTS + this.$props.contact.id + '/access').then(r => {
          this.access = r.access.length ? r.access[0] : '';
      })
    },
    async getLeaseMatrices() {
      let r = await api.get(this, api.LEASES + this.$props.lease_id + '/metrics').then(r => {
          this.leaseMatrices = r.metrics;
      })
    },
    back () {

      if (this.step === 1 && this.paymentSection) {
        this.paymentSection = false;
        this.setActiveDrawerComponent({...this.activeDrawerComponent, paymentSection: this.paymentSection, step: this.step })
        return
      }

      if (this.step === 1) {
        return
      } else {
        this.step -=1
      }

      this.setActiveDrawerComponent({
        ...this.activeDrawerComponent,
        paymentSection: this.paymentSection,
        step: this.step
      })
    },
    closeProcess () {
      this.$emit('close')
    },
    setMoveOutDate (date) {
      this.move_out_date = date;
      this.setActiveDrawerComponent({...this.activeDrawerComponent, move_out_date: date });
    },
    closeReminder(){
        this.show_reminder = false;
        this.closeProcess();
    }
  },
  watch:{
    summary_panel(){
        this.resizeStepper++;
    }
  }
}
</script>


<style scoped>
  .main-panel-body {
    height: 100%;
    display: flex;
  }

  .main-panel-header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    flex: 1;
  }

  .main-panel-content {
    flex: 1;
    overflow-y: scroll;
    padding-bottom: 81px;
  }

  .main-side-info-panel{
    flex: 0 0 420px;
    height: 100%;
    background-color: #F9FAFB;
    border-left: 1px solid #E1E6EA;
    overflow-y: auto;
  }

  .new-tenant-stepper{
      border-bottom: 1px solid #E1E6EA;
      text-align: center;
      box-shadow:none;
      background: white;
  }

  .navigation-panel-container{
    background: #F9FAFB;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100%;
    position: relative;
    overflow: hidden;
  }

  .panel-content {
    height: 100%;
    overflow: hidden;
    padding-bottom: 63px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  .nav-drawer-header {
    background: white;
    flex: 0 1;
  }

  .section-header{
    font-weight: 500;
  }

</style>
